<template>
  <div class="wrapper">
    <side-bar>
      <!-- <mobile-menu slot="content"></mobile-menu> -->
      <sidebar-link to="/operator/Overview">
        <i class="nc-icon icon-dashboard"></i>
        <p>Dashboard</p>
      </sidebar-link>

      <sidebar-dropdwon-link>
        <template slot="title">
          <i class="fa icon-site"></i>
          <b class="caret"></b>
          <span class="notification">마이페이지</span>
        </template>
        <div class="dropdown-menu-custom">
          <router-link
            v-if="this.permission === 'true'"
            class="sidebar-dropdown-item"
            to="/operator/mypage/registerAdmin"
            @click.native="toggleSidebar"
            >운영자 등록</router-link
          >
          <router-link
            class="sidebar-dropdown-item"
            to="/operator/mypage/adminList"
            @click.native="toggleSidebar"
            >운영자</router-link
          >
          <router-link
            class="sidebar-dropdown-item"
            to="/operator/mypage/plugRegister"
            @click.native="toggleSidebar"
            >플러그 등록</router-link
          >
          <router-link
            v-if="this.permission === 'true'"
            class="sidebar-dropdown-item"
            to="/operator/mypage/priceInput"
            @click.native="toggleSidebar"
            >사용요금 등록</router-link
          >
        </div>
      </sidebar-dropdwon-link>

      <sidebar-dropdwon-link>
        <template slot="title">
          <i class="nc-icon icon-apparatus"></i>
          <b class="caret"></b>
          <span class="notification">고객 관리</span>
        </template>
        <div class="dropdown-menu-custom">
          <router-link
            class="sidebar-dropdown-item"
            to="/operator/customer/userList"
            @click.native="toggleSidebar"
            >충전 사용자</router-link
          >
          <router-link
            class="sidebar-dropdown-item"
            to="/operator/customer/managerList"
            @click.native="toggleSidebar"
            >건물 관리자</router-link
          >
        </div>
      </sidebar-dropdwon-link>

      <sidebar-dropdwon-link>
        <template slot="title">
          <i class="nc-icon icon-use"></i>
          <b class="caret"></b>
          <span class="notification">운영 관리</span>
        </template>
        <div class="dropdown-menu-custom">
          <router-link
            class="sidebar-dropdown-item"
            to="/operator/management/siteList"
            @click.native="toggleSidebar"
            >사이트</router-link
          >
          <router-link
            class="sidebar-dropdown-item"
            to="/operator/management/plugList"
            @click.native="toggleSidebar"
            >플러그</router-link
          >
          <router-link
            class="sidebar-dropdown-item"
            to="/operator/management/useList"
            @click.native="toggleSidebar"
            >사용 내역</router-link
          >
          <router-link
            class="sidebar-dropdown-item"
            to="/operator/management/ticketList"
            >정기권 구매 내역</router-link
          >
        </div>
      </sidebar-dropdwon-link>

      <sidebar-dropdwon-link>
        <template slot="title">
          <i class="nc-icon icon-use"></i>
          <b class="caret"></b>
          <span class="notification">정산 관리</span>
        </template>
        <div class="dropdown-menu-custom">
          <router-link
            class="sidebar-dropdown-item"
            to="/operator/paid/PaidPro"
            @click.native="toggleSidebar"
            >정산 처리</router-link
          >
          <router-link
            class="sidebar-dropdown-item"
            to="/operator/paid/PaidResult"
            @click.native="toggleSidebar"
            >정산 결과</router-link
          >
          <router-link
            class="sidebar-dropdown-item"
            to="/operator/paid/PaidSearch"
            @click.native="toggleSidebar"
            >정산 검색</router-link
          >
        </div>
      </sidebar-dropdwon-link>
    </side-bar>
    <div class="main-panel">
      <top-navbar></top-navbar>

      <dashboard-content @click="toggleSidebar"> </dashboard-content>

      <!-- <content-footer></content-footer> -->
    </div>
  </div>
</template>
<style lang="scss"></style>
<script>
import TopNavbar from "./TopNavbar.vue";
import ContentFooter from "./ContentFooter.vue";
import DashboardContent from "./Content.vue";
import MobileMenu from "./MobileMenu.vue";
export default {
  data() {
    return {
      permission: "",
    };
  },
  async created() {
    if (sessionStorage.getItem("login") !== null) {
      this.permission =
        this.$store.state.get_admin_detail.admin_user_profile.permission;
    }
  },
  components: {
    TopNavbar,
    ContentFooter,
    DashboardContent,
    MobileMenu,
  },
  methods: {
    toggleSidebar() {
      if (this.$sidebar.showSidebar) {
        this.$sidebar.displaySidebar(false);
      }
    },
  },
};
</script>

<style scoped>
.dropdown-item {
  margin: 5px 15px 0px 40px;
  border-radius: 4px;
  color: #ffffff;
  opacity: 0.86;
  padding: 8px 50px;
}
</style>
