import Vue from "vue";
import VueRouter from "vue-router";
import store from "../vue-store.js";

import DashboardLayout from "../layout/DashboardLayout.vue";
// GeneralViews
import NotFound from "../pages/NotFoundPage.vue";

// Admin pages
import Overview from "src/pages/Overview.vue";

/*정산 관리 */
import PaidPro from "src/pages/paid/PaidPro.vue";
import PaidResult from "src/pages/paid/PaidResult.vue";
import PaidResultTable from "src/pages/paid/PaidResultTable.vue";
import PaidSearch from "src/pages/paid/PaidSearch.vue";

/* 사용자 */
// import UserInfoForm from "src/pages/user/UserInfoForm.vue";
import Login from "src/pages/user/Login.vue";

//여기서부터 찐
/* 마이페이지 */
import RegisterAdmin from "src/pages/mypage/RegisterAdmin.vue";
import PriceInput from "src/pages/mypage/PriceInput.vue";
import AdminList from "src/pages/mypage/AdminList.vue";
import PlugRegister from "src/pages/mypage/PlugRegister.vue";

import UserList from "src/pages/customer/UserList.vue";
import ManagerList from "src/pages/customer/ManagerList.vue";

import SiteList from "src/pages/management/SiteList.vue";
import PlugList from "src/pages/management/PlugList.vue";
import UseList from "src/pages/management/UseList.vue";
import TicketList from "src/pages/management/TicketList.vue";

Vue.use(VueRouter);

const routes = [
  // / 입력시 로그인 페이지 이동
  {
    path: "/",
    component: DashboardLayout,
    // redirect: '/admin/overview'
    redirect: "/login",
  },

  // /login  로그인 페이지 이동
  {
    path: "/login",
    name: "Login",
    component: Login,
    meta: {
      title: "로그인",
    },
  },

  {
    // 정산처리 세부내역(팝업)
    path: "/operator/paid/PaidResultTable",
    name: "PaidResultTable",
    component: PaidResultTable,
  },

  {
    // 대쉬보드 이동
    path: "/operator",
    component: DashboardLayout,
    children: [
      {
        path: "Overview",
        name: "Overview",
        component: Overview,
        meta: {
          title: "쉐어플러그_대시보드",
        },
      },
      {
        // 운영자 등록 이동
        path: "mypage/registerAdmin",
        name: "RegisterAdmin",
        component: RegisterAdmin,
        meta: {
          title: "마이페이지_운영자 등록",
        },
      },
      {
        // 운영자 리스트
        path: "mypage/adminList",
        name: "AdminList",
        component: AdminList,
        meta: {
          title: "마이페이지_운영자",
        },
      },
      {
        // 플러그 등록
        path: "mypage/plugRegister",
        name: "PlugRegister",
        component: PlugRegister,
        meta: {
          title: "마이페이지_플러그 등록",
        },
      },
      {
        // 사용요금 등록
        path: "mypage/priceInput",
        name: "PriceInput",
        component: PriceInput,
        meta: {
          title: "마이페이지_사용요금 등록",
        },
      },
      {
        // 충전사용자 리스트
        path: "customer/userList",
        name: "UserList",
        component: UserList,
        meta: {
          title: "고객관리_충전사용자",
        },
      },
      {
        // 건물관리자 리스트
        path: "customer/managerList",
        name: "ManagerList",
        component: ManagerList,
        meta: {
          title: "고객관리_건물관리자",
        },
      },
      {
        // 사이트 리스트
        path: "management/siteList",
        name: "SiteList",
        component: SiteList,
        meta: {
          title: "운영관리_사이트",
        },
      },
      {
        // 플러그 리스트
        path: "management/plugList",
        name: "PlugList",
        component: PlugList,
        meta: {
          title: "운영관리_플러그",
        },
      },
      {
        // 팝업에서 플러그리스트를 띄움
        path: "management/plugList/:provider_id",
        name: "PlugList",
        component: PlugList,
        meta: {
          title: "운영관리_플러그",
        },
      },
      {
        // 사용내역 리스트
        path: "management/useList",
        name: "UseList",
        component: UseList,
        meta: {
          title: "운영관리_사용내역",
        },
      },
      {
        // 정기권 내역 리스트
        path: "management/ticketList",
        name: "TicketList",
        component: TicketList,
        meta: {
          title: "운영관리_정기권내역",
        },
      },

      {
        // 정산처리 리스트
        path: "paid/PaidPro",
        name: "PaidPro",
        component: PaidPro,
        meta: {
          title: "정산관리_정산처리",
        },
      },
      {
        // 정산결과 리스트
        path: "paid/PaidResult",
        name: "PaidResult",
        component: PaidResult,
        meta: {
          title: "정산관리_정산결과",
        },
      },
      // {
      //   path: "paid/PaidResultTable",
      //   name: "PaidResultTable",
      //   component: PaidResultTable,
      // },
      {
        // 정산검색 리스트
        path: "paid/PaidSearch",
        name: "PaidSearch",
        component: PaidSearch,
        meta: {
          title: "정산관리_정산검색",
        },
      },
    ],
  },

  { path: "*", component: NotFound },
];

const router = new VueRouter({
  routes, // short for routes: routes
  linkActiveClass: "nav-item active",
  scrollBehavior: (to) => {
    if (to.hash) {
      return { selector: to.hash };
    } else {
      return { x: 0, y: 0 };
    }
  },
  mode: "history",
});

router.beforeEach((to, from, next) => {
  if (to.matched.some((record) => record.meta.requiresAuth)) {
    if (store.state.token) {
      next();
      return;
    }
    next("/login");
  } else {
    next();
  }
});

// 브라우저 타이틀 생성
const DEFAULT_TITLE = "어드민";
router.afterEach((to, from) => {
  Vue.nextTick(() => {
    document.title = to.meta.title
      ? DEFAULT_TITLE + "_" + to.meta.title
      : DEFAULT_TITLE;
  });
});
/**
 * Asynchronously load view (Webpack Lazy loading compatible)
 * The specified component must be inside the Views folder
 * @param  {string} name  the filename (basename) of the view to load.
function view(name) {
   var res= require('../components/Dashboard/Views/' + name + '.vue');
   return res;
};**/

export default router;
