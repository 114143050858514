<template>
  <div class="content" v-if="this.change_access === 'y'">
    <div class="row">
      <div class="col-6">
        <card
          class="strpied-tabled-with-hover content-card"
          body-classes="table-full-width table-responsive"
        >
          <template slot="header">
            <h4 class="card-title">건물 관리자</h4>
          </template>
          <div class="search_div">
            <div class="search_input_row">
              <p class="p1">검색 기준</p>
              <base-select-search
                type="text"
                class="text-center selectInput seletbox"
                :items="searchOptionList"
                v-model="searchOptionList.id"
              ></base-select-search>
              <base-input
                type="text"
                placeholder=""
                v-model="searchInput"
                class="inputbox"
                @keypress="enterkey()"
              />
              <button
                class="btn btn-fill btn-primary btn-block search_btn"
                type="button"
                @click="findManager()"
              >
                <a style="white-space: nowrap">검색</a>
              </button>
            </div>
            <div class="search_input_row">
              <p class="p2">검색 기준</p>
              <div class="col-10 radio_div">
                <input
                  type="radio"
                  name=""
                  id="chk_usetime_off"
                  v-model="testData"
                  label="all"
                  value="전체"
                />전체 List
                <input
                  type="radio"
                  id="chk_usetime_off"
                  v-model="testData"
                  label="waiting"
                  value="waiting"
                />계좌 인증 요청 건
              </div>
            </div>
          </div>

          <!-- <site-table class="table-hover table-striped"></site-table> -->
          <div class="search_result_div">
            <div v-if="searchData.length > 0">
              <div class="countData">총 {{ this.totalNum }} 개</div>
              <div class="div-table">
                <div class="div-row">
                  <div
                    class="div-cell"
                    v-for="column in tableColumns"
                    :key="column.id"
                  >
                    <span>
                      {{ column.msg }}
                    </span>
                  </div>
                </div>
                <!-- <div class="div-row" v-for="(item, index) in paginatedData" :key="index" @click="moveSiteDetail(item.siteId)"> -->
                <div
                  class="div-row"
                  v-for="(item, index) in searchData"
                  :key="index.id"
                  @click="findManagerDetail(index)"
                  :class="{ clickedRow: index === clickRowIndex }"
                >
                  <div
                    class="div-cell"
                    v-for="column in tableColumns"
                    :key="column.id"
                  >
                    {{ itemValue(item, column.id) }}
                  </div>
                </div>
              </div>
              <div class="paging-cover">
                <b-pagination
                  class="mt-3"
                  v-model="pageNum"
                  :total-rows="totalNum"
                  :per-page="pages.pageSize"
                  @page-click="pageClickFind"
                ></b-pagination>
              </div>
            </div>
            <div v-else-if="tableData.length > 0">
              <div class="countData">총 {{ totalNum }} 개</div>
              <div class="div-table">
                <div class="div-row">
                  <div
                    class="div-cell"
                    v-for="column in tableColumns"
                    :key="column.id"
                  >
                    <span>
                      {{ column.msg }}
                    </span>
                  </div>
                </div>
                <!-- <div class="div-row" v-for="(item, index) in paginatedData" :key="index" @click="moveSiteDetail(item.siteId)"> -->
                <div
                  class="div-row"
                  v-for="(item, index) in tableData"
                  :key="index.id"
                  @click="getDetail(index)"
                  :class="{ clickedRow: index === clickRowIndex }"
                >
                  <div
                    class="div-cell"
                    v-for="column in tableColumns"
                    :key="column.id"
                  >
                    {{ itemValue(item, column.id) }}
                  </div>
                </div>
              </div>
              <div class="paging-cover">
                <b-pagination
                  class="mt-3"
                  v-model="pageNum"
                  :total-rows="totalNum"
                  :per-page="pages.pageSize"
                  @page-click="pageClick"
                ></b-pagination>
              </div>
            </div>
            <div v-else>
              <div class="emptyContent">표시할 데이터가 없습니다.</div>
            </div>
          </div>
        </card>
      </div>

      <div class="col-6">
        <card
          class="strpied-tabled-with-hover content-card"
          body-classes="table-full-width table-responsive"
        >
          <template slot="header">
            <h4 class="card-title">건물 관리자 상세</h4>
          </template>
          <div class="detail_div">
            <div v-if="clickRowIndex >= 0">
              <table class="table">
                <tr>
                  <th>등록일</th>
                  <td>{{ this.manager.created }}</td>
                </tr>
                <tr>
                  <th>ID</th>
                  <td>{{ this.manager.email }}</td>
                </tr>
                <tr>
                  <th>건물 관리자 이름</th>
                  <td>{{ this.manager.name }}</td>
                </tr>
                <tr>
                  <th>전화 번호</th>
                  <td>{{ this.manager.tel_number }}</td>
                </tr>
                <!-- <tr>
                  <th>주소</th>
                  <td>
                    {{ this.manager.address_0 }}<br />{{ this.manager.address_1 }}
                  </td>
                </tr> -->
                <tr>
                  <th>휴대폰 번호</th>
                  <td>{{ this.manager.mob_number }}</td>
                </tr>
                <tr>
                  <th>사이트 개수</th>
                  <td>{{ this.manager.site_count }}</td>
                </tr>

                <th>사이트 정보</th>
                <div
                  v-for="(site_id, index) in site_ret"
                  :key="index"
                  :class="'siteInfoTB test' + index"
                >
                  <tr>
                    <th>사이트ID</th>
                    <td v-if="site_id.site_id === 0">미등록</td>
                    <td v-else>
                      {{ site_id.site_id }}
                    </td>
                  </tr>
                  <tr>
                    <th>사이트명</th>
                    <td v-if="site_id.name === '0'"></td>
                    <td v-else>
                      {{ site_id.name }}
                    </td>
                  </tr>

                  <tr>
                    <th>주소</th>
                    <td v-if="site_id.address_0 === '0'"></td>
                    <td v-else>
                      {{ site_id.address_0 }}
                      <br />
                      {{ site_id.address_1 }}
                    </td>
                  </tr>
                  <tr>
                    <th>사용 상태</th>
                    <td v-if="site_id.status === 'ACTIVE'">사용중</td>
                    <td v-else-if="site_id.status === 'NOTWORK'">사용안함</td>
                  </tr>
                  <tr>
                    <th>정기권 여부</th>
                    <td v-if="site_id.subs === 'approved'">사용</td>
                    <td v-else-if="site_id.subs === 'disapproved'">사용안함</td>
                  </tr>
                  <th>인증 계좌 정보</th>
                  <td
                    v-show="site_id.site_id == account_id.site_id"
                    v-for="(account_id, index) in bank_ret"
                    :key="index"
                  >
                    {{ account_id.name }}&nbsp;
                    {{ account_id.acc_holder }}&nbsp;
                    {{ account_id.acc_num }}&nbsp;&nbsp;
                    {{ account_id.acc_check }}
                  </td>
                  <tr id="detail_div">
                    <th>계좌 인증</th>
                    <td
                      v-show="site_id.site_id == wait_id.site_id"
                      v-for="(wait_id, index) in bank_wait_ret"
                      :key="index"
                    >
                      {{ wait_id.name }}
                      {{ wait_id.acc_holder }}
                      {{ wait_id.acc_num }}
                      {{ wait_id.acc_requested }}
                      <img
                        v-bind:src="wait_id.acc_upload"
                        style="width: 200px"
                        alt="pic"
                      />

                      <button
                        class="btn btn-outline btn-wd btn-info approval"
                        name="data"
                        type="button"
                        @click="app_btn(index)"
                      >
                        승인
                      </button>
                    </td>
                  </tr>
                  <tr style="border-bottom: 1px solid">
                    <th>계좌 인증 히스토리</th>
                    <td>
                      <div id="Acc_List" data-app>
                        <v-row justify="center">
                          <v-dialog
                            class="m_dialog"
                            v-model="dialog"
                            persistent
                            :retain-focus="false"
                            max-width="60%"
                          >
                            <template v-slot:activator="{ on, attrs }">
                              <button
                                style="margin: 10px"
                                v-bind="attrs"
                                v-on="on"
                                class="btn btn-fill btn-wd btn-primary"
                                @click="acc_btn(index)"
                              >
                                내역 보기
                              </button>
                            </template>

                            <!-- 모달창 텍스트내용 -->
                            <v-card>
                              <v-card-title class="text-h5">
                                <h3>계좌 인증 히스토리</h3>
                              </v-card-title>
                              <div class="div-table">
                                <div class="div-row">
                                  <div
                                    class="div-cell"
                                    v-for="column in acc_tableColumns"
                                    :key="column.id"
                                  >
                                    <span>
                                      {{ column.msg }}
                                    </span>
                                  </div>
                                </div>
                                <!-- <div class="div-row" v-for="(item, index) in paginatedData" :key="index" @click="moveSiteDetail(item.siteId)"> -->
                                <div
                                  class="div-row"
                                  v-for="(item, index) in acc_tableData"
                                  :key="index.id"
                                >
                                  <div
                                    class="div-cell"
                                    v-for="column in acc_tableColumns"
                                    :key="column.id"
                                  >
                                    <div v-if="column.id == 'acc_statuss'">
                                      <span
                                        v-if="
                                          itemValue(item, 'acc_status') == 'Y'
                                        "
                                        style="color: blue"
                                        >승인</span
                                      >
                                      <span
                                        v-else-if="
                                          itemValue(item, 'acc_status') == 'N'
                                        "
                                        style="color: green"
                                        >승인대기</span
                                      >
                                      <span
                                        v-else-if="
                                          itemValue(item, 'acc_status') == 'C'
                                        "
                                        class="status_notuse"
                                        >취소</span
                                      >
                                    </div>
                                    {{ itemValue(item, column.id) }}
                                  </div>
                                </div>
                              </div>
                              <!-- <v-data-table
                              :headers="headers"
                              :items="tableData[this.index].bank_history"
                              :items-per-page="5"
                              class="elevation-1"
                            ></v-data-table> -->
                              <!-- 모달 안쪽 저장 버튼 -->
                              <v-card-actions>
                                <v-spacer></v-spacer>
                                <v-btn
                                  class="btn btn-info btn-fill float-right col-1"
                                  style="color: white"
                                  text
                                  @click="dialog = false"
                                >
                                  닫기
                                </v-btn>
                              </v-card-actions>
                            </v-card>
                          </v-dialog>
                        </v-row>
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <th>충전 제한 팝업</th>
                    <td>
                      <div class="ridio_popup">
                        <input
                          :name="'popup' + index"
                          type="radio"
                          v-model="site_id.popup"
                          value="ON"
                          class="radiobtn"
                          id=""
                        /><label class="poplabel">노출</label>
                        <input
                          :name="'popup' + index"
                          type="radio"
                          v-model="site_id.popup"
                          class="radiobtn"
                          value="OFF"
                          id=""
                        /><label class="poplabel">미노출</label>
                        <button
                          v-if="permission === 'true'"
                          class="popup_save"
                          @click="popupSave(index)"
                        >
                          저장
                        </button>
                      </div>
                    </td>
                  </tr>
                </div>

                <tr>
                  <th>플러그 개수</th>
                  <td>{{ this.manager.plug_count }}</td>
                </tr>
                <tr>
                  <th>플러그 정보</th>
                  <td>
                    <button
                      @click="windowpopup()"
                      class="btn btn-fill btn-wd btn-primary"
                    >
                      정보 보기
                    </button>
                  </td>
                </tr>
              </table>
            </div>
            <div v-else>
              <div class="emptyContent">
                상세 보기 할 데이터를 클릭해 주세요.
              </div>
            </div>
          </div>
        </card>
      </div>
    </div>
  </div>
  <div v-else>
    <card>
      <h1>해당 계정은 [고객 관리] 페이지에 접근 권한이 없습니다.</h1>
    </card>
  </div>
</template>
<script>
import Card from "src/components/Cards/Card.vue";
import BaseInput from "src/components/Inputs/BaseInput.vue";
import accessToken from "../../accessToken";
export default {
  name: "managerList",
  components: {
    Card,
    BaseInput,
  },
  data() {
    return {
      // 인증 계좌 정보 리스트
      headers: [
        {
          text: "요청일",
          align: "start",
          sortable: false,
          value: "acc_requested",
        },
        // { text: "사이트 ID", value: "site_id" },
        { text: "은행이름", sortable: false, value: "bank_name" },
        { text: "예금주", sortable: false, value: "acc_holder" },
        { text: "계좌 번호", sortable: false, value: "acc_num" },
        { text: "승인일", sortable: false, value: "acc_check" },
      ],

      index: 0,

      // 계좌 인증 히스토리 팝업
      dialog: false,

      // 테이블 페이징 갯수
      pages: { page: 1, pageSize: 15 },

      // 페이징 시작 카운팅 넘버
      pageNum: 0,

      // 전체 페이징 카운팅 넘버
      totalNum: 0,

      // 토큰 엑세스 권한
      access: accessToken.host,

      // 건물 관리자 데이터
      manager: {
        id: "",
        name: "",
        mob_number: "",
        tel_number: "",
        email: "",
        created: "",
        address_0: "",
        address_1: "",
        charge: "",
        site_count: "",
        plug_count: "",
      },

      // 계좌 인증 데이터
      app_id: {
        account_id: "",
        site_id: "",
      },

      // ??
      popup_id: {
        site_id: "",
      },

      // 인증 계좌 테이블 데이터
      acc_tableData: {
        acc_requested: "",
        site_id: "",
        bank_name: "",
        acc_holder: "",
        acc_num: "",
        acc_checks: "",
        acc_status: "",
      },
      // 충전 제한 팝업 노출 미노출 체크 데이터
      site_ret: {
        site_id: "",
        popup: "",
        permission: "",
      },

      // 인증된 계좌 리스트
      bank_ret: "",

      // 계좌 인증이 들어온 데이터 승인해줘야할 데이터
      bank_wait_ret: {
        site_id: "",
        account_id: "",
      },

      // 검색 기준(라디오버튼) 체크되어야있어야할 데이터
      testData: "전체",

      // 계좌 인증 히스토리 팝업 데이터
      popupData: [],

      // 검색 기준 인풋박스 데이터 컬럼
      searchOptionList: [
        { id: "all", name: "전체" },
        { id: "email", name: "ID" },
        { id: "name", name: "이름" },
        { id: "phone", name: "휴대폰번호" },
      ],

      // 건물 관리자 테이블 컬럼
      tableColumns: [
        { id: "email", msg: "ID" },
        { id: "name", msg: "건물 관리자 이름" },
        { id: "mob_number", msg: "휴대폰 번호" },
        // { id: "site_id", msg: "사이트 ID" },
        { id: "plug_count", msg: "플러그 개수" },
      ],

      // 계좌 내역 테이블 컬럼
      acc_tableColumns: [
        { id: "acc_requested", msg: "요청일" },
        { id: "site_id", msg: "사이트 ID" },
        { id: "bank_name", msg: "은행 이름" },
        { id: "acc_holder", msg: "예금주" },
        { id: "acc_num", msg: "계좌 번호" },
        { id: "acc_check", msg: "승인일" },
        { id: "acc_statuss", msg: "승인 여부" },
        // { id: "rain_admin", msg: "처리자" },
      ],

      // 어드민 계정 권한 데이터
      change_access: "",

      // 어드민 계정 id 데이터
      my_id: "",

      // 메인 건물사용자 테이블 데이터
      tableData: [],

      // 건물관리자 검색시 출력되는 데이터
      searchData: [],

      // 검색할때 입력하는 인풋박스 데이터
      searchInput: "",

      clickRowIndex: -1,
      permission: "",
    };
  },
  async created() {
    // 세션에서 로그인 여부 확인하고 권한부여
    if (sessionStorage.getItem("login") !== null) {
      this.change_access =
        this.$store.state.get_admin_detail.admin_user_profile.access.slice(
          1,
          2
        );
      this.my_id =
        this.$store.state.get_admin_detail.admin_user_profile.admin_id;
      this.permission =
        this.$store.state.get_admin_detail.admin_user_profile.permission;
    }
    if (this.change_access === "y") {
      // 페이지에 들어오면 테이블리스트가 출력되도록함
      this.getManagerList();
    }
  },
  computed: {
    // 테이블 페이징 생성
    pageCount() {
      //전체 페이지 수 count
      let listLeng = this.totalNum,
        listSize = this.pages.pageSize,
        page = Math.floor(listLeng / listSize);

      if (listLeng % listSize > 0) page += 1;
      return page;
    },

    // 검색해서 나오는 데이터 테이블 페이징 함수
    pageFindCount() {
      //전체 페이지 수 count
      let listLeng = this.totalNum,
        listSize = this.pages.pageSize,
        page = Math.floor(listLeng / listSize);

      if (listLeng % listSize > 0) page += 1;
      return page;
    },
  },
  methods: {
    // m.shareplug - select 페이지 에서 나오는 팝업 출력 여부 체크 함수
    async popupSave(index) {
      var data = {};
      var onoff = "";
      data.site_id = this.site_ret[index].site_id;
      data.ONOFF = this.site_ret[index].popup;
      console.log("data  :  ", data);
      if (data.ONOFF == "ON") {
        var onoff = "노출";
      } else if (data.ONOFF == "OFF") {
        var onoff = "미노출";
      }
      if (
        confirm("'충전 제한 팝업' 설정을 " + onoff + "로 변경하시겠습니까?") ==
        true
      ) {
        await this.$store.dispatch("FETCH_POPUP_ONOFF", data);
      }
      // console.log(this.popupData[index]);
    },

    // 검색시 엔터키 눌렀을 경우 처리 되도록 하는 함수
    enterkey() {
      if (window.event.keyCode == 13) {
        this.findManager();
      }
    },

    // 플러그 정보 보기 팝업
    windowpopup() {
      // localhost
      if (this.access === true) {
        window.open(
          "http://localhost:8080/operator/management/plugList/" +
            this.manager.id,
          "window_name",
          "width=1400,height=1000,location=no,status=no,scrollbars=yes"
        );
      } else {
        window.open(
          // server
          "https://admin.shareplug.co.kr/operator/management/plugList/" +
            this.manager.id,
          "window_name",
          "width=1400,height=1000,location=no,status=no,scrollbars=yes"
        );
      }
    },

    // 계좌 인증 승인 함수
    async app_btn(index) {
      this.app_id.site_id = this.bank_wait_ret[index].site_id;
      this.app_id.account_id = this.bank_wait_ret[index].account_id;
      if (confirm("다음 요청하신 계좌로 변경하는 것이 맞습니까?") == true) {
        const response = await this.$store.dispatch(
          "FETCH_GET_ADMIN_APPROVAL",
          this.app_id
        );

        if (response.data.result == true) {
          const response = await this.$store.dispatch(
            "FETCH_GET_MANAGER_LIST",
            this.pages
          );
          console.log(response.data);

          var list = response.data.data.user_list;
          this.totalNum = response.data.data.total;
          this.tableData = [];

          for (var item of list) {
            item.site_ret.permission = this.permission;
            var temp = {
              user_id: item.provider_id,
              site_id: item.site_id,
              email: item.email,
              name: item.name,
              team: item.team,
              mob_number: item.mob_number,
              tel_number: item.tel_number,
              address_0: item.address_0,
              address_1: item.address_1,
              created: item.created,
              plug_count: item.plug_count,
              site_count: item.site_ret.length,
              site_ret: item.site_ret,
              bank_ret: item.bank_ret,
              bank_wait_ret: item.bank_wait_ret,
              bank_history: item.bank_history,
            };
            this.tableData.push(temp);
          }
          this.clickRowIndex = -1;
          this.$forceUpdate(this.getDetail(this.index));
        }
      }
    },

    list: function () {
      var list = [];
      for (var i = 1; i < this.age; i += 2) list.push(i);
      return list;
    },

    itemValue(item, column) {
      return item[column.toLowerCase()];
    },
    // async imgDownload(){
    //   return this.bank_wait_ret ? this.bank_wait_ret[0].acc_upload : '';
    // },

    /* 페이징 클릭 시 */
    pageClick(button, page) {
      this.getManagerList(page);
    },

    // 검색시 페이징 클릭
    pageClickFind(button, page) {
      this.findManager(page);
    },

    // 건물 관리자 검색 기능
    async findManager(page) {
      this.pageNum = page ? page : 1;
      this.pages.page = this.pageNum;

      // 검색 기준 id 값 체크
      if (this.searchOptionList.id === undefined) {
        this.searchOptionList.id = "all";
      }

      if (this.testData === "") {
        alert("계좌인증 요청을 선택해주세요");
      } else {
        var data = {};
        data.id = this.searchOptionList.id;
        data.search = this.searchInput;
        data.billing = this.testData;
        data.page = this.pages.page;
        data.lines = this.pages.pageSize;

        const response = await this.$store.dispatch("FIND_MANAGER", data);
        console.log(response);
        var list = response.data.data.find_list;
        this.totalNum = response.data.data.total;

        if (!list || list.length === 0) {
          // alert("검색된 데이터가 없습니다. 다시 검색하시기 바랍니다.");
          this.tableData = [];
        }
        this.searchData = [];
        for (var item of list) {
          var temp = {
            user_id: item.provider_id,
            site_id: item.site_id,
            email: item.email,
            name: item.name,
            team: item.team,
            mob_number: item.mob_number,
            tel_number: item.tel_number,
            address_0: item.address_0,
            address_1: item.address_1,
            created: item.created,
            plug_count: item.plug_count,
            site_count: item.site_ret.length,
            site_ret: item.site_ret,
            bank_ret: item.bank_ret,
            bank_wait_ret: item.bank_wait_ret,
            bank_history: item.bank_history,
          };
          this.searchData.push(temp);
        }
        this.clickRowIndex = -1;
      }
    },

    // 검색 데이터 디테일 정보
    async findManagerDetail(index) {
      this.clickRowIndex = index;
      this.manager.email = this.searchData[index].email;
      this.manager.name = this.searchData[index].name;
      this.manager.mob_number = this.searchData[index].mob_number;
      this.manager.tel_number = this.searchData[index].tel_number;
      this.manager.id = this.searchData[index].user_id;
      this.manager.address_0 = this.searchData[index].address_0;
      this.manager.address_1 = this.searchData[index].address_1;
      this.manager.created = this.searchData[index].created;
      this.manager.charge = this.searchData[index].charge;
      this.manager.site_count = this.searchData[index].site_count;
      this.manager.plug_count = this.searchData[index].plug_count;
      this.site_ret = this.searchData[index].site_ret;
      this.bank_ret = this.searchData[index].bank_ret;
      this.bank_wait_ret = this.searchData[index].bank_wait_ret;
      this.bank_history = this.searchData[index].bank_history;
      this.index = index;
    },

    // 메인 테이블 디테일 데이터 정보
    async getDetail(index) {
      this.clickRowIndex = index;
      this.manager.email = this.tableData[index].email;
      this.manager.name = this.tableData[index].name;
      this.manager.mob_number = this.tableData[index].mob_number;
      this.manager.tel_number = this.tableData[index].tel_number;
      this.manager.id = this.tableData[index].user_id;
      this.manager.address_0 = this.tableData[index].address_0;
      this.manager.address_1 = this.tableData[index].address_1;
      this.manager.created = this.tableData[index].created;
      this.manager.charge = this.tableData[index].charge;
      this.manager.site_count = this.tableData[index].site_count;
      this.manager.plug_count = this.tableData[index].plug_count;
      this.site_ret = this.tableData[index].site_ret;
      this.bank_ret = this.tableData[index].bank_ret;
      this.bank_wait_ret = this.tableData[index].bank_wait_ret;
      this.bank_history = this.tableData[index].bank_history;
      this.popup = this.tableData[index].popup;
      this.index = index;
      for (var i = 0; i < this.site_ret.length; i++) {
        this.popupData = this.tableData[index].site_ret[i].popup;
      }
    },

    // 건물사용자 메인 테이블 데이터
    async getManagerList(page) {
      this.pageNum = page ? page : 1;
      this.pages.page = this.pageNum;

      const response = await this.$store.dispatch(
        "FETCH_GET_MANAGER_LIST",
        this.pages
      );
      console.log(response.data);

      var list = response.data.data.user_list;
      this.totalNum = response.data.data.total;
      this.tableData = [];

      for (var item of list) {
        var temp = {
          user_id: item.provider_id,
          site_id: item.site_id,
          email: item.email,
          name: item.name,
          team: item.team,
          mob_number: item.mob_number,
          tel_number: item.tel_number,
          address_0: item.address_0,
          address_1: item.address_1,
          created: item.created,
          plug_count: item.plug_count,
          site_count: item.site_ret.length,
          site_ret: item.site_ret,
          bank_ret: item.bank_ret,
          bank_wait_ret: item.bank_wait_ret,
          bank_history: item.bank_history,
        };
        this.tableData.push(temp);
      }
      // console.log("test : ", this.tableData[0].bank_history);
    },

    // 계좌 승인 기능
    async acc_btn(index) {
      // this.pageNum = page ? page : 1;
      // this.pages.page = this.pageNum;

      var site_id = this.site_ret[index].site_id;
      const response = await this.$store.dispatch("GET_ACC_HISTORY", site_id);
      console.log(response.data);

      var list = response.data.data.acc_list;
      // this.totalNum = response.data.data.total;
      this.acc_tableData = [];

      for (var item of list) {
        var temp = {
          site_id: item.site_id,
          acc_requested: item.acc_requested,
          bank_name: item.bank_name,
          acc_holder: item.acc_holder,
          acc_num: item.acc_num,
          acc_check: item.acc_check,
          acc_status: item.acc_status,
        };
        this.acc_tableData.push(temp);
      }
      // console.log("아이디", this.site_ret[index].site_id);
    },
    // async radioChange(index) {
    //   // console.log(index);
    //   // this.index = index;
    //   // var test = "ON";
    //   // if (document.getElementById("radioOn" + index) == "ON") {
    //   //   test = "ON";
    //   // } else {
    //   //   test = "OFF";
    //   // }
    //   console.log(index);
    //   console.log(this.popupData);
    //   document.getElementById("radioOn0").value = "ON";
    //   document.getElementById("radioOn1").value = "ON";
    //   console.log(document.getElementById("radioOn" + index).value);
    //   // this.popupData = "ON";
    //   // console.log(this.popupData);
    //   // console.log(this.popupData[index]);
    //   // this.popupData[index] = "ON";
    // },
  },
};
</script>
<style scoped>
.search_div {
  padding: 10px 30px 30px 20px;
}
.search_div .search_input_row {
  margin: 20px 0 0 0;
  display: flex;
}
.search_div .search_btn {
  width: 70px;
  font-weight: 400;
  background-color: #3195cf;
  border: 1px solid #4388c1;
  color: white;
  height: 35px;
  font-size: 13px;
}
.radio_div {
  display: flex;
}
.radio_div div {
  margin-bottom: 0;
  margin-right: 30px;
}
.countData {
  padding: 15px;
}

.detail_div {
  margin: 10px 25px;
}
.table th {
  background: #f2f2f2;
}
.checkbox_div .form-check {
  display: inline-block;
}

.btn_div button {
  margin: 10px 10px;
  width: 100px;
}
.btn-primary {
  background-color: #3195cf;
  border: 1px solid #4388c1;
  color: white;
}
.approval {
  height: 30px;
  min-width: 10px;
  padding-bottom: 30px;
  margin-left: 10px;
}
.radiobtn {
  margin: 5px 8px 0 5px;
}

.p2 {
  margin: 12px 0 0 0;
  white-space: nowrap;
}
.p1 {
  margin: 5px 0 0 0;
  white-space: nowrap;
}
.seletbox {
  margin: 0 20px 0 0;
}
.inputbox {
  margin: 0 20px 2% 0;
  width: 50%;
  position: relative;
}
.popup_save {
  border: 1px solid#4388c1;
  background-color: #3195cf;
  color: white;
  border-radius: 4px;
  width: 60px;
  font-size: 16px;
  margin: 0 0 0 31px;
}
.poplabel {
  margin: auto;
  color: black;
  font-size: 16px;
}
.siteInfoTB:nth-child(4n) th {
  background-color: rgb(255, 219, 219);
}

.siteInfoTB:nth-child(4n + 1) th {
  background-color: rgb(243, 243, 155);
}

.siteInfoTB:nth-child(4n + 2) th {
  background-color: rgb(193, 214, 193);
}

.siteInfoTB:nth-child(4n + 3) th {
  background-color: rgb(173, 173, 217);
}
</style>
