<template>
  <div class="content" v-if="this.change_access === 'y'">
    <div class="row">
      <div class="col-6">
        <card
          class="strpied-tabled-with-hover content-card"
          body-classes="table-full-width table-responsive"
        >
          <template slot="header">
            <h4 class="card-title">충전 사용자11</h4>
          </template>
          <div class="search_div">
            <div class="search_input_row">
              <p class="p1">검색 기준</p>
              <base-select-search
                type="text"
                class="text-center selectInput seletbox"
                :items="searchOptionList"
                v-model="searchOptionList.id"
              ></base-select-search>
              <base-input
                type="text"
                placeholder=""
                v-model="searchInput"
                @keypress="enterkey()"
                class="inputbox"
              />
              <button
                class="btn btn-fill btn-primary btn-block search_btn"
                type="button"
                @click="findUser()"
              >
                <a style="white-space: nowrap">검색</a>
              </button>
            </div>
          </div>
            <div class="search_result_div">
            <div v-if="searchData.length > 0">
              <div class="countData">총 {{ this.totalNum }} 개</div>
              <div class="div-table">
                <div class="div-row">
                  <div
                    class="div-cell"
                    v-for="column in tableColumns"
                    :key="column.id"
                  >
                    <span>
                      {{ column.msg }}
                    </span>
                  </div>
                </div>
                <div
                  class="div-row"
                  v-for="(item, index) in searchData"
                  :key="index.id"
                  @click="findUserDetail(index)"
                  :class="{'clickedRow': index === clickRowIndex}"
                >
                  <div
                    class="div-cell"
                    v-for="column in tableColumns"
                    :key="column.id"
                  >
                    <div v-if="column.id == 'charges'">
                      <span> {{ item.charge | comma }}</span>
                    </div>
                    {{ itemValue(item, column.id) }}
                  </div>
                </div>
              </div>
              <div class="paging-cover">
                <b-pagination class="mt-3" v-model="pageNum" :total-rows="totalNum" :per-page="pages.pageSize" @page-click="pageClickFind"></b-pagination>
              </div>
            </div>
            <div v-else-if="tableData.length > 0">
              <div class="countData">총 {{ this.totalNum }} 개</div>
              <div class="div-table">
                <div class="div-row">
                  <div
                    class="div-cell"
                    v-for="column in tableColumns"
                    :key="column.id"
                  >
                    <span>
                      {{ column.msg }}
                    </span>
                  </div>
                </div>
                <div
                  class="div-row"
                  v-for="(item, index) in tableData"
                  :key="index.id"
                  @click="getDetail(index)"
                  :class="{'clickedRow': index === clickRowIndex}"
                >
                  <div
                    class="div-cell"
                    v-for="column in tableColumns"
                    :key="column.id"
                  >
                    <div v-if="column.id == 'charges'">
                      <span> {{ item.charge | comma }}</span>
                    </div>
                    {{ itemValue(item, column.id) }}
                  </div>
                </div>
              </div>
              <div class="paging-cover">
                <b-pagination class="mt-3" v-model="pageNum" :total-rows="totalNum" :per-page="pages.pageSize" @page-click="pageClick"></b-pagination>
              </div>
            </div>
            <div v-else>
              <div class="emptyContent">표시할 데이터가 없습니다.</div>
            </div>
          </div>
        </card>
      </div>

      <div class="col-6">
        <card
          class="strpied-tabled-with-hover content-card"
          body-classes="table-full-width table-responsive"
        >
          <template slot="header">
            <h4 class="card-title">충전 사용자 상세</h4>
          </template>
          <div class="detail_div">
            <div v-if="clickRowIndex >= 0">
              <table class="table">
                <tr>
                  <th>등록일</th>
                  <td>{{ this.user.created }}</td>
                </tr>
                <tr>
                  <th>ID</th>
                  <td>{{ this.user.email }}</td>
                </tr>
                <tr>
                  <th>이름</th>
                  <td>{{ this.user.name }}</td>
                </tr>
                <tr>
                  <th>휴대폰 번호</th>
                  <td>{{ this.user.mob_number }}</td>
                </tr>
                <!-- <tr>
                  <th>주소</th>
                  <td>
                    {{ this.user.address_0 }} <br />{{ this.user.address_1 }}
                  </td>
                </tr> -->

                <tr>
                  <th>최근 사용 시간</th>
                  <td>
                    {{ $getDateYYYYMMDD(this.user.time_from) }}~{{
                      $getDateYYYYMMDD(this.user.time_to)
                    }}
                  </td>
                </tr>
                <tr>
                  <th>총 사용 횟수</th>
                  <td>{{ this.user.use_count }}</td>
                </tr>
                <tr>
                  <th>총 결제 금액</th>
                  <td>{{ this.user.charge | comma }}</td>
                </tr>
                <!-- 20230628 kimjak 수정 컬럼 미사용 -->
                <!-- <tr>
                  <th>정기권 소유 여부</th>
                  <td>없음</td>
                </tr> -->
                <tr>
                  <th>전체 사용 내역</th>
                  <td>
                    <!-- 사용 내역 모달창 버튼 -->
                    <div id="User_List" data-app>
                      <v-row justify="center">
                        <v-dialog
                          class="m_dialog"
                          v-model="dialog"
                          persistent
                          max-width="80%"
                        >
                          <template v-slot:activator="{ on, attrs }">
                            <button
                              v-bind="attrs"
                              v-on="on"
                              style="red"
                              class="btn btn-fill btn-primary btn-block btn_blue form-group uselog_btn"
                            >
                              내역 보기
                            </button>
                            <!-- <v-btn
                          color="primary"
                          style="color:primary !important; background-color:#1D62F0 !important"
                          v-bind="attrs"
                          v-on="on"
                        >
                          내역 보기
                        </v-btn> -->
                          </template>

                          <!-- 모달창 텍스트내용 -->
                          <v-card>
                            <v-card-title> 전체 사용 내역 <img
                            src="~@/assets/imgs/close.jpg"
                              class="closebtn"
                              @click="reset()"
                              type="button"
                            ></img> </v-card-title>
                         
                            <!-- 검색 영역 -->
                            <div class="row searchRow div1 mb-3 ml-4">
                              <div class="radio_div">
                                 <label class="labtn"><input
                                    type="radio"
                                    id="chk_usetime_off"
                                    v-model="searchOptionRadio.id"
                                    label="all"
                                    name="all"
                                    value="all"
                                    class="radiobtn"
                                  />전체</label> 
                                 <label class="labtn"> <input
                                    type="radio"
                                    name="notice"
                                    id="chk_usetime_off"
                                    v-model="searchOptionRadio.id"
                                    label="notice"
                                    class="radiobtn rb_user_memo"
                                    value="notice"
                                  />사용자 내역 메모</label>
                                </div>
                              <div class="div2">
                                <base-select-search
                                  type="text"
                                  class="text-center selectInput startDate"
                                  :items="searchDatePropList"
                                  :selectId="searchDatePropList.id"
                                  v-model="searchDatePropList.id"
                                ></base-select-search>
                                <div>
                                  <input
                                    type="date"
                                    id="datefield"
                                    v-model="dateFrom"
                                    min="2021-01-01"
                                    max="2050-13-13"
                                    onfocus="this.max=new Date().toISOString().split('T')[0]"
                                    class="datepic1"
                                  />
                                  <input
                                    type="date"
                                    id="datefield"
                                    v-model="dateTo"
                                    min="2021-01-01"
                                    max="2050-13-13"
                                    onfocus="this.max=new Date().toISOString().split('T')[0]"
                                    class="datepic2"
                                  />
                                </div>

                                
                              <button
                                type="button"
                                id="searchBtn"
                                class="searchBtn"
                                @click="findUserPopup()"
                              >
                                <p class="p2">검 색</p>
                              </button>

                                
                              </div>
                            </div>
                            <!-- 검색 영역 END-->

                            <!-- 모달창 테이블 -->
                            <div v-if="this.seachPopupData.length > 0">
                              <div class="div-table">
                                <div class="div-row">
                                  <div
                                    class="div-cell"
                                    v-for="column in Searchpopup_tableColumns"
                                    :key="column.id"
                                    type="text"
                                  >
                                    <span>
                                      {{ column.msg }}
                                    </span>
                                  </div>
                                </div>
                                <!-- <div class="div-row" v-for="(item, index) in paginatedData" :key="index" @click="moveSiteDetail(item.siteId)"> -->
                                <div
                                  class="div-row"
                                  v-for="(item, index) in seachPopupData"
                                  :key="index.id"
                                >
                                  <div
                                    class="div-cell"
                                    v-for="column in Searchpopup_tableColumns"
                                    :key="column.id"
                                    type="text"
                                  >
                                    <div v-if="column.id == 'notice'">
                                      <span
                                        v-if="itemValue(item, 'notice') == null"
                                        >-</span
                                      >
                                    </div>
                                    <div v-if="column.id == 'cancel'">
                                      <span
                                        v-if="itemValue(item, 'cancel') == null"
                                        >-</span
                                      >
                                    </div>

                                    <span v-if="column.id === 'plug_id'">{{ itemValue(item, column.id) | plugIdComma }}</span>
                                    <span v-else>{{ itemValue(item, column.id) }}</span>
                                    <div v-if="column.id == 'charges'">
                                      <span> {{ item.charge | comma }}</span>
                                    </div>

                                    <!-- <div v-if="column.id == 'notices'">
                                    <base-input
                                      class="intext"
                                      style="padding-top: 5px; width: 90%"
                                      type="text"
                                      placeholder="텍스트를 입력하세요."
                                      v-model="item.notice"
                                      maxlength="250"
                                      name="user.notice"
                                    />
                                  </div> -->
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div v-else-if="this.popup_tableData.length > 0">
                              <div class="div-table">
                                <div class="div-row">
                                  <div
                                    class="div-cell"
                                    v-for="column in popup_tableColumns"
                                    :key="column.id"
                                    type="text"
                                  >
                                    <span>
                                      {{ column.msg }}
                                    </span>
                                  </div>
                                </div>
                                <div
                                  class="div-row"
                                  v-for="(item, index) in popup_tableData"
                                  :key="index.id"
                                >
                                  <div
                                    class="div-cell"
                                    v-for="column in popup_tableColumns"
                                    :key="column.id"
                                    type="text"
                                  >
                                    <div v-if="column.id == 'notice'">
                                      <span
                                        v-if="itemValue(item, 'notice') == null"
                                        >-</span
                                      >
                                    </div>
                                    <div v-if="column.id == 'cancel'">
                                      <span
                                        v-if="itemValue(item, 'cancel') == null"
                                        >-</span
                                      >
                                    </div>

                                    <span v-if="column.id === 'plug_id'">{{ itemValue(item, column.id) | plugIdComma  }}</span>
                                    <span v-else>{{ itemValue(item, column.id) }}</span>
                                    <div v-if="column.id == 'charges'">
                                      <span> {{ item.charge | comma }}</span>
                                    </div>

                                    <!-- <div v-if="column.id == 'notices'">
                                    <base-input
                                      class="intext"
                                      style="padding-top: 5px; width: 90%"
                                      type="text"
                                      placeholder="텍스트를 입력하세요."
                                      v-model="item.notice"
                                      maxlength="250"
                                      name="user.notice"
                                    />
                                  </div> -->
                                  </div>
                                </div>
                            
                              </div>
                            </div>
                            <!-- 모달창 테이블 end -->

                            <!-- 모달 안쪽 저장 버튼 -->
                            <v-card-actions>
                              <v-spacer></v-spacer>
                              <v-btn
                                class="btn btn-fill float-right close searchBtn"
                                style="color: white"
                                text
                                @click="reset()"
                              >
                                닫기
                              </v-btn>
                              <!-- <v-btn
                                class="btn btn-info btn-fill float-right col-1"
                                style="color: white"
                                text
                                @click="saveNotice()"
                              >
                                저장
                              </v-btn> -->
                            </v-card-actions>
                          </v-card>
                        </v-dialog>
                      </v-row>
                    </div>
                  </td>
                </tr>
                <tr>
                  <th>
                    현재 포인트
                  </th>
                  <td >
                    {{this.user.refund_total+(this.user.promotion_total) | comma}}  
                  </td> 
                </tr>
                <tr>
                  <th>환불 포인트</th>
                  <td>
                    <v-row class="point_give mb-2">
                      <v-col md="2">
                        {{this.user.refund_total | comma}}
                      </v-col>
                      <v-col md="4">
                      <base-input
                        type="num"
                        placeholder=""
                        v-model="refundPoint"
                        oninput="this.value=this.value.replace(/[^0-9]/g,'');"
                        @keypress="enterkey()"
                        class="inputnum"
                      />
                      </v-col>
                      <v-col md="3"></v-col>

                      <v-col md="3">
                        <button
                          v-if="this.permission === 'true'"
                          class="btn btn-fill point_send searchBtn ml-3"
                          type="button"
                          @click="refundPointSend(index)"
                        >
                        지급
                        </button>
                      </v-col>
                    </v-row>
                    <div class="div-table">
                      <div class="div-row">
                        <div
                          class="div-cell"
                          v-for="column in refundPointColumn"
                          :key="column.id"
                        >
                          <span>
                            {{ column.msg }}
                          </span>
                        </div>
                      </div>
                      <div
                        class="div-row"
                        v-for="(item, index) in refundPagingDate"
                        :key="index.id"
                      >
                        <div
                          class="div-cell"
                          v-for="column in refundPointColumn"
                          :key="column.id"
                        >
                          <div v-if="column.id == 'charges'">
                            <span> {{ item.charge | comma }}</span>
                          </div>
                          {{ itemValue(item, column.id) }}
                        </div>
                      </div>
                    </div>
                    <div
                      class="moreview btn btn-default btn-block"
                      v-show="refundPointSize > i"
                      @click="refundMoreView"
                      style="border: none"
                    >
                      더보기▼
                    </div>
                  </td>
                </tr>
                <tr>
                  <th>프로모션 포인트</th>
                  <td>
                    
                    <v-row class="point_give mb-2">
                      <v-col md="2">
                       {{this.user.promotion_total | comma}}
                      </v-col>
                      <v-col md="4">
                        <base-input
                          type="num"
                          placeholder=""
                          v-model="promotionPoint"
                          oninput="this.value=this.value.replace(/[^0-9]/g,'');"
                          @keypress="enterkey()"
                          class="inputnum"
                        />

                      </v-col>
                      <v-col md="3">
                        <input
                          type="date"
                          id="datefield"
                          v-model="pointDate"
                          min="2021-01-01"
                          max="2050-13-13"
                          class="date1"
                        />
                      </v-col>
                      <v-col md="3">
                        <button
                          v-if="this.permission === 'true'"
                          class="btn btn-fill point_send ml-3"
                          type="button"
                          @click="promotionPointSend(index)"
                        >
                          지급
                        </button>
                      </v-col>
                    </v-row>

                    <div class="div-table">
                      <div class="div-row">
                        <div
                          class="div-cell"
                          v-for="column in promotionPointColumn"
                          :key="column.id"
                        >
                          <span>
                            {{ column.msg }}
                          </span>
                        </div>
                      </div>
                      <div
                        class="div-row"
                        v-for="(item, index) in promotionPagingData"
                        :key="index.id"
                      >
                        <div
                          class="div-cell"
                          v-for="column in promotionPointColumn"
                          :key="column.id"
                        >
                          <div v-if="column.id == 'charges'">
                            <span> {{ item.charge | comma }}</span>
                          </div>
                          {{ itemValue(item, column.id) }}
                        </div>
                      </div>
                    </div>
                    <div
                      class="moreview btn btn-default btn-block"
                      v-show="promotionPointSize > i"
                      @click="promotionMoreView"
                      style="border: none"
                    >
                      더보기▼
                    </div>
                  </td>
                </tr>
              </table>
            </div>
            <div v-else>
              <div class="emptyContent">
                상세 보기 할 데이터를 클릭해 주세요.
              </div>
            </div>
          </div>
        </card>
      </div>
    </div>
    <!-- //row -->
  </div>
  <div v-else>
    <card>
      <h1>해당 계정은 [고객 관리]  페이지에 접근 권한이 없습니다.</h1>
    </card>
  </div>
</template>
<script>
import Card from "src/components/Cards/Card.vue";
import BaseInput from "src/components/Inputs/BaseInput.vue";
import "vue2-datepicker/index.css";

export default {
  filters: {
    comma(val) {
      return String(val).replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    },
    plugIdComma(val){
      return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ")
    }
  },
  name: "User_List",

  components: {
    Card,
    BaseInput,
  },
  data() {
    return {

      // 페이징 갯수
      pages: { page: 1, pageSize: 15 },

      // 페이징 시작 넘버
      pageNum: 0,

      // 페이징 토탈넘버
      totalNum: 0,

      // 환불 포인트 갯수
      refundPointSize: 3,

      // 프로모션 포인트 갯수
      promotionPointSize:3,

      // 더보기 클릭시 나오는 컬럼 갯수
      i: 3,

      // 더보기 클릭시 나오는 컬럼 갯수
      moreLine: 3,

      // 전체 사용 내역 날짜 셀렉트박스
      dateFrom: "",
      dateTo: "",

      // 어드민 계정 권한 데이터
      change_access: "",
      my_id: "",

      // 충전 사용자 데이터
      user: {
        id: "",
        name: "",
        mob_number: "",
        email: "",
        created: "",
        address_0: "",
        address_1: "",
        charge: "",
        use_count: "",
        time_from: "",
        time_to: "",
        refund_total:"",
        promotion_total:"",

        // 전체사용내역
        site_id: "",
        plug_id: "",
        t_from: "",
        t_to: "",
        charge_de: "",
        notice: "",
        cancel: "",
      },

      // 충전사용자 디테일 데이터
      user_detail_ret: {
        plug_record_id: "",
        site_id: "",
        plug_id: "",
        time_from: "",
        time_to: "",
        charge: "",
        notice: "",
        cancel: "",
      },

      // 환불 포이트 데이터
      refund_point_ret:[],

      // 프로모션 포이트 데이터
      promotion_point_ret:{},

      // 내역보기 다이올로그 팝업 여부
      dialog: false,

      // 검색 기준 인풋박스 
      searchOptionList: [
        { id: "all", name: "전체" },
        { id: "email", name: "ID" },
        { id: "name", name: "이름" },
        { id: "phone", name: "휴대폰번호" },
      ],

      // 충전 사용자 메인테이블 컬럼
      tableColumns: [
        { id: "email", msg: "ID" },
        { id: "name", msg: "이름" },
        { id: "phone", msg: "휴대폰 번호" },
        { id: "use_count", msg: "총 사용 횟수" },
        { id: "charges", msg: "총 결제 금액" },
      ],

      // 환불 포인트 컬럼
      refundPointColumn: [
        { id: "point", msg: "포인트" },
        { id: "created", msg: "지급일" },
        { id: "used", msg: "사용일" },
        { id: "admin", msg: "처리자" },
      ],

      // 프로모션 포인트 컬럼
      promotionPointColumn: [
        { id: "point", msg: "포인트" },
        { id: "status", msg: "상태" },
        { id: "created", msg: "지급일" },
        { id: "used", msg: "사용일(만료)" },
        { id: "admin", msg: "처리자" },
      ],

      // 내역 보기 팝업 컬럼
      popup_tableColumns: [
        // 모달창 테이블 컬럼
        { id: "time_from", msg: "시작 시간" },
        { id: "time_to", msg: "종료 시간" },
        { id: "charge", msg: "결재 금액" },
        { id: "site_id", msg: "사용 사이트 ID" },
        { id: "plug_id", msg: "사용 플러그 ID" },
        { id: "cancel_request", msg: "취소 요청" },
        { id: "notice", msg: "사용자 내역 메모" },
      ],

      // 내역 보기 팝업 검색시 컬럼
      Searchpopup_tableColumns: [
        // 모달창 테이블 컬럼
        { id: "time_from", msg: "시작 시간" },
        { id: "time_to", msg: "종료 시간" },
        { id: "charge", msg: "결재 금액" },
        { id: "site_id", msg: "사용 사이트 ID" },
        { id: "plug_id", msg: "사용 플러그 ID" },
        { id: "cancel_request", msg: "취소 요청" },
        { id: "notice", msg: "사용자 내역 메모" },
      ],

      // 포인트 데이트
      pointDate:(new Date().getFullYear()+'-'+(new Date().getMonth()+2).toString().padStart(2,'0')+'-'+(new Date().getDate()-1).toString().padStart(2, '0')).slice(0, 10),

      // 메인 테이블 데이터
      tableData: [],

      //환불 포인트 테이블 데이터
      refundPointData:[],

      // 프로모션 포인트 테이블 데이터
      promotionPointData:[],

      // 내역보기 팝업 테이블 데이터
      popup_tableData: [],

      // 내역보기 팝업 검색 테이블 데이터
      seachPopupData: [],

      // 검색 메인 테이블 데이터
      searchData: [],

      // 검색 인풋 박스 데이터
      searchInput: "",

      // 환불 포인트 데이터
      refundPoint:"",

      // 프로모션 포인트 데이터
      promotionPoint:"",

      // 팝업 검색 라디오 id
      searchOptionRadio: { id: 'all'},

      // 팝업 시작일 종료일 셀렉트 박스
      searchDatePropList: [
        { id: "startDate", name: "시작일" },
        { id: "endDate", name: "종료일" },
      ],

      // 검색시 조건에대한 데이터
      search: {
        searchDateProp: "",
        sDate: "",
        eDate: "",
        sote: "",
        useStatus: "",
        searchText: "",
      },

      // 데이터 클릭 하기 전에 상세데이터 출력되지 않도록 하는 인덱스
      clickRowIndex: -1,

      // 인덱스
      index:'',

      // 환불포인트 데이터 테이블 페이징
      refundPagingDate:[],

      // 프로모션 포인트 데이터 테이블 페이징
      promotionPagingData:[],
      permission: "",
      //  search_table: '',
    };
  },

  async created() {
    // 세션에서 로그인 여부 확인하고 권한부여
    if (sessionStorage.getItem("login") !== null) {
      this.change_access =
        this.$store.state.get_admin_detail.admin_user_profile.access.slice(
          1,
          2
        );
      this.my_id =
        this.$store.state.get_admin_detail.admin_user_profile.admin_id;
      this.permission =
        this.$store.state.get_admin_detail.admin_user_profile.permission;
    }
    if(this.change_access === 'y'){
      // 페이지에 들어오면 테이블리스트가 출력되도록함
      this.getUserList();
    }
  },
  computed: {
    pageCount() {
      //전체 페이지 수 count
      let listLeng = this.totalNum,
        listSize = this.pages.pageSize,
        page = Math.floor(listLeng / listSize);

      if (listLeng % listSize > 0) page += 1;
      return page;
    },
    pageFindCount() {
      //전체 페이지 수 count
      let listLeng = this.totalNum,
        listSize = this.pages.pageSize,
        page = Math.floor(listLeng / listSize);

      if (listLeng % listSize > 0) page += 1;
      return page;
    },
  },

  methods: {

    // 팝업 데이터 리셋 함수
    reset() {
      if (this.seachPopupData.length > -1) {
        this.seachPopupData = [];
      }
      this.dialog = false;

      //전체 사용 내역 팝업의 검색 조건에 쓰이는 변수들 초기화
      this.searchDatePropList.id = "startDate";
      this.dateFrom = "";
      this.dateTo = "";
      this.searchOptionRadio.id = 'all';
    },

    // 엔터키 눌럿을 경우 처리되도록 하는 함수
    enterkey() {
      if (window.event.keyCode == 13) {
        this.findUser();
      }
    },

    // 환불포인트 더보기 기능
    refundMoreView() {
      this.i = this.i + this.moreLine;
      this.refundPagingDate = this.refundPointData.slice(0, this.i);
    },

    // 프로모션 포인트 더보기 기능
    promotionMoreView() {
      this.i = this.i + this.moreLine;
      this.promotionPagingData = this.promotionPointData.slice(0, this.i);
    },
    // async saveNotice() {
    //   for (var i = 0; i < this.popup_tableData.length; i++) {
    //     var data = {};
    //     console.log("데이터", this.popup_tableData[i].notice);
    //     console.log("데이터", this.popup_tableData[i].plug_record_id);
    //     data.plug_record_id = this.popup_tableData[i].plug_record_id;
    //     data.notice = this.popup_tableData[i].notice;
    //   }
      // var data = {};
      // data.plug_record_id = this.popup_tableData[0].plug_record_id;
      // data.notice = this.popup_tableData[0].notice;
      // if (this.popup_tableData.notice === null) {
      //   alert("type something there");
      // } else {
      //   const response = await this.$store.dispatch(
      //     "FETCH_CREATE_NOTICE",
      //     data
      //   );
      //   if (response.data.result === true) {
      //     this.tableData[this.index].notice = data.notice;
      //     alert("저장 완료.");
      //     window.location.reload();
      //   } else {
      //     alert("error");
      //   }
      // }
    // },

    // 환불 포인트 지급 기능
    async refundPointSend(index) {
      var data = {};
      data.point = this.refundPoint;
      data.user_id = this.user.id;
      data.admin_email = this.$store.state.get_admin_detail.admin_user_profile.admin_email;
      console.log(data)
      if(data.point === ''){
        alert("포인트를 입력하세요")
      }else{
        if(confirm(data.point+" 포인트를 지급하는 것이 맞습니까?")){
          const response = await this.$store.dispatch("CREATE_REFUND_POINT", data);
          console.log(response);
          if(response.data.result === true){
            const response = await this.$store.dispatch(
              "FETCH_GET_USER_LIST",
              this.pages
            );
            console.log(response.data);

            var list = response.data.data.user_list;
            this.totalNum = response.data.data.total;
            this.tableData = [];

            for (var item of list) {
              var temp = {
                user_id: item.user_id,
                plug_record_id: item.plug_record_id,
                email: item.email,
                name: item.name,
                team: item.team,
                phone: item.mob_number,
                address_0: item.address_0,
                address_1: item.address_1,
                created: item.created,
                charge: item.charge,
                use_count: item.use_count,
                time_from: item.time_from,
                time_to: item.time_to,

                // 전체사용내역
                user_detail_ret: item.user_detail_ret,
                refund_point_ret: item.refund_point_ret,
                promotion_point_ret: item.promotion_point_ret,
                refund_total: item.refund_total,
                promotion_total: item.promotion_total
              };
              this.tableData.push(temp);
            }
            this.clickRowIndex = -1;
            this.$forceUpdate(this.getDetail(index));
          }
        }
      }
    },

    // 프로모션 포인트 지급
    async promotionPointSend(index) {
      var data ={};
      data.point = this.promotionPoint;
      data.user_id = this.user.id;
      data.admin_email = this.$store.state.get_admin_detail.admin_user_profile.admin_email;
      data.use_date = this.pointDate;
      console.log(data)
      if(data.point === ''){
        alert("포인트를 입력하세요")
      }else{
        if(confirm(data.point + " 포인트를 지급하는 것이 맞습니까?")){
          const response = await this.$store.dispatch("CREATE_PROMOTION_POINT", data);
          console.log(response);
          if(response.data.result === true){
            const response = await this.$store.dispatch(
              "FETCH_GET_USER_LIST",
              this.pages
            );
            console.log(response.data);

            var list = response.data.data.user_list;
            this.totalNum = response.data.data.total;
            this.tableData = [];

            for (var item of list) {
              var temp = {
                user_id: item.user_id,
                plug_record_id: item.plug_record_id,
                email: item.email,
                name: item.name,
                team: item.team,
                phone: item.mob_number,
                address_0: item.address_0,
                address_1: item.address_1,
                created: item.created,
                charge: item.charge,
                use_count: item.use_count,
                time_from: item.time_from,
                time_to: item.time_to,

                // 전체사용내역
                user_detail_ret: item.user_detail_ret,
                refund_point_ret: item.refund_point_ret,
                promotion_point_ret: item.promotion_point_ret,
                refund_total: item.refund_total,
                promotion_total: item.promotion_total
              };
              this.tableData.push(temp);
            }
            this.clickRowIndex = -1;
            this.$forceUpdate(this.getDetail(index));
          }
        }
        
    
      }
    },

    // 테이블 페이징 버튼 기능
    pageClick(button, page) {
      this.getUserList(page);
    },

    // 검색 데이터 테이블 페이징 버튼 기능
    pageClickFind(button, page) {
      this.findUser(page);
    },

    // 충전 사용자 검색 기능
    async findUser(page) {
      this.pageNum = page ? page : 1;
      this.pages.page = this.pageNum;
      if (this.searchOptionList.id === undefined) {
        this.searchOptionList.id = "all";
      }
      var data = {};
      data.id = this.searchOptionList.id;
      data.search = this.searchInput;
      data.page = this.pages.page;
      data.lines = this.pages.pageSize;

      const response = await this.$store.dispatch("FIND_USER", data);
      console.log(response);
      var list = response.data.data.find_list;
      this.totalNum = response.data.data.total;

      if (!list || list.length === 0) {
        // alert("검색된 데이터가 없습니다. 다시 검색하시기 바랍니다.");
        this.tableData = [];
        list = [];
      }
      this.searchData = [];
      
      for (var item of list) {
        var temp = {
          user_id: item.user_id,
          plug_record_id: item.plug_record_id,
          email: item.email,
          name: item.name,
          team: item.team,
          phone: item.mob_number,
          address_0: item.address_0,
          address_1: item.address_1,
          created: item.created,
          charge: item.charge,
          use_count: item.use_count,
          time_from: item.time_from,
          time_to: item.time_to,
          notice: item.notice,
          // 전체사용내역
          user_detail_ret: item.user_detail_ret,
          refund_point_ret: item.refund_point_ret,
          promotion_point_ret: item.promotion_point_ret,
          refund_total: item.refund_total,
          promotion_total: item.promotion_total
        };
        this.searchData.push(temp);
      }
      this.clickRowIndex = -1;
    },

    // 내역보기 검색 기능
    async findUserPopup() {
      // alert("abc");
      if (this.searchDatePropList.id === undefined) {
        this.searchDatePropList.id = "startDate";
      }
      var data = {};
      data.id = this.searchOptionRadio.id;
      data.dateFrom = this.dateFrom;
      data.dateTo = this.dateTo;
      data.user_id = this.user.id;
      data.selDate = this.searchDatePropList.id;
      console.log(this.searchDatePropList.id);
      console.log(this.searchOptionRadio.id);
      console.log(this.dateFrom);

      const response = await this.$store.dispatch("FIND_USER_POPUP", data);
      console.log(response);
      var list = response.data.data.find_list;
      if (!list || list.length === 0) {
        alert("검색된 데이터가 없습니다. 다시 검색하시기 바랍니다.");
        return false;
      }

      this.seachPopupData = [];
      for (var item of list) {
        var temp = {
          user_id: item.user_id,
          charge: item.charge,
          time_from: this.$getDateYYYYMMDD(item.time_from),
          time_to: this.$getDateYYYYMMDD(item.time_to),
          site_id: item.site_id,
          plug_id: item.plug_id.toString(),
          cancel_request: item.cancel_request,
          notice: item.notice,
        };
        this.seachPopupData.push(temp);
      }
    },

    // 검색 데이터 디테일 정보
    async findUserDetail(index) {
      this.refundPoint = '';
      this.promotionPoint='';
      this.clickRowIndex = index;
      this.index = index;
      this.i = 3;
      this.user.email = this.searchData[index].email;
      this.user.name = this.searchData[index].name;
      this.user.mob_number = this.searchData[index].phone;
      this.user.id = this.searchData[index].user_id;
      this.user.address_0 = this.searchData[index].address_0;
      this.user.address_1 = this.searchData[index].address_1;
      this.user.created = this.searchData[index].created;
      this.user.charge = this.searchData[index].charge;
      this.user.use_count = this.searchData[index].use_count;
      this.user.time_from = this.searchData[index].time_from.toString();
      this.user.time_to = this.searchData[index].time_to.toString();
      this.user.refund_total = this.searchData[index].refund_total === null ? 0 : 
      parseInt(this.searchData[index].refund_total);
      this.user.promotion_total = this.searchData[index].promotion_total === null ? 0 :
      parseInt(this.searchData[index].promotion_total);
      // 사용내역

      this.user.site_id = this.searchData[index].site_id;
      this.user.plug_id = this.searchData[index].plug_id;
      this.user.charge_de = this.searchData[index].charge;
      this.user.cancel = this.searchData[index].cancel;
      this.user.notice = this.searchData[index].notice;
      this.user.t_from = this.searchData[index].time_from.toString();
      this.user.t_to = this.searchData[index].time_to.toString();
      this.user_detail_ret = this.searchData[index].user_detail_ret;
      //Popup table data
      this.popup_tableData = [];
      for (var i = 0; i < this.user_detail_ret.length; i++) {
        this.popup_tableData.push({
          time_from: this.$getDateYYYYMMDD(this.user_detail_ret[i].time_from),
          time_to: this.$getDateYYYYMMDD(this.user_detail_ret[i].time_to),
          charge: this.user_detail_ret[i].charge,
          site_id: this.user_detail_ret[i].site_id,
          plug_id: this.user_detail_ret[i].plug_id,
          cancel: this.user_detail_ret[i].cancel_request,
          notice: this.user_detail_ret[i].notice,
          plug_record_id: this.user_detail_ret[i].plug_record_id,
        });
      }
      //Refund Points Data
      this.refund_point_ret = this.searchData[index].refund_point_ret;
      this.refundPointData = [];
      for (var i = 0; i < this.refund_point_ret.length; i++) {
        this.refund_point_ret[i].point = this.$options.filters.numCommaFilter(this.refund_point_ret[i].point);
        this.refundPointData.push({
          point: this.refund_point_ret[i].used_status === 0 ? '+ ' 
          + this.refund_point_ret[i].point: '- ' + this.refund_point_ret[i].point,
          created: this.refund_point_ret[i].created,
          used: this.refund_point_ret[i].used === null ? '-' : this.refund_point_ret[i].used,
          admin: this.refund_point_ret[i].admin_email,
        });
      }
      this.refundPointSize = this.refund_point_ret.length;
      this.refundPagingDate = this.refundPointData.slice(0, this.i);
      
      //Promotion Points Data
      this.promotion_point_ret = this.searchData[index].promotion_point_ret;
      this.promotionPointData = [];
      for (var i = 0; i < this.promotion_point_ret.length; i++) {
        this.promotion_point_ret[i].point = this.$options.filters.numCommaFilter(this.promotion_point_ret[i].point);
        this.promotionPointData.push({
          point: this.promotion_point_ret[i].used_status === 0 ? '+ ' 
          + this.promotion_point_ret[i].point: '- '+this.promotion_point_ret[i].point,
          created: this.promotion_point_ret[i].created === this.promotion_point_ret[i].used ? "-":
           this.promotion_point_ret[i].created,
          used: this.promotion_point_ret[i].used === null ? '-' : this.promotion_point_ret[i].used,
          admin: this.promotion_point_ret[i].admin_email,
          status: this.promotion_point_ret[i].USED_STATUS1
        });
      }
      this.promotionPointSize = this.promotion_point_ret.length;
      this.promotionPagingData = this.promotionPointData.slice(0, this.i);
    },

    // 상세 정보 디테일
    async getDetail(index) {
      this.refundPoint = '';
      this.promotionPoint='';
      this.clickRowIndex = index;
      this.index = index;
      this.i = 3;
      this.user.email = this.tableData[index].email;
      this.user.name = this.tableData[index].name;
      this.user.mob_number = this.tableData[index].phone;
      this.user.id = this.tableData[index].user_id;
      this.user.address_0 = this.tableData[index].address_0;
      this.user.address_1 = this.tableData[index].address_1;
      this.user.created = this.tableData[index].created;
      this.user.charge = this.tableData[index].charge;
      this.user.use_count = this.tableData[index].use_count;
      this.user.time_from = this.tableData[index].time_from.toString();
      this.user.time_to = this.tableData[index].time_to.toString();
      this.user.refund_total = this.tableData[index].refund_total === null ? 0 : 
      parseInt(this.tableData[index].refund_total);
      this.user.promotion_total = this.tableData[index].promotion_total === null ? 0 :
      parseInt(this.tableData[index].promotion_total);
      // 사용내역

      this.user.site_id = this.tableData[index].site_id;
      this.user.plug_id = this.tableData[index].plug_id;
      this.user.charge_de = this.tableData[index].charge;
      this.user.cancel = this.tableData[index].cancel;
      this.user.notice = this.tableData[index].notice;
      this.user.t_from = this.tableData[index].time_from.toString();
      this.user.t_to = this.tableData[index].time_to.toString();
      this.user_detail_ret = this.tableData[index].user_detail_ret;
      //Popup table data
      this.popup_tableData = [];
      for (var i = 0; i < this.user_detail_ret.length; i++) {
        this.popup_tableData.push({
          time_from: this.$getDateYYYYMMDD(this.user_detail_ret[i].time_from),
          time_to: this.$getDateYYYYMMDD(this.user_detail_ret[i].time_to),
          charge: this.user_detail_ret[i].charge,
          site_id: this.user_detail_ret[i].site_id,
          plug_id: this.user_detail_ret[i].plug_id,
          cancel: this.user_detail_ret[i].cancel_request,
          notice: this.user_detail_ret[i].notice,
          plug_record_id: this.user_detail_ret[i].plug_record_id,
        });
      }
      //Refund Points Data
      this.refund_point_ret = this.tableData[index].refund_point_ret;
      this.refundPointData = [];
      for (var i = 0; i < this.refund_point_ret.length; i++) {
        this.refund_point_ret[i].point = this.$options.filters.numCommaFilter(this.refund_point_ret[i].point);
        this.refundPointData.push({
          point: this.refund_point_ret[i].used_status === 0 ? '+ ' 
          + this.refund_point_ret[i].point: '- ' + this.refund_point_ret[i].point,
          created: this.refund_point_ret[i].created,
          used: this.refund_point_ret[i].used === null ? '-' : this.refund_point_ret[i].used,
          admin: this.refund_point_ret[i].admin_email,
        });
      }
      this.refundPointSize = this.refund_point_ret.length;
      this.refundPagingDate = this.refundPointData.slice(0, this.i);

      //Promotion Points Data
      this.promotion_point_ret = this.tableData[index].promotion_point_ret;
      this.promotionPointData = [];
      for (var i = 0; i < this.promotion_point_ret.length; i++) {
        this.promotion_point_ret[i].point = this.$options.filters.numCommaFilter(this.promotion_point_ret[i].point);
        this.promotionPointData.push({
          point: this.promotion_point_ret[i].used_status == 0 ? '+ ' 
          + this.promotion_point_ret[i].point: '- '+ this.promotion_point_ret[i].point,
          created: this.promotion_point_ret[i].created === this.promotion_point_ret[i].used ? "-":
           this.promotion_point_ret[i].created,
          used: this.promotion_point_ret[i].used === null ? '-' :
            this.promotion_point_ret[i].used,
          admin: this.promotion_point_ret[i].admin_email,
          status: this.promotion_point_ret[i].USED_STATUS1
        });
      }
      this.promotionPointSize = this.promotion_point_ret.length;
      this.promotionPagingData = this.promotionPointData.slice(0, this.i);
    },


    itemValue(item, column) {
      return item[column.toLowerCase()];
    },

    // 충전 사용자 데이터 테이블
    async getUserList(page) {
      this.pageNum = page ? page : 1;
      this.pages.page = this.pageNum;

      const response = await this.$store.dispatch(
        "FETCH_GET_USER_LIST",
        this.pages
      );
      console.log(response.data);

      var list = response.data.data.user_list;

      this.totalNum = response.data.data.total;
      this.tableData = [];

      for (var item of list) {
        var temp = {
          user_id: item.user_id,
          plug_record_id: item.plug_record_id,
          email: item.email,
          name: item.name,
          team: item.team,
          phone: item.mob_number,
          address_0: item.address_0,
          address_1: item.address_1,
          created: item.created,
          charge: item.charge,
          use_count: item.use_count,
          time_from: item.time_from,
          time_to: item.time_to,

          // 전체사용내역
          user_detail_ret: item.user_detail_ret,
          refund_point_ret: item.refund_point_ret,
          promotion_point_ret: item.promotion_point_ret,
          refund_total: item.refund_total,
          promotion_total: item.promotion_total
        };
        this.tableData.push(temp);
      }
      this.clickRowIndex = -1;
    },
  },
};
</script>
<style scoped>
.close {
  margin-left: 80%;
}
.search_div {
  padding: 10px 30px 30px 20px;
}
.search_div .search_input_row {
  margin: 20px 0 0 0;
  display: flex;
}
.search_div .search_btn {
  width: 70px;
  font-weight: 400;
  background-color: #3195cf;
  border: 1px solid #4388c1;
  color: white;
  height: 35px;
  font-size: 13px;
}
.countData {
  padding: 15px;
}
.inputnum {
  border: 1px solid grey;
  margin: 0 10px 0 10px;
}
.point_send{
  width: 70px;
  height: 35px;
  font-weight: 400;
  border-radius: 4px;
  background-color: #73bce6;
  border: 1px solid #4388c1;
  color: white;
  font-size: 13px;
  padding: 0;
  white-space: nowrap;
}
.detail_div {
  margin: 10px 25px;
}
.table th {
  background: #f2f2f2;
}
.checkbox_div .form-check {
  display: inline-block;
}

.btn_div button {
  margin: 10px 10px;
  width: 100px;
}
.uselog_btn {
  width: 120px;
  height: 35px;
  margin: 10px 10px;
}
.btn-info.btn-fill {
  height: 46px;
}
.searchRow {
  padding: 0;
  margin: 0;
}
.intext {
  border-radius: 5px;
  width: 95%;
  height: 80%;
}
.p1 {
  margin: 5px 0 0 0;
  white-space: nowrap;
}
.p2 {
  margin-top: 7px;
  font-size: 14px;
}
.seletbox {
  margin: 0 20px 0 0;
}
.inputbox {
  margin: 0 20px 2% 0;
  width: 50%;
  position: relative;
}
.datepic1 {
  position: absolute;
  border-radius: 4px;
  border: 1px solid #4388c1;
  padding-left: 10px;
  margin: 0 0 0 45px;
  height: 35px;
}
.datepic2 {
  margin: 0 0 0 240px;

  position: absolute;
  border-radius: 4px;
  border: 1px solid #4388c1;
  padding-left: 10px;
  height: 35px;
}
.searchBtn {
  width: 70px;
  height: 35px;
  font-weight: 400;
  border-radius: 4px;
  background-color: #73bce6;
  border: 1px solid #4388c1;
  color: white;
  font-size: 13px;
  padding: 0;
  margin: 0px 0 0 415px;
  white-space: nowrap;
}
.div1 {
  width: 100%;
  margin: 10px 0 10px 0;
}
.div2 {
  width: 100%;
}
.startDate {
  float: left;
}
.raDiv {
  margin: -15px 0 0 600px;
  white-space: nowrap;
}
.radio_div {
  display: flex;
  margin: 20px 0 6px 20px;
  width: 15%;
  white-space: nowrap;
}
.rb_user_memo {
  margin-left: 40px;
}
.date1 {
  border-radius: 4px;
  border: 1px solid #4388c1;
  padding-left: 10px;
  height: 35px;
  width:125px;
}
.closebtn {
 width:40px;
 margin-left: auto;
}
.labtn{
  font-size: 16px;
  color:black;
}
</style>
