<template>
  <div class="content" v-if="this.change_access === 'y'">
    <div class="row">
      <div class="col-6">
        <card
          class="strpied-tabled-with-hover"
          body-classes="table-full-width table-responsive"
        >
          <template slot="header">
            <h4 class="card-title">사용 내역</h4>
          </template>
          <div class="search_div">
            <div class="search_input_row">
              <p class="p1">검색 기준</p>
              <base-select-search
                type="text"
                class="text-center selectInput seletbox"
                :items="searchOptionList"
                v-model="searchOptionList.id"
              ></base-select-search>
              <base-input
                type="text"
                placeholder=""
                v-model="searchInput"
                @keypress="enterkey()"
                class="inputbox"
              />
            </div>

            <div class="search_input_row">
              <div class="p2">시작 시간</div>

              <input
                type="date"
                id="datefield"
                v-model="dateFrom"
                min="2021-01-01"
                max="2050-13-13"
                onfocus="today"
                class="date1"
              />
              <input
                type="date"
                id="datefield"
                v-model="dateTo"
                min="2021-01-01"
                max="2050-13-13"
                onfocus="today"
                class="date2"
              />
            </div>
            <div class="search_input_row">
              <div class="p3">내역 검색</div>
              <label for="all">
                <input
                  type="checkbox"
                  name="checkall"
                  value="all"
                  id="checkall"
                  @click="allCheck()"
                  v-model="check"
                />
                <em></em>전체</label
              >
              <label for="0">
                <input
                  type="checkbox"
                  name="chk_lang"
                  id="chk1"
                  value="0"
                  v-model="searchBox"
                  @click="changeFn()"
                /><em></em>정산 전
              </label>

              <label for="1"
                ><input
                  type="checkbox"
                  name="chk_lang"
                  id="chk2"
                  value="1"
                  v-model="searchBox"
                  @click="changeFn()"
                /><em></em>정산 완료</label
              >
              <label for="2">
                <input
                  type="checkbox"
                  name="chk_lang"
                  id="chk3"
                  value="2"
                  v-model="searchBox1"
                  @click="changeFn()"
                /><em></em>미반영</label
              >
              <!-- db 및 기능상으로 존재하지 않는 부분이기에 빼놓음 -->
              <label for="4">
                <input
                  type="checkbox"
                  name="chk_lang"
                  id="chk4"
                  v-model="searchBox"
                  @click="changeFn()"
                /><em></em>취소 요청</label
              >
            </div>
            <!-- {{ searchBox }}
            {{ searchBox1 }}
            {{ check }} -->
            <button
              class="btn btn-fill btn-primary btn-block search_btn"
              type="button"
              @click="findUsing()"
            >
              <a style="white-space: nowrap">검색</a>
            </button>
          </div>

          <!-- <site-table class="table-hover table-striped"></site-table> -->
          <div class="search_result_div">
            <!-- searchData 검색하고 나오는 list -->
            <div v-if="searchData.length > 0">
              <div class="countData">총 {{ this.totalNum }} 개</div>
              <div class="div-table">
                <div class="div-row">
                  <div
                    class="div-cell"
                    v-for="column in tableColumns"
                    :key="column.id"
                  >
                    <span>
                      {{ column.msg }}
                    </span>
                  </div>
                </div>
                <div
                  class="div-row"
                  v-for="(item, index) in searchData"
                  :key="index.id"
                  @click="getFindDetail(index)"
                  :class="{ clickedRow: index === clickRowIndex }"
                >
                  <div
                    class="div-cell"
                    v-for="column in tableColumns"
                    :key="column.id"
                  >
                    {{ itemValue(item, column.id) }}
                  </div>
                </div>
              </div>
              <div class="paging-cover">
                <b-pagination
                  class="mt-3"
                  v-model="pageNum"
                  :total-rows="totalNum"
                  :per-page="pages.pageSize"
                  @page-click="pageClickFind"
                ></b-pagination>
              </div>
            </div>

            <!-- //tableData(원래 나오는 list) -->
            <div v-else-if="tableData.length > 0">
              <div class="countData">총 {{ this.totalNum }} 개</div>
              <div class="div-table">
                <div class="div-row">
                  <div
                    class="div-cell"
                    v-for="column in tableColumns"
                    :key="column.id"
                  >
                    <span>
                      {{ column.msg }}
                    </span>
                  </div>
                </div>
                <div
                  class="div-row"
                  v-for="(item, index) in tableData"
                  :key="index.id"
                  @click="getDetail(index)"
                  :class="{ clickedRow: index === clickRowIndex }"
                >
                  <div
                    class="div-cell"
                    v-for="column in tableColumns"
                    :key="column.id"
                  >
                    <span v-if="column.plug_id === 'plug_id'">{{
                      itemValue(item, column.id) | plugIdComma
                    }}</span>
                    <span v-else>{{ itemValue(item, column.id) }}</span>
                  </div>
                </div>
              </div>
              <div class="paging-cover">
                <b-pagination
                  class="mt-3"
                  v-model="pageNum"
                  :total-rows="totalNum"
                  :per-page="pages.pageSize"
                  @page-click="pageClick"
                ></b-pagination>
              </div>
            </div>
            <div v-else>
              <div class="emptyContent">표시할 데이터가 없습니다.</div>
            </div>
          </div>
        </card>
      </div>

      <div class="col-6">
        <card
          class="strpied-tabled-with-hover"
          body-classes="table-full-width table-responsive"
        >
          <template slot="header">
            <h4 class="card-title">사용 내역 상세</h4>
          </template>
          <div class="detail_div">
            <div v-if="clickRowIndex >= 0">
              <table class="table">
                <tr>
                  <th>이름</th>
                  <td>{{ this.plug.user_name }}</td>
                </tr>
                <tr>
                  <th>휴대폰 번호</th>
                  <td>{{ this.plug.user_phone }}</td>
                </tr>
                <tr>
                  <th>회원 정보</th>
                  <td>{{ this.plug.user_email }}</td>
                </tr>
                <tr>
                  <th>시작 시간</th>
                  <td>{{ this.plug.created }}</td>
                </tr>
                <tr>
                  <th>종료 시간</th>
                  <td>{{ this.plug.time_to }}</td>
                </tr>
                <tr>
                  <th>건물 관리자 이름</th>
                  <td>{{ this.plug.provider_name }}</td>
                </tr>

                <tr>
                  <th>사이트 ID</th>
                  <td>{{ this.plug.site_id }}</td>
                </tr>
                <tr>
                  <th>사이트명</th>
                  <td>{{ this.plug.site_name }}</td>
                </tr>
                <tr>
                  <th>플러그 ID</th>
                  <td>
                    {{ this.plug.plug_id | plugIdComma }}
                  </td>
                </tr>

                <!-- <tr>
                  <th>플러그 명</th>
                  <td>{{ this.plug.plug_name }}</td>
                </tr> -->

                <tr>
                  <th>선택 상품</th>
                  <td>{{ this.plug.minute }}</td>
                </tr>
                <tr>
                  <th>결제 금액</th>
                  <td>
                    {{
                      this.plug.charge === -3
                        ? "무제한"
                        : this.plug.charge === -2
                        ? "OTP"
                        : this.plug.charge | comma
                    }}
                    {{ this.plug.pay_type == "point" ? "포인트" : "" }}
                  </td>
                </tr>
                <!-- <tr>
                  <th>취소 요청</th>
                  <td></td>
                </tr> -->
                <tr>
                  <th>사용자 내역 메모</th>
                  <td>
                    <base-input
                      maxlength="250"
                      type="text"
                      placeholder=""
                      v-model="plug.notice"
                    />
                    <button
                      v-if="this.permission === 'true'"
                      class="btn btn-default btn-sm btn-fill btn-primary btn-block etcSave_btn"
                      type="button"
                      @click="saveNotice()"
                    >
                      저장
                    </button>
                  </td>
                </tr>
                <!-- <tr>
                  <th>내역 동기화</th>
                  <td></td>
                </tr> -->
                <tr>
                  <th>정산 여부</th>
                  <td>
                    {{
                      this.plug.plug_bill_created === null &&
                      this.plug.charge === -2
                        ? "otp정산 안 함"
                        : this.plug.plug_bill_created === null &&
                          this.plug.charge === -3
                        ? "otp정산 안 함"
                        : this.plug.plug_bill_created === null
                        ? "정산 전"
                        : this.plug.plug_bill_created
                    }}
                  </td>
                </tr>
                <tr>
                  <th>정산 미반영</th>
                  <td>
                    <base-input
                      type="text"
                      placeholder=""
                      v-model="plug.bill_comment"
                    />
                    <button
                      v-if="this.permission === 'true'"
                      class="btn btn-default btn-sm btn-fill btn-primary btn-block causeSave_btn"
                      @click="saveComment()"
                      type="button"
                    >
                      저장
                    </button>
                  </td>
                </tr>
                <tr>
                  <th>변경 히스토리</th>
                  <td>
                    {{
                      this.plug.bill_status === "1"
                        ? "정산후 미반영"
                        : this.plug.bill_status === "0"
                        ? "정산전 미반영"
                        : "정산"
                    }}
                    {{ this.plug.change_email }} {{ this.plug.change_time }}
                  </td>
                </tr>
              </table>
            </div>
            <div v-else>
              <div class="emptyContent">
                상세 보기 할 데이터를 클릭해 주세요.
              </div>
            </div>
          </div>
        </card>
      </div>
    </div>
  </div>
  <div v-else>
    <card>
      <h1>해당 계정은 [운영 관리] 페이지에 접근 권한이 없습니다.</h1>
    </card>
  </div>
</template>
<script>
import Card from "src/components/Cards/Card.vue";
import BaseInput from "src/components/Inputs/BaseInput.vue";
import "vue2-datepicker/index.css";
import dayjs from "dayjs";

export default {
  filters: {
    comma(val) {
      return String(val).replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    },
    plugIdComma(val) {
      return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ");
    },
  },
  components: {
    Card,
    BaseInput,
  },
  data() {
    return {
      // 내역 검색 체크 여부
      check: true,

      // 정산전, 완료 체크
      searchBox: ["0", "1"],

      // 정산 미반영 체크
      searchBox1: ["2"],

      // 검색 데이터
      searchInput: "",

      // 시작시간 데이터
      dateFrom: dayjs().subtract(1, "year").add(1, "day").format("YYYY-MM-DD"),
      dateTo: dayjs().format("YYYY-MM-DD"),

      // 페이지 갯수
      pages: { page: 1, pageSize: 15 },
      pageNum: 0,
      totalNum: 0,
      change_access: "",

      plug: {
        id: "",
        plug_id: "",
        minute: "",
        plug_name: "",
        time_from: "",
        time_to: "",
        charge: "",
        created: "",
        pay_type: "",
        point_type: "",

        //user info
        user_id: "",
        user_name: "",
        user_phone: "",
        user_email: "",

        //provider info
        provider_id: "",
        provider_name: "",
        provider_email: "",
        provider_phone: "",

        //site id
        site_id: "",
        site_name: "",
        site_type: "",

        //comment 비고
        notice: "",
        bill_comment: "",
        change_time: "",
        change_email: "",
        admin_email: "",
        bill: "",
        bill_status: "",
        bill_done: "",
        plug_bill_created: "",
      },

      // 검색 셀렉트 박스 옵션
      searchOptionList: [
        { id: "all", name: "전체" },
        { id: "user_name", name: "이름" },
        { id: "phone", name: "휴대폰번호" },
        { id: "site_name", name: "사이트명" },
        { id: "plug_id", name: "플러그 ID" },
      ],

      // 메인 테이블 컬럼
      tableColumns: [
        { id: "user_name", msg: "이름" },
        { id: "mobile_number", msg: "휴대폰 번호" },
        { id: "created", msg: "시작 시간" },
        { id: "minute", msg: "선택 상품" },
        { id: "site_name", msg: "사이트명" },
        { id: "plug_id", msg: "플러그 ID" },
      ],

      // 테이블 데이터
      tableData: [],

      // 검색 데이터
      searchData: [],
      clickRowIndex: -1,
      permission: "",
    };
  },
  async created() {
    if (sessionStorage.getItem("login") !== null) {
      this.change_access =
        this.$store.state.get_admin_detail.admin_user_profile.access.slice(
          2,
          3
        );
      this.my_id =
        this.$store.state.get_admin_detail.admin_user_profile.admin_id;
      this.plug.admin_email =
        this.$store.state.get_admin_detail.admin_user_profile.admin_email;
      this.permission =
        this.$store.state.get_admin_detail.admin_user_profile.permission;
    }
    if (this.change_access === "y") {
      this.getUsingList();
    }
  },
  computed: {
    pageCount() {
      //전체 페이지 수 count
      let listLeng = this.totalNum,
        listSize = this.pages.pageSize,
        page = Math.floor(listLeng / listSize);

      if (listLeng % listSize > 0) page += 1;
      return page;
    },
    pageFindCount() {
      //전체 페이지 수 count
      let listLeng = this.totalNum,
        listSize = this.pages.pageSize,
        page = Math.floor(listLeng / listSize);

      if (listLeng % listSize > 0) page += 1;
      return page;
    },
  },
  methods: {
    enterkey() {
      if (window.event.keyCode == 13) {
        this.findUsing();
      }
    },

    // 내역 검색 체크박스 모두 체크
    allCheck() {
      if (!this.check) {
        for (let i = 0; i < 2; i++) {
          this.searchBox.push(i);
        }
        for (let i = 2; i < 3; i++) {
          this.searchBox1.push(i);
        }
        this.check = true;
      } else {
        this.searchBox = [];
        this.searchBox1 = [];

        this.check = false;
      }
      this.$emit("checkClick", this.searchBox);
    },

    // 전체 체크시 별도체크
    changeFn() {
      if ($("#chk1").prop("checked")) {
        $("#chk1").prop("checked", true);

        $("#checkall").prop("checked", false);
      }
      if ($("#chk2").prop("checked")) {
        $("#chk2").prop("checked", true);
        $("#checkall").prop("checked", false);
      }
      if ($("#chk3").prop("checked")) {
        $("#chk3").prop("checked", true);
        $("#checkall").prop("checked", false);
      }
      if ($("#chk4").prop("checked")) {
        $("#chk4").prop("checked", true);
        $("#checkall").prop("checked", false);
      }
      if ($("#chk5").prop("checked")) {
        $("#chk5").prop("checked", true);
        $("#checkall").prop("checked", false);
      }
    },

    itemValue(item, column) {
      return item[column.toLowerCase()];
    },
    // async imgDownload(){
    //   return this.bank_wait_ret ? this.bank_wait_ret[0].acc_upload : '';
    // },
    /* 페이징 클릭 시 */
    pageClick(button, page) {
      this.getUsingList(page);
    },
    pageClickFind(button, page) {
      this.findUsing(page);
    },

    // 내역 검색 기능
    async findUsing(page) {
      this.pageNum = page ? page : 1;
      this.pages.page = this.pageNum;
      if (this.searchOptionList.id === undefined) {
        this.searchOptionList.id = "all";
      }
      if (this.dateFrom === "" || this.dateTo === "") {
        alert("날짜를 선택해주세요");
      } else {
        var data = {};
        data.id = this.searchOptionList.id;
        data.search = this.searchInput;
        data.dateFrom = this.dateFrom;
        data.dateTo = this.dateTo;
        data.check = this.check;
        data.searchBox = this.searchBox;
        data.searchBox1 = this.searchBox1;
        data.page = this.pages.page;
        data.lines = this.pages.pageSize;
        const response = await this.$store.dispatch("FIND_USING", data);

        // if (response.data.result === false) {
        //   this.tableData = [];
        //   return false;
        // }
        var list = response.data.data.find_list;
        this.totalNum = response.data.data.total;

        if (!list || list.length === 0) {
          // alert("검색된 데이터가 없습니다. 다시 검색하시기 바랍니다.");
          this.tableData = [];
          list = [];
        }
        this.searchData = [];
        for (var item of list) {
          var temp = {
            //plug info
            id: item.id,
            plug_id: item.plug_id,
            minute: item.minute,
            plug_name: item.plug_name,
            time_from: item.time_from,
            time_to: this.$getDateYYYYMMDD(item.time_to),
            charge: item.charge,
            created: item.created,
            pay_type: item.pay_type,
            point_type: item.point_type,
            mobile_number: item.mob_number,

            //user info
            user_id: item.user_id,
            user_name: item.user_name,
            user_phone: item.user_phone,
            user_email: item.user_email,

            //provider info
            provider_id: item.provider_id,
            provider_name: item.provider_name,
            provider_email: item.provider_email,
            provider_phone: item.provider_phone,

            //site_info
            site_id: item.site_id,
            site_name: item.site_name,
            site_type: item.site_type,

            //change
            change_email: item.change_email,
            change_time: item.change_time,
            notice: item.notice,
            bill_comment: item.bill_comment,
            bill_status: item.bill_status,
            bill_done: item.bill_done,
            plug_bill_created: item.plug_bill_created,

            //bill site
            bill_all: item.bill,
          };
          this.searchData.push(temp);
        }
        this.clickRowIndex = -1;
      }
    },

    //검색 내역 나올때 하나하나씩 보여주는 것
    async getFindDetail(index) {
      console.log(this.searchData[index]);
      this.clickRowIndex = index;
      this.plug.id = this.searchData[index].id;
      this.plug.plug_id = this.searchData[index].plug_id;
      this.plug.plug_name = this.searchData[index].plug_name;
      this.plug.created = this.searchData[index].created;
      this.plug.minute = this.searchData[index].minute;
      this.plug.time_from = this.searchData[index].time_from;
      this.plug.time_to = this.searchData[index].time_to;
      this.plug.charge = this.searchData[index].charge;
      this.plug.pay_type = this.searchData[index].pay_type;
      this.plug.point_type = this.searchData[index].point_type;

      //user ret info
      this.plug.user_id = this.searchData[index].user_id;
      this.plug.user_name = this.searchData[index].user_name;
      this.plug.user_phone = this.searchData[index].mobile_number;
      this.plug.user_email = this.searchData[index].user_email;

      //provider ret info
      this.plug.provider_id = this.searchData[index].provider_id;
      this.plug.provider_name = this.searchData[index].provider_name;
      this.plug.provider_email = this.searchData[index].provider_email;
      this.plug.provider_phone = this.searchData[index].provider_phone;
      //plug ret info
      this.plug.site_id = this.searchData[index].site_id;
      this.plug.site_name = this.searchData[index].site_name;
      this.plug.site_type = this.searchData[index].site_type;
      this.index = index;
      //change info
      this.plug.notice = this.searchData[index].notice;
      this.plug.bill_comment = this.searchData[index].bill_comment;
      this.plug.change_email = this.searchData[index].change_email;
      this.plug.change_time = this.searchData[index].change_time;
      this.plug.bill_status = this.searchData[index].bill_status;
      this.plug.bill_done = this.searchData[index].bill_done;
      this.plug.plug_bill_created = this.searchData[index].plug_bill_created;
      //bill site id
    },

    // 메인 테이블 데이터 상세정보
    async getDetail(index) {
      this.clickRowIndex = index;
      this.plug.id = this.tableData[index].id;
      this.plug.plug_id = this.tableData[index].plug_id;
      this.plug.plug_name = this.tableData[index].plug_name;
      this.plug.created = this.tableData[index].created;
      this.plug.minute = this.tableData[index].minute;
      this.plug.time_from = this.tableData[index].time_from;
      this.plug.time_to = this.tableData[index].time_to;
      this.plug.charge = this.tableData[index].charge;
      this.plug.pay_type = this.tableData[index].pay_type;
      this.plug.point_type = this.tableData[index].point_type;

      //user ret info
      this.plug.user_id = this.tableData[index].user_id;
      this.plug.user_name = this.tableData[index].user_name;
      this.plug.user_phone = this.tableData[index].mobile_number;
      this.plug.user_email = this.tableData[index].user_email;

      //provider ret info
      this.plug.provider_id = this.tableData[index].provider_id;
      this.plug.provider_name = this.tableData[index].provider_name;
      this.plug.provider_email = this.tableData[index].provider_email;
      this.plug.provider_phone = this.tableData[index].provider_phone;
      //plug ret info
      this.plug.site_id = this.tableData[index].site_id;
      this.plug.site_name = this.tableData[index].site_name;
      this.plug.site_type = this.tableData[index].site_type;
      this.index = index;
      //change info
      this.plug.notice = this.tableData[index].notice;
      this.plug.bill_comment = this.tableData[index].bill_comment;
      this.plug.change_email = this.tableData[index].change_email;
      this.plug.change_time = this.tableData[index].change_time;
      this.plug.bill_status = this.tableData[index].bill_status;
      this.plug.bill_done = this.tableData[index].bill_done;
      this.plug.plug_bill_created = this.tableData[index].plug_bill_created;

      //bill site id
    },

    // 자용자 내역 메모 저장 기능
    async saveNotice() {
      var data = {};
      data.id = this.plug.id;
      data.notice = this.plug.notice;
      if (this.plug.notice === null) {
        alert("type something there");
      } else {
        const response = await this.$store.dispatch(
          "FETCH_CREATE_NOTICE",
          data
        );
        if (response.data.result === true) {
          this.tableData[this.index].notice = data.notice;
          alert("저장 완료.");
          // window.location.reload();
        } else {
          alert("error");
        }
      }
    },

    // 정산 미반영 저장 기능
    async saveComment() {
      //plug_record table에서 plug_bill_created이라는 항목이 null이면 정산 전 미반영이고,
      //데이터가 있으면 전산 후 미반영
      if (this.plug.plug_bill_created === null) {
        this.plug.bill_status = 0;
      } else {
        this.plug.bill_status = 1;
      }
      console.log(this.plug.bill_status);

      var data = {};
      data.id = this.plug.id;
      data.admin_email = this.plug.admin_email;
      data.bill_comment = this.plug.bill_comment;
      data.bill_status = this.plug.bill_status;

      if (this.plug.bill_comment === null) {
        alert("Type something there");
      } else {
        const response = await this.$store.dispatch(
          "FETCH_CREATE_COMMENT",
          data
        );
        console.log(response);
        if (response.data.result === true) {
          this.tableData[this.index].bill_comment = response.data.data.comment;
          this.tableData[this.index].bill_status = response.data.data.status;
          this.tableData[this.index].change_email =
            response.data.data.history_email;
          this.tableData[this.index].change_time =
            response.data.data.history_time;
          this.$forceUpdate(this.getDetail(this.index));
          alert("저장 완료.");
        } else {
          alert("some error");
        }
      }
    },

    // 메인 테이블 데이터
    async getUsingList(page) {
      this.pageNum = page ? page : 1;
      this.pages.page = this.pageNum;

      const response = await this.$store.dispatch(
        "FETCH_GET_USING_LIST",
        this.pages
      );
      console.log(response.data);

      var list = response.data.data.all_list;
      this.bill = response.data.data.bill;
      this.totalNum = response.data.data.total;
      this.tableData = [];
      for (var item of list) {
        var temp = {
          //plug info
          id: item.id,
          plug_id: item.plug_id,
          minute: item.minute === 18000 ? "정기권" : item.minute,
          plug_name: item.plug_name,
          time_from: item.time_from,
          time_to: this.$getDateYYYYMMDD(item.time_to),
          charge:
            item.pay_type === "sub"
              ? "정기권"
              : item.pay_type === "otp"
              ? "OTP"
              : item.charge,
          created: item.created,
          pay_type: item.pay_type,
          point_type: item.point_type,
          mobile_number: item.mob_number,

          //user info
          user_id: item.user_id,
          user_name: item.user_name,
          user_phone: item.user_phone,
          user_email: item.user_email,

          //provider info
          provider_id: item.provider_id,
          provider_name: item.provider_name,
          provider_email: item.provider_email,
          provider_phone: item.provider_phone,

          //site_info
          site_id: item.site_id,
          site_name: item.site_name,
          site_type: item.site_type,

          //change
          change_email: item.change_email,
          change_time: item.change_time,
          notice: item.notice,
          bill_comment: item.bill_comment,
          bill_status: item.bill_status,
          bill_done: item.bill_done,
          plug_bill_created: item.plug_bill_created,
          //bill site
          bill_all: item.bill,
        };
        this.tableData.push(temp);
      }
      this.clickRowIndex = -1;
    },
  },
};
</script>
<style scoped>
.search_div {
  padding: 10px 30px 30px 20px;
}
.search_div .search_input_row {
  display: flex;
  line-height: 40px;
  margin: 10px 0 0 0;
}
.search_div .search_btn {
  width: 300px;
  font-weight: 400;
  background-color: #3195cf;
  border: 1px solid #4388c1;
  color: white;
  height: 35px;
  font-size: 13px;
  margin: auto;
}
.countData {
  padding: 15px;
}

.detail_div {
  margin: 10px 25px;
}
.table th {
  background: #f2f2f2;
}
.checkbox_div .form-check {
  display: inline-block;
}

.btn_div button {
  margin: 10px 10px;
  width: 100px;
}
.uselog_btn {
  color: black;
}

.search_div .search_input_row .startTime_div div {
  display: inline-block;
  font-size: 11px;
  color: white;
  background-color: #7c8585;
  text-align: center;
  vertical-align: middle;
  border-right: 0.1px solid black;
  height: 30px;
  line-height: 30px;
  width: 44px;
}

.search_div .search_input_row .startTime_div div.active {
  background-color: #292929;
}

.checkbox_div {
  display: flex;
}

.checkbox_div .form-check-box {
  margin-right: 20px;
}

.etcSave_btn,
.causeSave_btn {
  width: 100px;
  height: 30px;
  float: right;
}

.date1 {
  border-radius: 4px;
  border: 1px solid #4388c1;
  padding-left: 10px;
  height: 35px;
  margin: 14px 0 0 20px;
}
.date2 {
  border-radius: 4px;
  border: 1px solid #4388c1;
  padding-left: 10px;
  height: 35px;
  margin: 14px 0 0 30px;
}
.p1 {
  margin: 5px 0 0 10px;
  white-space: nowrap;
}
.p2 {
  margin: 5px 0 0 10px;
  white-space: nowrap;
}
.p3 {
  margin: 10px 10px 0 10px;
  white-space: nowrap;
}
.seletbox {
  margin: 0 20px 0 0;
  white-space: nowrap;
}
.inputbox {
  margin: 0 20px 2% 0;
  width: 50%;
  position: relative;
}

label {
  margin-left: 25px;
  font-size: 16px;
  margin-top: 12px;
  white-space: nowrap;
}
em {
  margin-right: 10px;
}
</style>
