import Vue from "vue";
import axios from "axios";
import accessToken from "./accessToken";

var access = accessToken.host;
if (access === true) {
  axios.defaults.baseURL = "http://localhost:80/raincircle_php/api.php";
} else {
  axios.defaults.baseURL = "https://admin.shareplug.co.kr/web/api";
}

//서버에 올릴떄 쓰기

axios.defaults.headers.post["Content-Type"] = "application/json; charset=utf-8";

const _axios = axios.create({
  withCredentials: access,
});

/* TODO (나경) request 전에 세션 있는지 체크 하고 없으면 메인페이지 이동, response 에러나면 에러창 발생
// axios.interceptors.request.use(
//   async function (config) {
//     try {
//       let access_token = window.sessionStorage.getItem('access_token');

//       if(!vue.$isNull(access_token)) _.merge(config.headers, {'Authorization':'Bearer '+access_token});
//       else delete config.headers.Authorization;
      
//       if(_.indexOf(allowUrl, config.url) == -1 && !vue.$accessToeknCheck()) {
//         let returnVal = await vue.$getAccessToken();
//         access_token = window.sessionStorage.getItem('access_token');

//         if(returnVal && !vue.$isNull(access_token) && vue.$accessToeknCheck()){
//           _.merge(config.headers, {'Authorization':'Bearer '+access_token});
//           return config;
//         }else{
//           //vue.$awn.alert('인증토큰 획득에 실패하였습니다.');
//         }
//       }

//       return config;
//     } catch (err) {
//       console.error('[_axios.interceptors.request] config : ' + err.message);
//     }
//   },
//   function (error) {
//     try {
//       vue.$awn.alert('request : '+error.message);
//       // Do something with request error
//       return Promise.reject(error);
//     } catch (err) {
//       console.error('[_axios.interceptors.request] error : ' + err.message);
//     }
//   }
// );

// // Add a response interceptor
// _axios.interceptors.response.use(
//   function (response) {
//     try {
//       // Do something with response data
//       return response;
//     } catch (err) {
//       console.error('[_axios.interceptors.response] response : ' + err.message);
//     }
//   },
//   function (error) {
//     try {
//       console.log(error)
//       var errorStatus = error.response.status;

//       if(errorStatus == '400') vue.$awn.alert(error.response.data);
//       if(errorStatus == '401') vue.$awn.alert('인증에 실패했습니다.');
//       if(errorStatus == '403') vue.$awn.alert('권한이 없습니다.');
//       if(errorStatus == '500') vue.$awn.alert('서버에서 오류가 발생하였습니다.');
      
//       // var errorStatus = error.response.status;
//       // if(errorStatus == '401')
//       return error.response;
//     } catch(err) {
//       console.error('[_axios.interceptors.response] error : '+err.message);
//     }
//   }
// );
*/

Plugin.install = function (Vue) {
  Vue.axios = _axios;
  window.axios = _axios;
  Object.defineProperties(Vue.prototype, {
    axios: {
      get() {
        return _axios;
      },
    },
    $axios: {
      get() {
        return _axios;
      },
    },
  });
};

Vue.use(Plugin);

export default Plugin;
