<template>
  <div class="content">
    <div class="container-fluid" style="padding-top: 12vh">
      <div class="row d-flex justify-content-center align-items-center">
        <card class="signUpDiv">
          <h4 slot="header" class="card-title text-center">LOGIN</h4>
          <form>
            <div class="row">
              <div class="col-md-12">
                <label class="control-label"> 아이디</label>
                <base-input
                  type="text"
                  v-model="login.email"
                  placeholder="메일 형식의 ID를 입력해 주세요."
                >
                </base-input>
              </div>
            </div>

            <div class="row">
              <div class="col-md-12">
                <label class="control-label"> 비밀번호</label>
                <base-input
                  type="password"
                  @keyup="enterkey()"
                  placeholder="비밀번호를 입력해주세요."
                  v-model="login.password"
                >
                </base-input>
              </div>
            </div>
            <!-- //아직 작동 안 됌 -->
            <div class="row">
              <div class="col-md-12 text-center" style="margin-bottom: 30px">
                <!-- <label class="control-label"> 자동 로그인</label> -->
                <!-- <div class="form-check" style="float: left">
                  <base-checkbox
                    name="autoLogin"
                    id="autoLogin"
                    v-model="isautoLogin"
                    text="자동 로그인"
                  ></base-checkbox>
                </div> -->
              </div>
            </div>
            <div class="row">
              <div class="col-md-12 text-center">
                <span class="loginNoticeText">{{ this.loginNoticeText }}</span>
                <button
                  type="button"
                  class="btn btn-info btn-fill loginBtn"
                  @click="userLogin()"
                >
                  확인
                </button>
              </div>
            </div>

            <div class="clearfix"></div>
          </form>
        </card>
      </div>
    </div>
  </div>
</template>

<script>
import Card from "src/components/Cards/Card.vue";
import BaseInput from "src/components/Inputs/BaseInput.vue";

export default {
  components: {
    Card,
    BaseInput,
  },
  data() {
    return {
      login: { email: "", password: "" },
      loginNoticeText: "",
      isautoLogin: false, //자동로그인 여부
    };
  },
  created() {
    console.log("1 : " + this.$store.getters.isLoggedIn);
    console.log("isautoLogin: " + this.isautoLogin);
    if (localStorage.getItem("token") || !!this.$store.getters.isLoggedIn) {
      console.log("this.$router.push(/admin/Overview)");
      //this.$router.push("/admin/overview");
    }
  },
  methods: {
    enterkey() {
      //엔터키 입력 시 로그인
      if (window.event.keyCode == 13) {
        if (!this.login.email) {
          this.loginNoticeText = "아이디를 입력해주세요.";
        } else if (!this.login.password) {
          this.loginNoticeText = "비밀번호를 입력해주세요.";
        } else {
          this.userLogin();
        }
      }
    },
    async userLogin() {
      const response = await this.$store.dispatch("LOGIN_ADMIN", this.login);
      console.log("response: ", response.data.result);
      console.log(response.data);

      if (response.data.result) {
        // 로그인 성공
        const a = await this.$store.dispatch("GET_ADMIN_USER_DETAIL");
        sessionStorage.setItem("userprofile", a);
        sessionStorage.setItem("login", true);
        console.log(a.data);
        // window.location.href = "https://console.shareplug.co.kr/admin/overview";
        this.$router.push("/operator/Overview");
      } else {
        alert("로그인에 실패했습니다.\n이메일 혹은 비밀번호가 틀립니다.");
      }
    },
    moveSignupPage() {
      this.$router.push("/signup");
    },
  },
};
</script>
<style scoped>
.card-title {
  margin: 40px 0;
  font-weight: bold;
  font-size: 40px;
}
.signUpDiv {
  width: 700px;
}

.loginBtn {
  width: 100%;
  margin-top: 10px;
}

.form-check .form-check-sign::before,
.form-check .form-check-sign::after {
  margin-top: 0px;
}

.custom-control-label {
  font-size: 16px;
}

.loginNoticeText {
  color: red;
}

@media (max-width: 768px) {
  .signUpDiv {
    padding: 0;
  }
}

/* 여기서부터 */
.joinText {
  margin-right: 20px;
}

.idpwRow {
  color: #c2bbbb;
  margin: 10px 0 25px 0;
}

.idpwRow .idpwRowHipen {
  margin: 0 30px;
}
</style>
