v
<template>
  <div class="content" v-if="this.change_permission === 'true'">
    <div class="container-fluid">
      <card>
        <h4 slot="header" class="card-title">운영자 등록</h4>
        <form class="col-md-10">
          <div class="row">
            <div class="col-md-4">
              <label class="control-label"> ID(이메일)_필수</label>
              <base-input type="text" placeholder="" v-model="user.email">
              </base-input>
            </div>
          </div>

          <div class="row">
            <div class="col-md-4">
              <label class="control-label">PW_필수</label>
              <base-input
                type="password"
                placeholder="비밀번호"
                v-model="user.password"
              >
              </base-input>
              <!-- <p class="status col-6" :style="status.password.style">{{ isNameValid }}</p> -->
            </div>
          </div>

          <div class="row">
            <div class="col-md-4">
              <label class="control-label">이름_필수</label>
              <base-input type="text" placeholder="이름" v-model="user.name">
              </base-input>
              <p class="status col-10" :style="status.name.style">
                {{ isNameValid }}
              </p>
            </div>
          </div>

          <div class="row">
            <div class="col-md-4">
              <label class="control-label"> 휴대폰 번호_필수</label>
              <base-input
                type="text"
                placeholder="휴대폰 번호"
                v-model="user.mob_number"
              >
              </base-input>
              <p class="col-md-10" :style="status.mobile.style">
                {{ isPhoneValid }}
              </p>
            </div>
          </div>

          <div class="row">
            <div class="col-md-4">
              <label class="control-label">팀</label>
              <base-input type="text" placeholder="팀" v-model="user.team">
              </base-input>
              <!-- <p class="status col-6" :style="status.team.style">{{ isNameValid }}</p> -->
            </div>
          </div>

          <div class="row">
            <div class="col-md-12">
              <label class="control-label">
                권한(어드민에서 각종 내역을 처리할 수 있는 권한을 부여합니다.)
              </label>
              <div class="can-toggle can-toggle--size-large">
                <input id="c" type="checkbox" />
                <label for="c">
                  <div
                    class="can-toggle__switch"
                    data-checked="사용"
                    data-unchecked="미사용"
                    @click="clickTicketFlag()"
                  ></div>
                </label>
              </div>
            </div>
          </div>

          <div class="row">
            <div class="col-md-4">
              <label class="control-label">
                접근(어드민에서 각 메뉴를 접근할 수 있는 권한을 부여합니다.)
              </label>
              <div
                class="div_checkbox"
                v-for="(item, index) in user.access"
                :key="index"
              >
                <input
                  type="checkbox"
                  class="mr-2"
                  value="1"
                  id="1"
                  v-model="item.check"
                />{{ item.time }}
              </div>
            </div>
          </div>

          <div class="clearfix"></div>
        </form>

        <div class="text-center">
          <button
            type="button"
            class="btn btn-fill btn-primary btn-fill float-right savebtn"
            id="saveBtn"
            @click.prevent="signupBtn()"
          >
            등록
          </button>
        </div>
      </card>
    </div>
  </div>
  <div v-else>
    <card>
      <h1>해당 계정은 [운영자 등록] 페이지에 접근 권한이 없습니다.</h1>
    </card>
  </div>
</template>

<script>
import Card from "src/components/Cards/Card.vue";
import BaseInput from "src/components/Inputs/BaseInput.vue";
import { validatePhone, validateName } from "src/components/Utils/Validate.js";

export default {
  components: {
    Card,
    BaseInput,
  },
  data() {
    return {
      // 운영자 등록 데이터
      user: {
        email: "",
        name: "",
        mob_number: "",
        team: "",
        password: "",
        permission: false,
        access: [
          { time: "대시보드", check: false },
          { time: "고객 관리", check: false },
          { time: "운영 관리", check: false },
          { time: "정산 관리", check: false },
        ],
        value: "",
      },

      status: {
        mobile: { style: "", complete: false },
        name: { style: "", complete: false },
        // team:{ style : "", complete : false }
      },
      style: {
        warn: "color: red; font-size:12px",
        success: "color: green; font-size:12px",
      },

      // 등록할 수 있는 권한
      change_permission: "",
    };
  },
  computed: {
    // 전화번호 체크
    isPhoneValid() {
      if (this.user.mob_number === "") {
        return;
      } else if (validatePhone(this.user.mob_number)) {
        this.status.mobile.style = this.style.success;
        this.status.mobile.complete = true;
        return "사용가능한 번호입니다.";
      } else {
        this.status.mobile.style = this.style.warn;
        this.status.mobile.complete = false;
        return "형식에 맞지 않는 번호입니다.";
      }
    },

    // 이름 체크
    isNameValid() {
      if (this.user.name === "") {
        return;
      } else if (validateName(this.user.name)) {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.status.name.style = this.style.success;
        this.status.name.complete = true;
        return "사용 가능한 이름입니다";
      } else {
        this.status.name.style = this.style.warn;
        this.status.name.complete = false;
        return "영어 또는 한글만 입력 가능합니다.";
      }
    },
  },
  async created() {
    if (sessionStorage.getItem("login") !== null) {
      this.change_permission =
        this.$store.state.get_admin_detail.admin_user_profile.permission;
    }
  },
  methods: {
    // 권한 부여
    clickTicketFlag: function () {
      this.user.permission = !this.user.permission;
    },

    maxLength(id, maxLen) {
      //input 텍스트박스 글자수 제한
      var inputValue = document.getElementById(id).value;
      if (maxLen == null) {
        maxLen =
          $("#" + id).attr("maxLength") != null
            ? $("#" + id).attr("maxLength")
            : 1000;
      }

      if (inputValue.length > maxLen) {
        document.getElementById(id).value = inputValue.substring(0, maxLen);
      }
    },
    // checkValidate: function() {

    //   //필수값 체크

    //  return true;
    // },

    // 등록 버튼
    signupBtn() {
      if (
        // this.status.email.complete &&
        // this.status.password.complete &&
        this.status.mobile.complete &&
        this.status.name.complete
      ) {
        //  document.getElementsByClassName("signup")[0].disabled = false;
        this.signUp();
        return;
      }

      return alert("회원가입에 실패하였습니다.");
    },

    // 회원가입 기능
    async signUp() {
      var value = "";
      for (var i = 0; i < 4; i++) {
        if (this.user.access[i].check === true) {
          value = value + "y";
        } else {
          value = value + "n";
        }
      }
      this.user.value = value;
      console.log(this.user);
      const response = await this.$store.dispatch(
        "FETCH_CREATE_ADMIN",
        this.user
      );

      if (response.data.result === true) {
        sessionStorage.setItem("signup", true);
        alert("회원가입에 성공하였습니다.");
        this.$router.push("/operator/mypage/adminList");
      } else if (response.data.result === "EMAIL_EXISTS") {
        alert("중복된 이메일입니다. 다시 입력해주세요.");
      } else {
        alert("문제가 생겼습니다. 개발팀에 문의하세요!");
      }
    },
  },
};
</script>
<style scoped>
.savebtn {
  margin: 0 1100px 0 0;
}
.btn-primary {
  background-color: #3195cf;
  border: 1px solid #4388c1;
  color: white;
}
.find-address {
  width: 90px;
  height: 25px;
  padding: 0;
  margin: 11px 0px 11px 15px;
  background-color: #e9ecef;
}

.electric_type-group {
  margin-bottom: 1rem;
  display: flex;
  width: 100%;
  overflow-x: auto;
}

.electric_type {
  margin-right: 4px;
}

.electric_type-img {
  width: 134px;
  height: 120px;
}

.input-group-text {
  width: 35px;
}

.input-group-text span {
  text-align: center;
  margin: 0 auto;
}

.section1 {
  padding-left: 15px !important;
  padding-right: 5px !important;
}

/***********joinform css */
* {
  font-family: Noto Sans KR;
  font-style: normal;
}
.joinForm-title {
  margin-bottom: 25px;
}

#accountAuth {
  height: 25px;
  padding: 2px 0;
  top: 603px;
  right: 38px;
  position: absolute;
  font-weight: 500;
  font-size: 12.5px;
  line-height: 21px;
  width: 90px;
  background: #156ab2;
  border-radius: 3px;
  border: none;
  color: #ffffff;
}
#overlap {
  height: 25px;
  padding: 2px 0;
  top: 6.3rem;
  right: 2rem;
  position: absolute;
  font-weight: 500;
  font-size: 12.5px;
  line-height: 21px;
  width: 90px;
  background: #156ab2;
  border-radius: 3px;
  border: none;
  color: #ffffff;
}

.auth {
  min-width: 60px;
  width: 100%;
  height: 40px;
  padding: 2px 0;
  font-weight: 500;
  font-size: 15px;
  background: #156ab2;
  border-radius: 3px;
  border: none;
  color: #ffffff;
}

.twice-check {
  min-width: 60px;
  width: 100%;
  height: 40px;
  padding: 2px 0;
  font-weight: 500;
  font-size: 15px;
  background: #156ab2;
  border-radius: 3px;
  border: none;
  color: #ffffff;
  margin: 0px;
}

.find-address {
  min-width: 60px;
  width: 100%;
  height: 40px;
  padding: 2px 0;
  font-weight: 500;
  font-size: 15px;
  background: #156ab2;
  border-radius: 3px;
  border: none;
  color: #ffffff;
  margin: 0px;
}
.signup {
  height: 65px;
  margin: 15px 0px 15px 0px;
  background: #156ab2;
  border-radius: 3px;
}
.status {
  position: relative;
  top: -10px;
  left: 10px;
  font-size: 12.5px;
  margin: 0px 0px 10px 0px;
  height: 10px;
}

.backBtn {
  position: absolute;
  left: 15px;
  height: 35px;
  padding: 5px 10px;
}

.infoform-btn-div {
  margin: auto auto 16px auto;
}

.daum-address {
  margin-bottom: 25px;
}

.account_notice {
  padding: 5px 35px;
}

.account_notice span {
  display: block;
  font-weight: 400;
  font-size: 13px;
  color: #9a9a9a;
}

table {
  width: 100%;
  border-collapse: collapse;
  text-align: center;
}

#tb_acoount_history {
  font-size: 12px;
}

#tb_acoount_history tbody {
  border-top: 1px solid #156ab2;
}

.accountImgName {
  font-size: 12px;
  color: #156ab2;
  margin: -10px 0px 0px 35px;
}

/* 여기서부터 사용하는 css*/
.div_checkbox {
  display: flex;
}
.container,
.container-fluid,
.container-sm,
.container-md,
.container-lg,
.container-xl {
  width: 100%;
  padding-right: 15px;
  padding-left: 0;
}
</style>

<style lang="scss">
@mixin can-toggle-branding(
  $can-toggle-off-color: #777,
  $can-toggle-on-color: #42d0ed,
  $can-toggle-inactive-text: rgba(white, 0.5),
  $can-toggle-transition: cubic-bezier(0, 1, 0.5, 1)
) {
  input[type="checkbox"] {
    &[disabled] ~ label {
      color: rgba($can-toggle-off-color, 0.5);
    }

    &:focus ~ label,
    &:hover ~ label {
      .can-toggle__switch {
        background-color: $can-toggle-off-color;
        &:after {
          color: darken($can-toggle-off-color, 10%);
        }
      }
    }
    &:hover ~ label {
      color: darken($can-toggle-off-color, 5%);
    }
    &:checked {
      ~ label {
        &:hover {
          color: darken($can-toggle-on-color, 3%);
        }

        .can-toggle__switch {
          background-color: lighten($can-toggle-on-color, 5%);
          &:after {
            color: darken($can-toggle-on-color, 5%);
          }
        }
      }

      &:focus,
      &:hover {
        ~ label {
          .can-toggle__switch {
            background-color: $can-toggle-on-color;
            &:after {
              color: darken($can-toggle-on-color, 10%);
            }
          }
        }
      }
    }
  }

  label {
    .can-toggle__switch {
      transition: background-color 0.3s $can-toggle-transition;
      background: lighten($can-toggle-off-color, 5%);
      &:before {
        color: $can-toggle-inactive-text;
      }
      &:after {
        // Autoprefixer choked here, so making the prefixes explicit
        -webkit-transition: -webkit-transform 0.3s $can-toggle-transition;
        transition: transform 0.3s $can-toggle-transition;
        color: $can-toggle-off-color;
      }
    }
  }
}

@mixin can-toggle-appearance(
  $can-toggle-width: 134px,
  $can-toggle-height: 36px,
  $can-toggle-border-radius: 4px,
  $can-toggle-offset: 2px,
  $can-toggle-label-font-size: 14px,
  $can-toggle-switch-font-size: 12px,
  $can-toggle-shadow: 0 3px 3px rgba(black, 0.4)
) {
  $can-toggle-switch-width: $can-toggle-width/2;

  input[type="checkbox"] {
    &:focus ~ label,
    &:hover ~ label {
      .can-toggle__switch {
        &:after {
          box-shadow: $can-toggle-shadow;
        }
      }
    }

    &:checked {
      ~ label {
        .can-toggle__switch {
          &:after {
            transform: translate3d(
              $can-toggle-width -
                ($can-toggle-switch-width + $can-toggle-offset),
              0,
              0
            );
          }
        }
      }
      &:focus,
      &:hover {
        ~ label {
          .can-toggle__switch {
            &:after {
              box-shadow: $can-toggle-shadow;
            }
          }
        }
      }
    }
  }

  label {
    font-size: $can-toggle-label-font-size;

    .can-toggle__switch {
      height: $can-toggle-height;
      flex: 0 0 $can-toggle-width;
      border-radius: $can-toggle-border-radius;

      &:before {
        left: $can-toggle-width/2;
        font-size: $can-toggle-switch-font-size;
        line-height: $can-toggle-height;
        width: $can-toggle-width/2;
        padding: 0 12px;
      }

      &:after {
        top: $can-toggle-offset;
        left: $can-toggle-offset;
        border-radius: $can-toggle-border-radius/2;
        width: $can-toggle-switch-width - $can-toggle-offset;
        line-height: $can-toggle-height - ($can-toggle-offset * 2);
        font-size: $can-toggle-switch-font-size;
      }

      &:hover {
        &:after {
          box-shadow: $can-toggle-shadow;
        }
      }
    }
  }
}

.can-toggle {
  position: relative;
  margin-bottom: 15px;
  width: fit-content;
  *,
  *:before,
  *:after {
    box-sizing: border-box;
  }
  //overflow: hidden;

  input[type="checkbox"] {
    opacity: 0;
    position: absolute;
    top: 0;
    left: 0;

    &[disabled] ~ label {
      pointer-events: none;
      .can-toggle__switch {
        opacity: 0.4;
      }
    }

    &:checked {
      ~ label {
        .can-toggle__switch {
          &:before {
            content: attr(data-unchecked);
            left: 0;
          }

          &:after {
            content: attr(data-checked);
          }
        }
      }

      &:focus,
      &:hover {
        ~ label {
        }
      }
    }
  }

  label {
    user-select: none;
    position: relative;
    display: flex;
    align-items: center;

    .can-toggle__switch {
      position: relative;

      &:before {
        content: attr(data-checked);
        position: absolute;
        top: 0;
        text-transform: uppercase;
        text-align: center;
      }

      &:after {
        content: attr(data-unchecked);
        position: absolute;
        z-index: 5;
        text-transform: uppercase;
        text-align: center;
        background: white;
        transform: translate3d(0, 0, 0);
      }
    }
  }

  // Default values for .can-toggle class
  @include can-toggle-branding;
  @include can-toggle-appearance;

  // &.can-toggle--size-large {
  //   @include can-toggle-appearance(
  //     160px,
  //     0px, // Toggle
  //     50px,
  //     px,  // Toggle h
  //     4px,
  //     x,   // Toggle border r
  //     2px,
  //     x,   // Offset (distance btw switch and
  //     14px,
  //     px,  // Label font
  //     14px,
  //     px,  // Switch font
  //     0 4px 4px rgba(black, 0.4) 0.4) // Switch shadow on hover/
  //   );
  // }
}
</style>
