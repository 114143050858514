<template>
  <div class="content">
    <div class="row">
      <div class="col-6">
        <card
          class="strpied-tabled-with-hover"
          body-classes="table-full-width table-responsive"
        >
          <template slot="header">
            <h4 class="card-title">플러그 엑셀 등록</h4>
          </template>
          <div class="detail_div">
            <table class="table">
              <tr>
                <th>엑셀 양식</th>
                <td>
                  <button
                    class="btn btn-primary btn-fill btn-sm"
                    @click="downloadFile()"
                  >
                    양식 다운로드
                  </button>
                </td>
              </tr>
              <tr>
                <th>등록</th>
                <td>
                  <div class="radio_div">
                    <input
                      type="radio"
                      placeholder="비밀번호"
                      v-model="radio"
                      value="new"
                    />신규
                    <input
                      class="rd_edit"
                      type="radio"
                      placeholder="비밀번호"
                      v-model="radio"
                      value="change"
                    />변경
                  </div>
                </td>
              </tr>
              <tr>
                <th>업로드</th>
                <td>
                  <label for="files" class="btn">파일 등록 </label>
                  <label>{{ this.uploadFile.name }}</label>
                  <input
                    @change="upload($event)"
                    type="file"
                    id="files"
                    style="display: none"
                  />
                </td>
              </tr>
              <tr>
                <th>업로드 결과</th>
                <td v-if="this.result === true">
                  {{ this.time + "등록완료" }}
                </td>
                <td v-else-if="this.result === false">
                  <div id="User_List" data-app>
                    <v-row justify="center">
                      <v-dialog
                        class="m_dialog"
                        v-model="dialog"
                        persistent
                        max-width="80%"
                      >
                        <template v-slot:activator="{ on, attrs }">
                          <span>"실패"</span>
                          <button
                            v-bind="attrs"
                            v-on="on"
                            class="btn btn-outline btn-wd btn-info uselog_btn"
                          >
                            내역 보기
                          </button>
                        </template>

                        <!-- 모달창 텍스트내용 -->
                        <v-card>
                          <v-card-title class="text-h5">
                            <h2>플러그 엑셀 업로드 결과</h2>
                          </v-card-title>

                          <!-- 모달창 테이블 -->

                          <div class="mijung">등록 실패 내역</div>
                          <div class="div-table">
                            <div class="div-row">
                              <div
                                class="div-cell"
                                v-for="(column, index) in fail_Columns"
                                :key="index"
                              >
                                <span>
                                  {{ column.msg }}
                                </span>
                              </div>
                            </div>
                            <!-- <div class="div-row" v-for="(item, index) in paginatedData" :key="index" @click="moveSiteDetail(item.siteId)"> -->
                            <div
                              class="div-row"
                              v-for="(item, index) in fail_TableData"
                              :key="index"
                            >
                              <div
                                class="div-cell"
                                v-for="(column, index) in fail_Columns"
                                :key="index"
                              >
                                <div v-if="column.id == 'use_paids'">
                                  <span> {{ item.use_paid | comma }}</span>
                                </div>

                                {{ itemValue(item, column.id) }}
                                <div v-if="column.id == 'use_msg'">
                                  <input
                                    v-model="message"
                                    placeholder="텍스트를 입력하세요."
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                          <!-- 모달창 테이블 end -->

                          <!-- 모달 안쪽 저장 버튼 -->
                          <v-card-actions>
                            <v-spacer></v-spacer>
                            <v-btn
                              class="btn btn-info btn-fill float-right col-1"
                              style="color: white"
                              text
                              @click="dialog = false"
                            >
                              닫기
                            </v-btn>
                          </v-card-actions>
                        </v-card>
                      </v-dialog>
                    </v-row>
                  </div>
                </td>
              </tr>
            </table>

            <div class="col-12">
              <button
                v-show="this.change_permission === 'true'"
                type="button"
                class="btn btn-fill btn-primary btn-block search_btn"
                id="saveBtn"
                @click.prevent="modifying()"
              >
                등록하기
              </button>
            </div>
          </div>
        </card>
      </div>
      <div class="col-6">
        <card
          class="strpied-tabled-with-hover"
          body-classes="table-full-width table-responsive"
        >
          <template slot="header">
            <h4 class="card-title">등록 플러그 조회</h4>
          </template>
          <div class="search_div">
            <div class="col-2 pseach">검색 기준</div>
            <div class="col-3">
              <base-select-search
                type="text"
                class="text-center selectInput seletbox"
                :items="searchOptionList"
                v-model="searchOptionList.id"
              ></base-select-search>
            </div>
            <div class="col-6">
              <base-input
                v-model="searchInput"
                type="find"
                placeholder=""
                @keypress="enterkey()"
                class="inputbox"
              />
            </div>
            <button
              class="btn btn-fill btn-primary btn-block search_btn"
              type="button"
              @click="findUser()"
            >
              검색
            </button>
          </div>
          <div class="search_result_div">
            <div v-if="searchData.length > 0">
              <div class="countData">총 {{ totalNum }} 개</div>
              <div class="div-table">
                <div class="div-row">
                  <div
                    class="div-cell"
                    v-for="column in tableColumns"
                    :key="column.id"
                  >
                    <span>
                      {{ column.msg }}
                    </span>
                  </div>
                </div>
                <div
                  class="div-row"
                  v-for="(item, index) in searchData"
                  :key="index.id"
                >
                  <div
                    class="div-cell"
                    v-for="column in tableColumns"
                    :key="column.id"
                  >
                    <span v-if="column.id === 'plug_id'">
                      {{ itemValue(item, column.id) | plugIdComma }}
                    </span>
                    <span v-else>{{ itemValue(item, column.id) }}</span>
                  </div>
                </div>
              </div>
              <div class="btn-cover">
                <ul class="pagination pagination-no-border pagination-">
                  <li class="page-item prev-page">
                    <button
                      aria-label="Previous"
                      @click="findUser(pageNum - 1)"
                      v-bind:class="{
                        'page-link': true,
                        disabled: pageNum == 1,
                      }"
                    >
                      <span>&lt;&lt;</span>
                    </button>
                  </li>
                  <li
                    class="page-item"
                    v-for="(item, index) in pageCount"
                    :key="item.id"
                    :class="{ active: pageNum == index + 1 }"
                  >
                    <a class="page-link" @click="findUser(index + 1)">{{
                      index + 1
                    }}</a>
                  </li>
                  <li class="page-item page-pre next-page">
                    <button
                      @click="findUser(pageNum + 1)"
                      v-bind:class="{
                        'page-link': true,
                        disabled: pageNum >= pageCount,
                      }"
                    >
                      <span>&gt;&gt;</span>
                    </button>
                  </li>
                </ul>
              </div>
            </div>
            <div v-else>
              <div class="emptyContent">표시할 데이터가 없습니다.</div>
            </div>
          </div>
        </card>
      </div>
    </div>
  </div>
</template>
<script>
import Card from "src/components/Cards/Card.vue";
import BaseInput from "src/components/Inputs/BaseInput.vue";
import Axios from "axios";

export default {
  components: {
    Card,
    BaseInput,
  },
  filters: {
    plugIdComma(val) {
      return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ");
    },
  },
  data() {
    return {
      // 검색 인풋박스 데이터
      searchInput: "",

      disabled: false,

      index: 0,

      // 팝업 여부
      dialog: false,

      // 페이징 갯수
      pages: { page: 1, pageSize: 5 },
      pageNum: 0,
      totalNum: 0,

      // 엑셀파일 업로드
      uploadFile: "",

      // 라디오 버튼
      radio: "",

      time: "",
      result: "",

      // 신규 변경 데이터
      fileData: "",

      datas: "",

      // 검색 셀렉트박스 옵션
      searchOptionList: [
        { id: "all", name: "전체" },
        { id: "plug_id", name: "플러그 ID" },
        { id: "serial_num", name: "일련번호" },
      ],

      // 검색 데이터 컬럼
      tableColumns: [
        { id: "plug_id", msg: "플러그 ID" },
        { id: "serial_num", msg: "일련번호" },
        { id: "status", msg: "상태" },
      ],

      // 테이블 데이터
      tableData: [],

      // 검색 데이터
      searchData: [],
      change_permission: "",
      my_id: "",

      //
      value_empty_fail: [],
      value_number_fail: [],
      plug_duplicate: [],
      serial_duplicate: [],
      // 모달창 안쪽 내역보기 미정산 테이블
      fail_Columns: [
        { id: "num", msg: "엑셀 행번호" },
        { id: "plug_id", msg: "플러그 ID" },
        { id: "reason", msg: "실패 사유" },
      ],
      fail_TableData: [],
    };
  },
  async created() {
    console.log(this.$store.state.get_admin_detail.admin_user_profile);
    if (sessionStorage.getItem("login") !== null) {
      this.change_permission =
        this.$store.state.get_admin_detail.admin_user_profile.permission;
      this.my_id =
        this.$store.state.get_admin_detail.admin_user_profile.admin_id;
    }
  },
  computed: {
    pageCount() {
      //전체 페이지 수 count
      let listLeng = this.totalNum,
        listSize = this.pages.pageSize,
        page = Math.floor(listLeng / listSize);

      if (listLeng % listSize > 0) page += 1;
      // console.log(page);
      return page;
    },
  },
  mounted() {
    this.uploadFile;
  },
  methods: {
    enterkey() {
      if (window.event.keyCode == 13) {
        this.findUser();
      }
    },

    downloadFile() {
      Axios({
        url: "https://admin.shareplug.co.kr/_web_api/plugexcel.xlsx",
        method: "GET",
        responseType: "blob",
        // withCredentials: true
      }).then((response) => {
        var fileUrl = window.URL.createObjectURL(new Blob([response.data]));
        var fileLink = document.createElement("a");
        fileLink.href = fileUrl;

        fileLink.setAttribute("download", "plugexcel.xlsx");
        document.body.appendChild(fileLink);

        fileLink.click();
      });
    },

    // 엑셀파일 등록
    async upload(event) {
      this.uploadFile = event.target.files[0];
      // console.log(this.uploadFile);
      var reader = new FileReader();
      var array = [];
      reader.onload = function (e) {
        var data = e.target.result;
        var XLSX = require("xlsx");
        data = new Uint8Array(data);

        var file = XLSX.read(data, { type: "array" });
        // Loop Over Each Sheet
        var result = {};
        file.SheetNames.forEach(function (sheetName) {
          // Obtain The Current Row As CSV
          var datas = XLSX.utils.sheet_to_json(file.Sheets[sheetName]);
          if (datas.length) result[sheetName] = datas;
          // $("#my_file_output").html(sCSV);
        });
        array.push(result);
      };
      reader.readAsArrayBuffer(this.uploadFile);
      this.fileData = array;
      // console.log(this.fileData);
    },

    //  등록하기 기능(엑셀파일의 여러 조건을 확인)
    async modifying() {
      if (this.radio === "") {
        alert("등록 형태(신규 or 변경)를 선택해 주세요.");
      } else if (this.fileData === "") {
        alert("파일을 등록해 주세요.");
      } else {
        if (this.fileData[0]["Sheet1"] == undefined) {
          alert(
            "내용이 입력되지 않은 파일입니다. 확인 후 등록하시기 바랍니다."
          );
        } else {
          for (var i = 0; i < this.fileData[0]["Sheet1"].length; i++) {
            var seed_num = this.fileData[0]["Sheet1"][i]["seed_num"];
            var serial_num = this.fileData[0]["Sheet1"][i]["serial_num"];
            var plug_id = this.fileData[0]["Sheet1"][i]["plug_id"];
            var region = this.fileData[0]["Sheet1"][i]["region"];

            plug_id = plug_id.toString();

            if (seed_num === undefined) {
              this.value_empty_fail.push({
                row_num: i + 1,
                reason: '"seed_num"셀이 비어 있습니다.',
              });
            } else if (serial_num === undefined) {
              this.value_empty_fail.push({
                row_num: i + 1,
                reason: '"serial_num"셀이 비어 있습니다.',
              });
            } else if (plug_id === undefined) {
              this.value_empty_fail.push({
                row_num: i + 1,
                reason: '"plug_id"셀이 비어 있습니다.',
              });
            } else if (region === undefined) {
              this.value_empty_fail.push({
                row_num: i + 1,
                reason: '"region"셀이 비어 있습니다.',
              });
            } else if (seed_num.length != 64) {
              this.value_number_fail.push({
                row_num: i + 1,
                plug_id: plug_id,
                reason: '"seed_num"셀의 입력 자리수가 맞지 않습니다.',
              });
            } else if (serial_num.length != 15) {
              this.value_number_fail.push({
                row_num: i + 1,
                plug_id: plug_id,
                reason: '"serial_num"셀의 입력 자리수가 맞지 않습니다.',
              });
            } else if (plug_id.length != 9) {
              this.value_number_fail.push({
                row_num: i + 1,
                plug_id: plug_id,
                reason: '"plug_id"셀의 입력 자리수가 맞지 않습니다.',
              });
            } else {
              continue;
            }
          }
          ////link where knowledge and code for checking duplicate values was taken
          //https://stackoverflow.com/questions/11035142/how-can-i-remove-rows-with-unique-values-keeping-rows-with-duplicate-values
          ///Check for the duplicate of serial num inside the excel
          var index = [];
          for (var a = 0; a < this.fileData[0]["Sheet1"].length; a++) {
            var duplicate = false;
            for (var b = 0; b < this.fileData[0]["Sheet1"].length; b++) {
              if (
                this.fileData[0]["Sheet1"][a]["serial_num"] ==
                  this.fileData[0]["Sheet1"][b]["serial_num"] &&
                a != b
              ) {
                duplicate = true;
              }
            }
            if (!duplicate) {
              index.push(a);
            }
          }
          if (index.length < this.fileData[0]["Sheet1"].length) {
            for (var a = 0; a < index.length; a++) {
              // console.log(this.fileData[0]["Sheet1"][index[a]]);
              this.fileData[0]["Sheet1"][index[a]] = null;
            }
            for (var i = 0; i < this.fileData[0]["Sheet1"].length; i++) {
              if (this.fileData[0]["Sheet1"][i] != null) {
                this.serial_duplicate.push({
                  row_num: i + 1,
                  plug_id: this.fileData[0]["Sheet1"][i]["plug_id"],
                  reason: '"serial_num" 셀의 중복 값이 있습니다',
                });
              }
            }
          } else {
            console.log(index);
          }
          if (this.serial_duplicate.length > 0) {
            this.result = false;
            this.fail_TableData = [];
            var temp = {};
            const iterator = this.serial_duplicate.values();
            for (var item of iterator) {
              temp = {
                num: item.row_num,
                plug_id: item.plug_id,
                reason: item.reason,
              };
              this.fail_TableData.push(temp);
            }
            return false;
          }

          ///End of checking for serial num

          ///Check for the duplicate of plug-id inside the excel
          var index = [];
          for (var a = 0; a < this.fileData[0]["Sheet1"].length; a++) {
            var duplicate = false;
            for (var b = 0; b < this.fileData[0]["Sheet1"].length; b++) {
              if (
                this.fileData[0]["Sheet1"][a]["plug_id"] ==
                  this.fileData[0]["Sheet1"][b]["plug_id"] &&
                a != b
              ) {
                duplicate = true;
              }
            }
            if (!duplicate) {
              index.push(a);
            }
          }
          if (index.length < this.fileData[0]["Sheet1"].length) {
            for (var a = 0; a < index.length; a++) {
              // console.log(this.fileData[0]["Sheet1"][index[a]]);
              this.fileData[0]["Sheet1"][index[a]] = null;
            }
            for (var i = 0; i < this.fileData[0]["Sheet1"].length; i++) {
              if (this.fileData[0]["Sheet1"][i] != null) {
                this.plug_duplicate.push({
                  row_num: i + 1,
                  plug_id: this.fileData[0]["Sheet1"][i]["plug_id"],
                  reason: '"plug_id" 셀의 중복 값이 있습니다',
                });
              }
            }
          } else {
            console.log(index);
          }
          if (this.plug_duplicate.length > 0) {
            this.result = false;
            this.fail_TableData = [];
            var temp = {};
            const iterator = this.plug_duplicate.values();
            for (var item of iterator) {
              temp = {
                num: item.row_num,
                plug_id: item.plug_id,
                reason: item.reason,
              };
              this.fail_TableData.push(temp);
            }
            return false;
          }
          ///End of checking of plug id
          if (this.value_empty_fail.length > 0) {
            this.result = false;
            // this.value_empty_fail = this.value_empty_fail.toString();
            this.fail_TableData = [];

            var temp = {};
            const iterator = this.value_empty_fail.values();
            for (var item of iterator) {
              temp = {
                num: item.row_num,
                reason: item.reason,
              };
              this.fail_TableData.push(temp);
            }
            return false;
          } else if (this.value_number_fail.length > 0) {
            this.result = false;
            this.fail_TableData = [];
            var temp = {};
            const iterator = this.value_number_fail.values();
            for (var item of iterator) {
              temp = {
                num: item.row_num,
                plug_id: item.plug_id,
                reason: item.reason,
              };
              this.fail_TableData.push(temp);
            }
            return false;
          } else {
            var data = {};
            data.id = this.radio;
            data.datas = this.fileData;

            const response = await this.$store.dispatch("CREATE_PLUG_ID", data);
            // console.log(response);
            this.time = response.data.data.time;
            this.result = response.data.result;
            var iterator = response.data.data.list;
            for (var item of iterator) {
              var temp = {
                num: item.row_num,
                plug_id: item.plug_id,
                reason: item.reason,
              };
              this.fail_TableData.push(temp);
            }
          }
        }
      }
    },
    itemValue(item, column) {
      return item[column.toLowerCase()];
    },

    // 등록플러그 검색
    async findUser(page) {
      this.pageNum = page ? page : 1;
      this.pages.page = this.pageNum;
      if (this.searchOptionList.id === undefined) {
        this.searchOptionList.id = "all";
      }
      var data = {};
      data.id = this.searchOptionList.id;
      data.search = this.searchInput;
      data.page = this.pages.page;
      data.lines = this.pages.pageSize;
      const response = await this.$store.dispatch("FIND_PLUG_REGISTER", data);
      // console.log(response);

      var list = response.data.data.find_list;
      this.totalNum = response.data.data.total;

      if (!list || list.length === 0) {
        // alert("검색된 데이터가 없습니다. 다시 검색하시기 바랍니다.");
        this.tableData = [];
        list = [];
      }
      this.searchData = [];
      for (var item of list) {
        var temp = {
          plug_id: item.plug_id,
          serial_num: item.serial_num,
          status: item.status,
        };
        this.searchData.push(temp);
      }
    },
  },
};
</script>
<style scoped>
.search_div {
  padding: 10px 30px 30px 20px;
}
.search_div .search_input_row {
  display: flex;
}
.search_btn {
  width: 300px;
  margin: auto;
  background-color: #3195cf;
  border: 1px solid #4388c1;
  color: white;
}
.countData {
  padding: 15px;
}

.detail_div {
  margin: 10px 25px;
}
.table th {
  background: #f2f2f2;
}

.checkbox_div .form-check {
  display: inline;
}

.btn_div button {
  margin: 10px 10px;
  width: 100px;
}
.inputbox {
  margin: -59px 20px 12% 200px;
  width: 100%;
  position: relative;
}
.seletbox {
  margin: 0 20px 0 0;
}
.pseach {
  white-space: nowrap;
}
.btn-fill {
  background-color: #3195cf;
  border: 1px solid #4388c1;
  color: white;
}
.rd_edit {
  margin-left: 40px;
}
</style>
