<template>
  <div class="content" v-if="this.change_access === 'y'">
    <div class="row">
      <div class="col-6">
        <card
          class="strpied-tabled-with-hover content-card"
          body-classes="table-full-width table-responsive"
        >
          <template slot="header">
            <h4 class="card-title">사이트</h4>
          </template>
          <div class="search_div">
            <div class="search_input_row">
              <p class="p1">검색 기준</p>
              <base-select-search
                type="text"
                class="text-center selectInput seletbox"
                :items="searchOptionList"
                v-model="searchOptionList.id"
              ></base-select-search>
              <base-input
                type="text"
                placeholder=""
                v-model="searchInput"
                @keypress="enterkey()"
                class="inputbox"
              />
              <button
                class="btn btn-fill btn-primary btn-block search_btn"
                type="button"
                @click="findSite()"
              >
                <a style="white-space: nowrap">검색</a>
              </button>
            </div>
          </div>

          <!-- <site-table class="table-hover table-striped"></site-table> -->
          <div class="search_result_div">
            <div v-if="searchData.length > 0">
              <div class="countData">총 {{ totalNum }} 개</div>
              <div class="div-table">
                <div class="div-row">
                  <div
                    class="div-cell"
                    v-for="column in tableColumns"
                    :key="column.id"
                  >
                    <span> {{ column.msg }} </span>
                  </div>
                </div>
                <!-- <div class="div-row" v-for="(item, index) in paginatedData" :key="index" @click="moveSiteDetail(item.siteId)"> -->
                <div
                  class="div-row"
                  v-for="(item, index) in searchData"
                  :key="index.id"
                  @click="findUserDetail(index)"
                  :class="{ clickedRow: index === clickRowIndex }"
                >
                  <div
                    class="div-cell"
                    v-for="column in tableColumns"
                    :key="column.id"
                  >
                    <div v-if="column.id == 'site_status'">
                      <span
                        v-if="itemValue(item, 'site_status') == 'ACTIVE'"
                        class="status_use"
                      >
                        사용</span
                      >
                      <span
                        v-else-if="itemValue(item, 'site_status') == 'NOTWORK'"
                        class="status_notuse"
                      >
                        사용안함</span
                      >
                    </div>
                    <div v-else>
                      {{ itemValue(item, column.id) }}
                    </div>
                  </div>
                </div>
              </div>
              <div class="paging-cover">
                <b-pagination
                  class="mt-3"
                  v-model="pageNum"
                  :total-rows="totalNum"
                  :per-page="pages.pageSize"
                  @page-click="pageClickFind"
                ></b-pagination>
              </div>
            </div>

            <div v-else-if="tableData.length > 0">
              <div class="countData">총 {{ totalNum }} 개</div>
              <div class="div-table">
                <div class="div-row">
                  <div
                    class="div-cell"
                    v-for="(column, index) in tableColumns"
                    :key="index"
                  >
                    <span>
                      {{ column.msg }}
                    </span>
                  </div>
                </div>
                <!-- <div class="div-row" v-for="(item, index) in paginatedData" :key="index" @click="moveSiteDetail(item.siteId)"> -->
                <div
                  class="div-row"
                  v-for="(item, index) in tableData"
                  :key="index.id"
                  @click="getDetail(index)"
                  :class="{ clickedRow: index === clickRowIndex }"
                >
                  <div
                    class="div-cell"
                    v-for="(column, index) in tableColumns"
                    :key="index"
                  >
                    <div v-if="column.id == 'site_status'">
                      <span
                        v-if="itemValue(item, 'site_status') == 'ACTIVE'"
                        class="status_use"
                      >
                        사용</span
                      >
                      <span
                        v-else-if="itemValue(item, 'site_status') == 'NOTWORK'"
                        class="status_notuse"
                      >
                        사용안함</span
                      >
                    </div>
                    <div v-else>
                      {{ itemValue(item, column.id) }}
                    </div>
                  </div>
                </div>
              </div>
              <div class="paging-cover">
                <b-pagination
                  class="mt-3"
                  v-model="pageNum"
                  :total-rows="totalNum"
                  :per-page="pages.pageSize"
                  @page-click="pageClick"
                ></b-pagination>
              </div>
            </div>
            <div v-else>
              <div class="emptyContent">표시할 데이터가 없습니다.</div>
            </div>
          </div>
        </card>
      </div>

      <div class="col-6">
        <card
          class="strpied-tabled-with-hover content-card"
          body-classes="table-full-width table-responsive"
        >
          <template slot="header">
            <h4 class="card-title">사이트 상세</h4>
          </template>
          <div class="detail_div" id="mydiv">
            <div v-if="clickRowIndex >= 0">
              <table class="table" id="second">
                <tr>
                  <th>사이트 등록일</th>
                  <td>{{ site.site_created }}</td>
                </tr>
                <tr>
                  <th>사이트 ID</th>
                  <td>{{ site.site_id }}</td>
                </tr>
                <tr>
                  <th>사이트명</th>
                  <td>{{ site.site_name }}</td>
                </tr>
                <tr>
                  <th>주소</th>
                  <td>
                    {{ site.site_address_0 }} <br />{{ site.site_address_1 }}
                  </td>
                </tr>
                <tr>
                  <th>건물 관리자 ID</th>
                  <td>{{ site.provider_email }}</td>
                </tr>
                <tr>
                  <th>건물 관리자명</th>
                  <td>{{ site.provider_name }}</td>
                </tr>

                <tr>
                  <th>전기 종류</th>
                  <td v-if="site.site_type === '1'">주택용</td>
                  <td v-else-if="site.site_type === '2'">산업용</td>
                  <td v-else-if="site.site_type === '3'">농사용</td>
                  <td v-else-if="site.site_type === '4'">교육용</td>
                  <td v-else-if="site.site_type === '5'">일반용</td>
                </tr>
                <tr>
                  <th>사이트 상태</th>
                  <td v-if="site.site_status === 'ACTIVE'">사용중</td>
                  <td v-else-if="site.site_status === 'NOTWORK'">사용안함</td>
                </tr>
                <tr>
                  <th>정기권 정보</th>
                  <td v-if="site.site_subscribe === 'approved'">
                    사용 ({{ site.site_subscribe_price | comma }}원)
                  </td>
                  <td v-else-if="site.site_subscribe === 'disapproved'">
                    사용안함
                  </td>
                </tr>
                <tr>
                  <th>섹션 수</th>
                  <td>{{ site.section_count }}개</td>
                </tr>
                <tr>
                  <th>플러그 개수</th>
                  <td>{{ site.plug_count }}개</td>
                </tr>
                <tr>
                  <th>인증 계좌 정보</th>
                  <td>
                    <table class="table inner_table">
                      <thead>
                        <tr>
                          <th colspan="3">은행정보</th>
                          <th>등록일</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td>{{ account_info.bank_name }}</td>
                          <td>{{ account_info.acc_holder }}</td>
                          <td>{{ account_info.acc_num }}</td>
                          <td>{{ account_info.acc_check }}</td>
                        </tr>
                      </tbody>
                    </table>
                  </td>
                </tr>
                <tr>
                  <th>정산 주기</th>
                  <td>
                    <div class="radio_div" id="seconddiv">
                      <div>
                        <input
                          type="date"
                          id="start"
                          min="2022-01-01"
                          class="firstSet"
                          v-model="site.date"
                          value=""
                          disabled
                        />
                      </div>

                      <div class="rd_paycycle_div1">
                        <input
                          type="radio"
                          name="rd_paycycle"
                          v-model="site.term"
                          value="4"
                        />1개월
                      </div>
                      <div class="rd_paycycle_div1">
                        <input
                          type="radio"
                          name="rd_paycycle"
                          v-model="site.term"
                          value="5"
                        />2개월
                      </div>
                      <div class="rd_paycycle_div1">
                        <input
                          type="radio"
                          name="rd_paycycle"
                          v-model="site.term"
                          value="2"
                        />월 2회
                      </div>
                      <div class="rd_paycycle_div1">
                        <input
                          type="radio"
                          name="rd_paycycle"
                          v-model="site.term"
                          value="3"
                        />월 3회
                      </div>
                      <div class="rd_paycycle_div1">
                        <input
                          type="radio"
                          name="rd_paycycle"
                          v-model="site.term"
                          value="1"
                        />월 4회
                      </div>
                      <button
                        v-if="this.permission === 'true'"
                        class="btn btn-default btn-sm btn-fill btn-primary btn-block paycycle_btn"
                        type="button"
                        @click="update_settle()"
                      >
                        저장
                      </button>
                    </div>
                  </td>
                </tr>
                <tr>
                  <th>변경 히스토리</th>
                  <td>
                    {{ this.site.change_time }} {{ this.site.change_email }}
                  </td>
                </tr>
              </table>
            </div>
            <div v-else>
              <div class="emptyContent">
                상세 보기 할 데이터를 클릭해 주세요.
              </div>
            </div>
          </div>
        </card>
      </div>
    </div>
  </div>
  <div v-else>
    <card>
      <h1>해당 계정은 [운영 관리] 페이지에 접근 권한이 없습니다.</h1>
    </card>
  </div>
</template>
<script>
import Card from "src/components/Cards/Card.vue";
import BaseInput from "src/components/Inputs/BaseInput.vue";

export default {
  // 숫자에 콤마 입력
  filters: {
    comma(val) {
      return String(val).replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    },
  },
  components: {
    Card,
    BaseInput,
  },
  data() {
    return {
      index: "",

      // 사이트 상세 데이터
      site: {
        site_created: "",
        site_id: "",
        site_name: "",
        site_address_0: "",
        site_address_1: "",
        provider_email: "",
        provider_name: "",
        site_type: "",
        site_status: "",
        site_subscribe_price: "",
        site_subscribe: "",
        section_count: "",
        plug_count: "",
        term: "",
        date: "",
        next_sett: "",
        admin_email: "",
        time: "",
        change_time: "",
        change_email: "",
      },

      // 인증 계좌 정보
      acc_info: {},

      // 인증 계좌 정보 테이블
      account_info: {
        bank_name: "",
        acc_holder: "",
        acc_num: "",
        acc_check: "",
        acc_status: "",
      },

      // 페이징 갯수
      pages: { page: 1, pageSize: 15 },

      // 페이징 넘버
      pageNum: 0,

      // 토탈 페이징 넘버
      totalNum: 0,

      // 검색 인풋 박스 데이터
      searchInput: "",

      // 사이트 검색 셀렉트박스
      searchOptionList: [
        // { id: "select", name: "선택" },
        { id: "all", name: "전체" },
        { id: "site_name", name: "사이트명" },
        { id: "address", name: "주소" },
        // { id: "prov_id", name: "관리자 ID" },
        { id: "prov_name", name: "관리자 이름" },
      ],

      // 사이트 테이블 메인 데이터 컬럼
      tableColumns: [
        { id: "site_name", msg: "사이트명" },
        { id: "site_address_0", msg: "사이트 주소" },
        { id: "provider_name", msg: "건물 관리자 이름" },
        { id: "site_status", msg: "사이트 상태" },
        { id: "plug_count", msg: "플러그 개수" },
      ],

      // 어드민 계정 데이터
      change_access: "",
      my_id: "",

      // 메인 테이블 데이터
      tableData: [],

      // 검색 테이블 데이터
      searchData: [],

      clickRowIndex: -1,
      permission: "",
    };
  },
  async created() {
    // 페이지 접속시 사이트 리스트 출력
    this.getManagerList();

    // 세션에서 로그인 여부 확인하고 권한부여
    if (sessionStorage.getItem("login") !== null) {
      this.change_access =
        this.$store.state.get_admin_detail.admin_user_profile.access.slice(
          2,
          3
        );
      this.my_id =
        this.$store.state.get_admin_detail.admin_user_profile.admin_id;
      this.site.admin_email =
        this.$store.state.get_admin_detail.admin_user_profile.admin_email;
      this.permission =
        this.$store.state.get_admin_detail.admin_user_profile.permission;
    }
  },
  computed: {
    pageCount() {
      //전체 페이지 수 count
      let listLeng = this.totalNum,
        listSize = this.pages.pageSize,
        page = Math.floor(listLeng / listSize);

      if (listLeng % listSize > 0) page += 1;
      return page;
    },
    pageFindCount() {
      //전체 페이지 수 count
      let listLeng = this.totalNum,
        listSize = this.pages.pageSize,
        page = Math.floor(listLeng / listSize);

      if (listLeng % listSize > 0) page += 1;
      return page;
    },
  },
  methods: {
    // 엔터키 눌럿을 경우 처리되도록 하는 함수
    enterkey() {
      if (window.event.keyCode == 13) {
        this.findSite();
      }
    },

    /* 페이징 클릭 시 */
    pageClick(button, page) {
      this.getManagerList(page);
    },
    pageClickFind(button, page) {
      this.findSite(page);
    },

    // 사이트 검색 기능
    async findSite(page) {
      this.pageNum = page ? page : 1;
      this.pages.page = this.pageNum;

      if (this.searchOptionList.id === undefined) {
        this.searchOptionList.id = "all";
      }
      // alert("검색 기준을 선택해주세요");
      // } else {
      var data = {};
      data.id = this.searchOptionList.id;
      data.search = this.searchInput;
      data.page = this.pages.page;
      data.lines = this.pages.pageSize;

      const response = await this.$store.dispatch("FIND_SITE", data);
      console.log(response);
      var list = response.data.data.find_list;
      this.totalNum = response.data.data.total;
      if (!list || list.length === 0) {
        // alert("검색된 데이터가 없습니다. 다시 검색하시기 바랍니다.");
        this.tableData = [];
        list = [];
      }
      this.searchData = [];
      for (var item of list) {
        var temp = {
          provider_id: item.provider_id,
          site_created: item.site_created,
          site_id: item.site_id,
          site_name: item.site_name,
          site_address_0: item.site_address_0,
          site_address_1: item.site_address_1,
          site_type: item.site_type,
          site_status: item.site_status,
          site_subscribe_price: item.site_subscribe_price,
          site_subscribe: item.site_subscribe,
          provider_email: item.provider_email,
          provider_name: item.provider_name,
          plug_count: item.plug_count,
          term: item.term,
          date: item.date,
          change_email: item.change_email,
          change_time: item.change_time,
          section_count: item.section_count,
          acc_info: item.acc_info,
          bank_name: item.bank_name,
          acc_holder: item.acc_holder,
          acc_num: item.acc_num,
          acc_check: item.acc_check,
          acc_status: item.acc_status,
        };
        this.searchData.push(temp);
      }
      this.clickRowIndex = -1;
    },

    // 정산주기 저장 기능
    async update_settle() {
      if (this.site.plug_count == 0) {
        alert(
          "해당하는 사이트에 아직 등럭된 플러그가 없어서 정산 주기를 바꿀 수 없습니다."
        );
      } else {
        var data = {};
        data.id = this.site.site_id;
        data.term = this.site.term;
        data.date = this.site.next_sett;
        data.admin_email = this.site.admin_email;
        console.log(data);
        const response = await this.$store.dispatch(
          "FETCH_UPDATE_SETTLE",
          data
        );

        // 사이트 상세 리스트
        if (response.data.result === true) {
          this.tableData[this.index].term = data.term;
          this.tableData[this.index].date = data.date;
          alert("저장 완료.");
          const response = await this.$store.dispatch(
            "FETCH_GET_SITELIST",
            this.pages
          );
          var list = response.data.data.site_list;
          console.log("list", list);
          this.totalNum = response.data.data.total;
          this.tableData = [];
          for (var item of list) {
            // for (var items of item.acc_info) {
            //   if (items.acc_status === "Y") {
            //     item.acc_info = items;
            //   }
            // }
            var temp = {
              provider_id: item.provider_id,
              site_created: item.site_created,
              site_id: item.site_id,
              site_name: item.site_name,
              site_address_0: item.site_address_0,
              site_address_1: item.site_address_1,
              site_type: item.site_type,
              site_status: item.site_status,
              site_subscribe_price: item.site_subscribe_price,
              site_subscribe: item.site_subscribe,
              provider_email: item.provider_email,
              provider_name: item.provider_name,
              plug_count: item.plug_count,
              term: item.term,
              date: item.date,
              next_sett: item.next_sett,
              change_email: item.change_email,
              change_time: item.change_time,
              section_count: item.section_count,
              acc_info: item.acc_info,
              bank_name: item.bank_name,
              acc_holder: item.acc_holder,
              acc_num: item.acc_num,
              acc_check: item.acc_check,
              acc_status: item.acc_status,
            };
            this.tableData.push(temp);
          }
          this.clickRowIndex = -1;
          this.$forceUpdate(this.getDetail(this.index));
        } else {
          alert("문제가 있어요, 개발팀에 문의하세요");
        }
        console.log("update_settle_result", response);
      }
    },
    itemValue(item, column) {
      return item[column.toLowerCase()];
    },

    //사이트 상세
    async getDetail(index) {
      this.clickRowIndex = index;
      this.index = index;
      this.site.site_created = this.tableData[index].site_created;
      this.site.site_id = this.tableData[index].site_id;
      this.site.site_name = this.tableData[index].site_name;
      this.site.site_address_0 = this.tableData[index].site_address_0;
      this.site.site_address_1 = this.tableData[index].site_address_1;
      this.site.site_status = this.tableData[index].site_status;
      this.site.provider_email = this.tableData[index].provider_email;
      this.site.provider_name = this.tableData[index].provider_name;
      this.site.site_type = this.tableData[index].site_type;
      this.site.site_subscribe_price =
        this.tableData[index].site_subscribe_price;
      this.site.site_subscribe = this.tableData[index].site_subscribe;
      this.site.section_count = this.tableData[index].section_count;
      this.site.plug_count = this.tableData[index].plug_count;
      this.site.term = this.tableData[index].term;
      this.site.date = this.tableData[index].date;
      this.site.next_sett = this.tableData[index].next_sett;
      this.site.change_email = this.tableData[index].change_email;
      this.site.change_time = this.tableData[index].change_time;
      this.account_info.bank_name = this.tableData[index].bank_name;
      this.account_info.acc_holder = this.tableData[index].acc_holder;
      this.account_info.acc_num = this.tableData[index].acc_num;
      this.account_info.acc_check = this.tableData[index].acc_check;
      this.acc_info = this.tableData[index].acc_info;
      this.account_info.acc_status = this.tableData[index].acc_status;

      console.log("abc", this.tableData[index]);
      //this.site.term = this.tableData[index].term;
    },

    // 검색 데이터 디테일 정보
    async findUserDetail(index) {
      this.clickRowIndex = index;
      this.index = index;
      this.site.site_created = this.searchData[index].site_created;
      this.site.site_id = this.searchData[index].site_id;
      this.site.site_name = this.searchData[index].site_name;
      this.site.site_address_0 = this.searchData[index].site_address_0;
      this.site.site_address_1 = this.searchData[index].site_address_1;
      this.site.site_status = this.searchData[index].site_status;
      this.site.provider_email = this.searchData[index].provider_email;
      this.site.provider_name = this.searchData[index].provider_name;
      this.site.site_type = this.searchData[index].site_type;
      this.site.site_subscribe_price =
        this.searchData[index].site_subscribe_price;
      this.site.site_subscribe = this.searchData[index].site_subscribe;
      this.site.section_count = this.searchData[index].section_count;
      this.site.plug_count = this.searchData[index].plug_count;
      this.site.term = this.searchData[index].term;
      this.site.date = this.searchData[index].date;
      this.site.change_email = this.searchData[index].change_email;
      this.site.change_time = this.searchData[index].change_time;
      this.account_info.bank_name = this.searchData[index].bank_name;
      this.account_info.acc_holder = this.searchData[index].acc_holder;
      this.account_info.acc_num = this.searchData[index].acc_num;
      this.account_info.acc_check = this.searchData[index].acc_check;
      this.acc_info = this.searchData[index].acc_info;
      this.account_info.acc_status = this.searchData[index].acc_status;
    },

    // 사이트 리스트 테이블
    async getManagerList(page) {
      this.pageNum = page ? page : 1;
      this.pages.page = this.pageNum;

      const response = await this.$store.dispatch(
        "FETCH_GET_SITELIST",
        this.pages
      );
      console.log(response.data);

      var list = response.data.data.site_list;
      this.totalNum = response.data.data.total;
      this.tableData = [];
      for (var item of list) {
        for (var items of item.acc_info) {
          if (items.acc_status === "Y") {
            item.acc_info = items;
          }
        }
        // console.log("qqq", item.acc_info);
        var temp = {
          provider_id: item.provider_id,
          site_created: item.site_created,
          site_id: item.site_id,
          site_name: item.site_name,
          site_address_0: item.site_address_0,
          site_address_1: item.site_address_1,
          site_type: item.site_type,
          site_status: item.site_status,
          site_subscribe_price: item.site_subscribe_price,
          site_subscribe: item.site_subscribe,
          provider_email: item.provider_email,
          provider_name: item.provider_name,
          plug_count: item.plug_count,
          term: item.term,
          date: item.date,
          next_sett: item.next_sett,
          change_email: item.change_email,
          change_time: item.change_time,
          section_count: item.section_count,
          acc_info: item.acc_info,
          bank_name: item.bank_name,
          acc_holder: item.acc_holder,
          acc_num: item.acc_num,
          acc_check: item.acc_check,
          acc_status: item.acc_status,
        };
        this.tableData.push(temp);
      }
      this.clickRowIndex = -1;
    },
  },
};
</script>
<style scoped>
.firstSet {
  background-color: #f2f2f2;
}
.search_div {
  padding: 10px 30px 30px 20px;
}
.search_div .search_input_row {
  display: flex;
  margin: 20px 0 0 0;
}
.search_div .search_btn {
  width: 70px;
  font-weight: 400;
  background-color: #3195cf;
  border: 1px solid #4388c1;
  color: white;
  height: 35px;
  font-size: 13px;
}
.countData {
  padding: 15px;
}

.detail_div {
  margin: 10px 25px;
}
.table th {
  background: #f2f2f2;
}
.checkbox_div .form-check {
  display: inline-block;
}

.btn_div button {
  margin: 10px 10px;
  width: 100px;
}
.uselog_btn {
  color: black;
}

.radio_div .form-check {
  display: inline-block;
}

.rd_paycycle_div1 {
  justify-content: space-between;
  align-items: center;
  float: left;
  padding: 5px 0 0 20px;
}

.rd_paycycle_div2 {
  justify-content: space-between;
  align-items: center;
}

.paycycle_btn {
  width: 100px;
  height: 30px;
  float: right;
}
.p1 {
  margin: 5px 0 0 0;
  white-space: nowrap;
}
.seletbox {
  margin: 0 20px 0 0;
}
.inputbox {
  margin: 0 20px 2% 0;
  width: 50%;
  position: relative;
}
</style>
