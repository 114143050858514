//vue-store.js
import { _ } from "core-js";
import Vue from "vue";
import Vuex from "vuex";
import createPersistedState from "vuex-persistedstate";
import {
  login_admin,
  get_admin_detail,
  create_admin,
  find_provider,
  get_plug_record_list,
  get_plug_list,
  get_site_list,
  get_plug_record_detail,
  comp_password,
  update_admin,
  get_settlement_list,
  get_admin_list,
  get_user_list,
  get_manager_list,
  get_sitelist,
  update_settle,
  popup_onoff,
  get_using_list,
  get_dashboard,
  create_billing,
  get_bill_record,
  get_admin_approval,
  create_comment,
  create_notice,
  get_admin_plug_list,
  update_password,
  find_admin,
  find_site,
  find_plug,
  find_ticket,
  find_user,
  find_user_popup,
  find_manager,
  find_using,
  find_billing_result,
  find_paid_result,
  find_paid_pro,
  find_plug_register,
  get_acc_history_list,
  get_popup_plug_list,
  delete_admin,
  create_plug_id,
  get_admin_ticket_list,
  get_paid_plug_list,
  make_otp,
  plug_db_set,
  create_refund_point,
  create_promotion_point,
  create_site_price,
  get_site_price,
} from "src/Api/index.js";

Vue.use(Vuex);

const store = new Vuex.Store({
  plugins: [createPersistedState()],
  state: {
    // 회원가입 성공 실패 여부
    isLogin: false,
    // 로그인 여부
  },
  mutations: {
    logout(state) {
      sessionStorage.clear();
      localStorage.clear();
      window.location.href = "/login";
      state.get_admin_detail = "";
      state.isLogin = false;
    },
    IS_LOGIN(state, LOGIN_ADMIN) {
      state.isLogin = LOGIN_ADMIN.data.result;
      state.isLogin
        ? console.log("로그인 성공")
        : console.log(state.isLogin, "로그인 실패");
    },
    ADMIN_PROFILE(state, profile) {
      state.get_admin_detail = profile;
    },
  },
  getters: {
    isLoggedIn: (state) => !!state.token,
    authStatus: (state) => state.status,
    getLoginUser: (state) => state.user,
  },
  actions: {
    async FETCH_POPUP_ONOFF({ commit }, site) {
      const response = await popup_onoff(site.site_id, site.ONOFF);
      commit;
      console.log("response:", response);
      return response;
    },
    async FETCH_UPDATE_SETTLE({ commit }, site) {
      const response = await update_settle(
        site.id,
        site.term,
        site.date,
        site.admin_email
      );
      commit;
      console.log("response:", response);
      return response;
    },
    async FETCH_CREATE_COMMENT({ commit }, plug) {
      const response = await create_comment(
        plug.id,
        plug.bill_comment,
        plug.admin_email,
        plug.bill_status
      );
      commit;
      console.log("response:", response);
      return response;
    },
    async FETCH_CREATE_NOTICE({ commit }, plug) {
      const response = await create_notice(plug.id, plug.notice);
      commit;
      console.log("response:", response);
      return response;
    },
    async FETCH_GET_SITELIST({ commit }, pages) {
      const response = await get_sitelist(pages);
      commit;
      console.log("response:", response);
      return response;
    },
    async FETCH_SETTLEMENT_LIST({ commit }) {
      const response = await get_settlement_list();
      commit;
      return response;
    },

    async LOGIN_ADMIN({ commit }, admin) {
      console.log("action login", admin);
      const response = await login_admin(admin);
      console.log("action response: ", response);
      commit("IS_LOGIN", response);
      return response;
    },
    async GET_ADMIN_USER_DETAIL({ commit }) {
      const response = await get_admin_detail();
      commit("ADMIN_PROFILE", response.data.data);
      return response;
    },
    async FETCH_CHECK_PROVIDER_EMAIL({ commit }, email) {
      console.log("actions email: ", email);
      commit;
      const response = await check_provider_email(email);
      console.log("actions response: ", response);
      return response;
    },
    async FETCH_CREATE_ADMIN({ commit }, user) {
      console.log("action memberInf: ", user);
      const response = await create_admin(user);
      commit("SIGNUP", response);
      console.log("action response: ", response);
      return response;
    },

    async FETCH_FIND_PROVIDER({ commit }, check) {
      const response = await find_provider(check);
      commit;
      console.log("response:", response);
      return response;
    },
    async FETCH_GET_PLUG_RECORD_LIST({ commit }, pages) {
      const response = await get_plug_record_list(pages);
      commit;
      console.log("response: ", response);
      return response;
    },
    async FETCH_GET_PLUG_RECORD_DETAIL({ commit }, pages) {
      const response = await get_plug_record_detail(pages);
      commit;
      return response;
    },
    async FETCH_GET_PLUG_LIST({ commit }, pages) {
      const response = await get_plug_list(pages);
      commit;
      console.log("response:", response);
      return response;
    },

    async FETCH_GET_ADMIN_LIST({ commit }, pages) {
      const response = await get_admin_list(pages);
      commit;
      console.log("response:", response);
      return response;
    },

    async FETCH_GET_USER_LIST({ commit }, pages) {
      const response = await get_user_list(pages);
      commit;
      console.log("response:", response);
      return response;
    },

    async FETCH_GET_MANAGER_LIST({ commit }, pages) {
      const response = await get_manager_list(pages);
      commit;
      console.log("response:", response);
      return response;
    },
    async FETCH_GET_USING_LIST({ commit }, pages) {
      const response = await get_using_list(pages);
      commit;
      console.log("response:", response);
      return response;
    },
    async FETCH_GET_BILL_RECORD({ commit }, pages) {
      const response = await get_bill_record(pages);
      commit;
      return response;
    },
    async FETCH_CREATE_BILLING({ commit }, data) {
      const response = await create_billing(data);
      commit;
      return response;
    },

    async FETCH_GET_PLUG_SITE_LIST({ commit }, pages) {
      const response = await get_plug_site_list(pages);
      commit;
      console.log("response:", response);
      return response;
    },
    async FETCH_GET_SITE_LIST({ commit }, pages) {
      const response = await get_site_list(pages);
      commit;
      return response;
    },

    async FETCH_GET_PLUG_DETAIL({ commit }, deviceId) {
      const response = await get_plug_detail(deviceId);
      commit;
      return response;
    },
    async FETCH_GET_SECTION_LIST({ commit }, section) {
      const response = await get_section_list(section);
      commit;
      return response;
    },

    async FETCH_GET_SITE_DETAIL({ commit }, siteId) {
      const response = await get_site_detail(siteId);
      commit;
      return response;
    },

    async COMPARE_PASSWORD({ commit }, password) {
      const response = await comp_password(password);
      commit;
      return response;
    },
    async UPDATE_ADMIN_USER({ commit }, memberInf) {
      commit;
      const response = await update_admin(memberInf);
      return response;
    },
    async DELETE_ADMIN_USER({ commit }, user_id) {
      commit;
      const response = await delete_admin(user_id);
      return response;
    },
    async UPDATE_PASSWORD({ commit }, data) {
      commit;
      const response = await update_password(data);
      return response;
    },
    async FETCH_GET_DASHBOARD({ commit }) {
      const response = await get_dashboard();
      commit;
      return response;
    },
    async FETCH_GET_ADMIN_APPROVAL({ commit }, app_id) {
      const response = await get_admin_approval(app_id);
      commit;
      return response;
    },
    async FETCH_GET_ADMIN_PLUG_LIST({ commit }, pages) {
      const response = await get_admin_plug_list(pages);
      commit;
      return response;
    },
    async FETCH_GET_ADMIN_TICKET_LIST({ commit }, pages) {
      const response = await get_admin_ticket_list(pages);
      commit;
      return response;
    },
    async FIND_ADMIN({ commit }, data) {
      const response = await find_admin(data);
      commit;
      return response;
    },
    async FIND_SITE({ commit }, data) {
      const response = await find_site(data);
      commit;
      return response;
    },
    async FIND_PLUG({ commit }, data) {
      const response = await find_plug(data);
      commit;
      return response;
    },
    async FIND_TICKET({ commit }, data) {
      const response = await find_ticket(data);
      commit;
      return response;
    },
    async FIND_USER({ commit }, data) {
      const response = await find_user(data);
      commit;
      return response;
    },
    async FIND_MANAGER({ commit }, data) {
      const response = await find_manager(data);
      commit;
      return response;
    },
    async FIND_USING({ commit }, data) {
      const response = await find_using(data);
      commit;
      return response;
    },
    async FIND_BILLING_RESULT({ commit }, data) {
      const response = await find_billing_result(data);
      commit;
      return response;
    },
    async FIND_PAID_RESULT({ commit }, data) {
      const response = await find_paid_result(data);
      commit;
      return response;
    },
    async FIND_PAID_PRO({ commit }, data) {
      const response = await find_paid_pro(data);
      commit;
      return response;
    },
    async FIND_PLUG_REGISTER({ commit }, data) {
      const response = await find_plug_register(data);
      commit;
      return response;
    },
    async GET_ACC_HISTORY({ commit }, site_id) {
      const response = await get_acc_history_list(site_id);
      commit;
      return response;
    },
    async FETCH_GET_POPUP_PLUG_LIST({ commit }, pages) {
      const response = await get_popup_plug_list(pages);
      commit;
      return response;
    },
    async CREATE_PLUG_ID({ commit }, data) {
      const response = await create_plug_id(data);
      commit;
      return response;
    },
    async FIND_USER_POPUP({ commit }, data) {
      const response = await find_user_popup(data);
      commit;
      return response;
    },
    async GET_PAID_PLUG_LIST({ commit }, data) {
      const response = await get_paid_plug_list(data);
      commit;
      return response;
    },
    async MAKE_OTP({ commit }, data) {
      const response = await make_otp(data);
      commit;
      return response;
    },
    async PLUG_DB_SET({ commit }, data) {
      const response = await plug_db_set(data);
      commit;
      return response;
    },
    async CREATE_REFUND_POINT({ commit }, data) {
      const response = await create_refund_point(data);
      commit;
      return response;
    },
    async CREATE_PROMOTION_POINT({ commit }, data) {
      const response = await create_promotion_point(data);
      commit;
      return response;
    },
    async CREATE_SITE_PRICE({ commit }, data) {
      const response = await create_site_price(data);
      commit;
      return response;
    },
    async GET_SITE_PRICE({ commit }) {
      const response = await get_site_price();
      commit;
      return response;
    },
  },
});

export default store;
