import axios from "axios";
import accessToken from "../accessToken";

var access = accessToken.host;

// 로컬과 서버에 올리는 권한 여부 조건
if (access === true) {
  var webURL = "http://localhost:80/raincircle_php/api.php";
} else {
  var webURL = "https://admin.shareplug.co.kr/_web_api/api.php";
}

// 사이트리스트 정산주기 변경
function update_settle(id, term, date, admin_email) {
  const params = new URLSearchParams();
  params.append("request", "update_settle");
  params.append("site_id", id);
  params.append("term", term);
  params.append("date", date);
  params.append("admin_email", admin_email);

  return axios({
    method: "post",
    url: webURL,
    data: params,
    withCredentials: access,
  });
}

// m.shareplug 사이트별 팝업 onoff 체크
function popup_onoff(site_id, ONOFF) {
  const params = new URLSearchParams();
  params.append("request", "popup_onoff");
  params.append("site_id", site_id);
  params.append("ONOFF", ONOFF);

  return axios({
    method: "post",
    url: webURL,
    data: params,
    withCredentials: access,
  });
}

// useList 정산미반영 코멘트 저장
function create_comment(id, bill_comment, admin_email, bill_status) {
  const params = new URLSearchParams();
  params.append("request", "create_comment");
  params.append("plug_record_id", id);
  params.append("bill_comment", bill_comment);
  params.append("admin_email", admin_email);
  params.append("bill_status", bill_status);

  return axios({
    method: "post",
    url: webURL,
    data: params,
    withCredentials: access,
  });
}

// useList 사용자 내역메모 저장
function create_notice(id, notice) {
  const params = new URLSearchParams();
  params.append("request", "create_notice");
  params.append("plug_record_id", id);
  params.append("notice", notice);

  return axios({
    method: "post",
    url: webURL,
    data: params,
    withCredentials: access,
  });
}

// siteList 리스트 생성 함수
function get_sitelist(pages) {
  const params = new URLSearchParams();
  params.append("request", "get_sitelist");
  params.append("page", pages.page);
  params.append("lines", pages.pageSize);

  return axios({
    method: "post",
    url: webURL,
    data: params,
    withCredentials: access,
  });
}

// paidpro 정산처리해야할 리스트
function get_settlement_list() {
  const params = new URLSearchParams();
  params.append("request", "get_settlement_list");

  return axios({
    method: "post",
    url: webURL,
    data: params,
    withCredentials: access,
  });
}

// usingTable 플러그 레코드 리스트
function get_plug_record_list(pages) {
  const params = new URLSearchParams();
  params.append("request", "get_plug_record_list");
  params.append("pages", pages);
  params.append("page", pages.page);
  params.append("lines", pages.pageSize);

  return axios({
    method: "post",
    url: webURL,
    data: params,
    withCredentials: access,
  });
}

// deviceDetailTable 플러그 레코드 디테일
function get_plug_record_detail(pages) {
  const params = new URLSearchParams();
  params.append("request", "get_plug_record_detail");
  params.append("pages", pages);
  params.append("page", pages.page);
  params.append("lines", pages.pageSize);
  params.append("plug_id", pages.plug_id);

  return axios({
    method: "post",
    url: webURL,
    data: params,
    withCredentials: access,
  });
}

// 플러그 리스트 생성
function get_plug_list(pages) {
  const params = new URLSearchParams();
  params.append("request", "get_plug_list");
  params.append("pages", pages);
  params.append("page", pages.page);
  params.append("lines", pages.pageSize);
  params.append("status", pages.status);

  return axios({
    method: "post",
    url: webURL,
    data: params,
    withCredentials: access,
  });
}

// adminList 어드민 계정 리스트
function get_admin_list(pages) {
  const params = new URLSearchParams();
  params.append("request", "get_admin_user_list");
  params.append("page", pages.page);
  params.append("lines", pages.pageSize);

  return axios({
    method: "post",
    url: webURL,
    data: params,
    withCredentials: access,
  });
}

// userList 충전사용자 리스트
function get_user_list(pages) {
  const params = new URLSearchParams();
  params.append("request", "get_user_list");
  params.append("page", pages.page);
  params.append("lines", pages.pageSize);

  return axios({
    method: "post",
    url: webURL,
    data: params,
    withCredentials: access,
  });
}

// managerList 건물관리자 리스트
function get_manager_list(pages) {
  const params = new URLSearchParams();
  params.append("request", "get_manager_list");
  params.append("page", pages.page);
  params.append("lines", pages.pageSize);

  return axios({
    method: "post",
    url: webURL,
    data: params,
    withCredentials: access,
  });
}

// usingList 충전사용자 리스트
function get_using_list(pages) {
  const params = new URLSearchParams();
  params.append("request", "get_using_list");
  params.append("page", pages.page);
  params.append("lines", pages.pageSize);

  return axios({
    method: "post",
    url: webURL,
    data: params,
    withCredentials: access,
  });
}

// paidResult 정산결과 리스트
function get_bill_record(pages) {
  const params = new URLSearchParams();
  params.append("request", "get_bill_record");
  params.append("page", pages.page);
  params.append("lines", pages.pageSize);

  return axios({
    method: "post",
    url: webURL,
    data: params,
    withCredentials: access,
  });
}

// deviceTable, siteTable, usingTable 사이트 리스트
function get_site_list(pages) {
  const params = new URLSearchParams();
  params.append("request", "get_site_list");
  params.append("pages", pages);
  params.append("page", pages.page);
  params.append("lines", pages.pageSize);

  return axios({
    method: "post",
    url: webURL,
    data: params,
    withCredentials: access,
  });
}

// Login 어드민 계정 로그인 기능
function login_admin(admin) {
  const params = new URLSearchParams();
  params.append("request", "admin_login");
  params.append("email", admin.email);
  params.append("password", admin.password);
  return axios({
    method: "post",
    url: webURL,
    data: params,
    withCredentials: access,
  });
}

// 어드민 계정의 정보로 로그인 체크 확인
function get_admin_detail() {
  const params = new URLSearchParams();
  params.append("request", "get_admin_user_detail");

  return axios({
    method: "post",
    url: webURL,
    data: params,
    withCredentials: access,
  });
}

// resultAdmin 어드민 계정 생성
function create_admin(user) {
  const params = new URLSearchParams();
  params.append("request", "create_admin_user");
  params.append("email", `${user.email}`);
  params.append("password", `${user.password}`);
  params.append("name", `${user.name}`);
  params.append("mob_number", `${user.mob_number}`);
  params.append("team", `${user.team}`);
  params.append("permission", `${user.permission}`);
  params.append("access", `${user.value}`);

  return axios({
    method: "post",
    url: webURL,
    data: params,
    withCredentials: access,
  });
}

//  console에서 사용하는 함수인듯
function find_provider(check) {
  const params = new URLSearchParams();
  params.append("request", "find_provider");
  params.append("check", check);
  params.append("name", check.name);
  params.append("mob_number", check.phone);

  return axios({
    method: "post",
    url: webURL,
    data: params,
    withCredentials: access,
  });
}

// adminList 어드민계정 수정
function update_admin(memberInf) {
  const params = new URLSearchParams();
  params.append("request", "update_admin_user");
  params.append("user_id", `${memberInf.id}`);
  params.append("name", `${memberInf.name}`);
  params.append("mob_number", `${memberInf.mob_number}`);
  params.append("team", `${memberInf.team}`);
  params.append("permission", `${memberInf.permission}`);
  params.append("access", `${memberInf.access}`);

  return axios({
    method: "post",
    url: webURL,
    data: params,
    withCredentials: access,
  });
}

// adminList admin 계정 디테일 정보 출력
function delete_admin(user_id) {
  const params = new URLSearchParams();
  params.append("request", "delete_admin_user");
  params.append("user_id", user_id);

  return axios({
    method: "post",
    url: webURL,
    data: params,
    withCredentials: access,
  });
}

// adminList admin 계정 비밀ㅂ너호 수정
function update_password(data) {
  const params = new URLSearchParams();
  params.append("request", "update_password");
  params.append("data", data);
  params.append("user_id", data.id);
  params.append("password", data.pass);

  return axios({
    method: "post",
    url: webURL,
    data: params,
    withCredentials: access,
  });
}

function comp_password(password) {
  const params = new URLSearchParams();
  params.append("request", "comp_password");
  params.append("password", password);

  return axios({
    method: "post",
    url: webURL,
    data: params,
    withCredentials: access,
  });
}

// overview 대쉬보드 사용 현황 정보 출력
function get_dashboard() {
  const params = new URLSearchParams();
  params.append("request", "get_dashboard");

  return axios({
    method: "post",
    url: webURL,
    data: params,
    withCredentials: access,
  });
}

// paidPro 정산처리 기능 함수
function create_billing(data) {
  const params = new URLSearchParams();
  params.append("request", "create_billing");
  params.append("data", JSON.stringify(data));

  return axios({
    method: "post",
    url: webURL,
    data: params,
    withCredentials: access,
  });
}

//  managerList 건물관리자 요청온 계좌 승인 기능
function get_admin_approval(app_id) {
  const params = new URLSearchParams();
  params.append("request", "get_admin_approval");
  params.append("account_id", app_id.account_id);
  params.append("site_id", app_id.site_id);

  return axios({
    method: "post",
    url: webURL,
    data: params,
    withCredentials: access,
  });
}

// plugList 풀러그 리스트 출력
function get_admin_plug_list(pages) {
  const params = new URLSearchParams();
  params.append("request", "get_admin_plug_list");
  params.append("page", pages.page);
  params.append("lines", pages.pageSize);

  return axios({
    method: "post",
    url: webURL,
    data: params,
    withCredentials: access,
  });
}

// TicketList 정기권 구매내역 페이지 리스트 출력
function get_admin_ticket_list(pages) {
  const params = new URLSearchParams();
  params.append("request", "get_admin_ticket_list");
  params.append("page", pages.page);
  params.append("lines", pages.pageSize);

  return axios({
    method: "post",
    url: webURL,
    data: params,
    withCredentials: access,
  });
}

// adminList admin 계정 검색
function find_admin(data) {
  const params = new URLSearchParams();
  params.append("request", "find_admin_user");
  // params.append("page", pages.page);
  // params.append("lines", pages.pageSize);
  params.append("searchId", data.id);
  params.append("searchValue", data.search);
  params.append("page", data.page);
  params.append("lines", data.lines);

  return axios({
    method: "post",
    url: webURL,
    data: params,
    withCredentials: access,
  });
}

// siteList 사이트 검색
function find_site(data) {
  const params = new URLSearchParams();
  params.append("request", "find_site");
  params.append("searchId", data.id);
  params.append("searchValue", data.search);
  params.append("page", data.page);
  params.append("lines", data.lines);

  return axios({
    method: "post",
    url: webURL,
    data: params,
    withCredentials: access,
  });
}

// plugList 플러그 검색
function find_plug(data) {
  const params = new URLSearchParams();
  params.append("request", "find_plug");
  params.append("searchId", data.id);
  params.append("searchValue", data.search);
  params.append("page", data.page);
  params.append("lines", data.lines);

  return axios({
    method: "post",
    url: webURL,
    data: params,
    withCredentials: access,
  });
}

// ticketList 정기권구매내역 내역검색
function find_ticket(data) {
  const params = new URLSearchParams();
  params.append("request", "find_ticket");
  params.append("searchId", data.id);
  params.append("searchValue", data.search);
  params.append("dateFrom", data.dateFrom);
  params.append("dateTo", data.dateTo);
  params.append("page", data.page);
  params.append("lines", data.lines);

  return axios({
    method: "post",
    url: webURL,
    data: params,
    withCredentials: access,
  });
}

// userList 유저 검색
function find_user(data) {
  const params = new URLSearchParams();
  params.append("request", "find_user");
  params.append("searchId", data.id);
  params.append("searchValue", data.search);
  params.append("page", data.page);
  params.append("lines", data.lines);

  return axios({
    method: "post",
    url: webURL,
    data: params,
    withCredentials: access,
  });
}

// userList 전체사용 내역-> 내역보기 클릭시 발생하는 팝업에서 검색기능
function find_user_popup(data) {
  const params = new URLSearchParams();
  params.append("request", "find_user_popup");
  params.append("searchId", data.id);
  params.append("user_id", data.user_id);
  params.append("dateFrom", data.dateFrom);
  params.append("dateTo", data.dateTo);
  params.append("selDate", data.selDate);
  params.append("page", data.page);
  params.append("lines", data.lines);

  return axios({
    method: "post",
    url: webURL,
    data: params,
    withCredentials: access,
  });
}

// managerList 관리자 검색
function find_manager(data) {
  const params = new URLSearchParams();
  params.append("request", "find_manager");
  params.append("searchId", data.id);
  params.append("searchValue", data.search);
  params.append("searchBilling", data.billing);
  params.append("page", data.page);
  params.append("lines", data.lines);

  return axios({
    method: "post",
    url: webURL,
    data: params,
    withCredentials: access,
  });
}

// UsingList 사용내역 검색
function find_using(data) {
  const params = new URLSearchParams();
  params.append("request", "find_using");
  params.append("searchId", data.id);
  params.append("searchValue", data.search);
  params.append("dateFrom", data.dateFrom);
  params.append("dateTo", data.dateTo);
  params.append("searchBox", data.searchBox);
  params.append("searchBox1", data.searchBox1);
  params.append("check", data.check);
  params.append("page", data.page);
  params.append("lines", data.lines);

  return axios({
    method: "post",
    url: webURL,
    data: params,
    withCredentials: access,
  });
}

// paidSearch 정산검색
function find_billing_result(data) {
  const params = new URLSearchParams();
  params.append("request", "find_billing_result");
  params.append("searchId", data.id);
  params.append("dateFrom", data.dateFrom);
  params.append("dateTo", data.dateTo);
  params.append("qFrom", data.qFrom);
  params.append("qTo", data.qTo);

  return axios({
    method: "post",
    url: webURL,
    data: params,
    withCredentials: access,
  });
}

// paidresult 정산결과 내역 검색
function find_paid_result(data) {
  const params = new URLSearchParams();
  params.append("request", "find_paid_result");
  params.append("searchId", data.id);
  params.append("searchValue", data.search);
  params.append("page", data.page);
  params.append("lines", data.lines);

  return axios({
    method: "post",
    url: webURL,
    data: params,
    withCredentials: access,
  });
}

// paidpro 정산처리 정산처리해야할 내역 검색
function find_paid_pro(data) {
  const params = new URLSearchParams();
  params.append("request", "find_paid_pro");
  params.append("searchId", data.id);
  params.append("searchValue", data.search);
  params.append("term", data.term);
  params.append("check", data.check);

  return axios({
    method: "post",
    url: webURL,
    data: params,
    withCredentials: access,
  });
}

// plugRegister 플러그 엑셀등록 페이지 등록플러그 검색
function find_plug_register(data) {
  const params = new URLSearchParams();
  params.append("request", "find_plug_register");
  params.append("searchId", data.id);
  params.append("searchValue", data.search);
  params.append("page", data.page);
  params.append("lines", data.lines);

  return axios({
    method: "post",
    url: webURL,
    data: params,
    withCredentials: access,
  });
}

// managerList 계좌승인 히스토리 내역보기(팝업)
function get_acc_history_list(site_id) {
  const params = new URLSearchParams();
  params.append("request", "get_acc_history_list");
  params.append("site_id", site_id);

  return axios({
    method: "post",
    url: webURL,
    data: params,
    withCredentials: access,
  });
}

// plugList 플러그 디테일 정보 플러그 정보 -> 정보보기 (deviceID별 팝업으로 정보보기)
function get_popup_plug_list(pages) {
  const params = new URLSearchParams();
  params.append("request", "get_popup_plug_list");
  params.append("provider_id", pages.provider_id);
  params.append("page", pages.page);
  params.append("lines", pages.pageSize);
  return axios({
    method: "post",
    url: webURL,
    data: params,
    withCredentials: access,
  });
}

// plugRegister 플러그 엑셀 등록시 엑셀에 플러그 id 및 데이터 생성
function create_plug_id(data) {
  const params = new URLSearchParams();
  params.append("request", "create_plug_id");
  params.append("data", JSON.stringify(data));

  return axios({
    method: "post",
    url: webURL,
    data: params,
    withCredentials: access,
  });
}

// paidresultTable 정산된 리스트 출력
function get_paid_plug_list(data) {
  const params = new URLSearchParams();
  params.append("request", "get_paid_plug_list");
  params.append("site_id", data.site_id);
  params.append("amount", data.amount);
  params.append("bill_id", data.bill_id);

  return axios({
    method: "post",
    url: webURL,
    data: params,
    withCredentials: access,
  });
}

// plugList otp 발행
function make_otp(data) {
  const params = new URLSearchParams();
  params.append("request", "make_otp");
  params.append("seed_num", data.seed_num);
  params.append("case_num", data.case_num);

  return axios({
    method: "post",
    url: webURL,
    data: params,
    withCredentials: access,
  });
}

// plug db 초기화
function plug_db_set(data) {
  const params = new URLSearchParams();
  params.append("request", "plug_db_set");
  params.append("plug_id", data);

  return axios({
    method: "post",
    url: webURL,
    data: params,
    withCredentials: access,
  });
}

// userList 환불 포인트 생성
function create_refund_point(data) {
  const params = new URLSearchParams();
  params.append("request", "create_refund_point");
  params.append("point", data.point);
  params.append("user_id", data.user_id);
  params.append("admin_email", data.admin_email);

  return axios({
    method: "post",
    url: webURL,
    data: params,
    withCredentials: access,
  });
}

// userList 프로모션 포인트 생성
function create_promotion_point(data) {
  const params = new URLSearchParams();
  params.append("request", "create_promotion_point");
  params.append("point", data.point);
  params.append("user_id", data.user_id);
  params.append("admin_email", data.admin_email);
  params.append("use_date", data.use_date);

  return axios({
    method: "post",
    url: webURL,
    data: params,
    withCredentials: access,
  });
}

// priceInput 사용요금 등록
function create_site_price(data) {
  const params = new URLSearchParams();
  params.append("request", "create_site_price");
  params.append("house", data.house_input);
  params.append("general", data.general_input);
  params.append("industry", data.industry_input);
  params.append("education", data.education_input);
  params.append("farm", data.farm_input);

  return axios({
    method: "post",
    url: webURL,
    data: params,
    withCredentials: access,
  });
}

// priceInput 사용요금 등록페이지 테이블 출력

function get_site_price() {
  const params = new URLSearchParams();
  params.append("request", "get_site_price");

  return axios({
    method: "post",
    url: webURL,
    data: params,
    withCredentials: access,
  });
}

export {
  get_plug_record_list,
  login_admin,
  get_admin_detail,
  get_admin_list,
  create_admin,
  find_provider,
  get_plug_list,
  get_site_list,
  get_plug_record_detail,
  comp_password,
  update_admin,
  get_settlement_list,
  get_user_list,
  get_manager_list,
  get_sitelist,
  update_settle,
  popup_onoff,
  get_using_list,
  get_dashboard,
  create_billing,
  get_bill_record,
  get_admin_approval,
  create_comment,
  create_notice,
  get_admin_plug_list,
  update_password,
  find_admin,
  find_site,
  find_plug,
  find_ticket,
  find_user,
  find_user_popup,
  find_manager,
  find_using,
  find_billing_result,
  get_acc_history_list,
  get_popup_plug_list,
  delete_admin,
  find_paid_result,
  find_paid_pro,
  find_plug_register,
  create_plug_id,
  get_admin_ticket_list,
  get_paid_plug_list,
  make_otp,
  plug_db_set,
  create_refund_point,
  create_promotion_point,
  create_site_price,
  get_site_price,
  // create_section
};
