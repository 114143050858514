<template>
  <div class="content" v-if="this.change_access === 'y'">
    <div class="row">
      <div class="col-6">
        <card
          class="strpied-tabled-with-hover"
          body-classes="table-full-width table-responsive"
        >
          <template slot="header">
            <h4 class="card-title">플러그</h4>
          </template>
          <div class="search_div">
            <div class="search_input_row">
              <p class="p1">검색 기준</p>
              <base-select-search
                type="text"
                class="text-center selectInput seletbox"
                :items="searchOptionList"
                v-model="searchOptionList.id"
              ></base-select-search>
              <base-input
                type="text"
                placeholder=""
                v-model="searchInput"
                @keypress="enterkey()"
                class="inputbox"
              />
              <button
                class="btn btn-fill btn-primary btn-block search_btn"
                type="button"
                @click="findPlug()"
              >
                <a style="white-space: nowrap">검색</a>
              </button>
            </div>
          </div>

          <!-- <site-table class="table-hover table-striped"></site-table> -->
          <div class="search_result_div">
            <div v-if="searchData.length > 0">
              <div class="countData">총 {{ totalNum }} 개</div>
              <div class="div-table">
                <div class="div-row">
                  <div
                    class="div-cell"
                    v-for="column in tableColumns"
                    :key="column.id"
                  >
                    <span>
                      {{ column.msg }}
                    </span>
                  </div>
                </div>
                <!-- <div class="div-row" v-for="(item, index) in paginatedData" :key="index" @click="moveSiteDetail(item.siteId)"> -->
                <div
                  class="div-row"
                  v-for="(item, index) in searchData"
                  :key="index.id"
                  @click="findUserDetail(index)"
                  :class="{ clickedRow: index === clickRowIndex }"
                >
                  <div
                    class="div-cell"
                    v-for="column in tableColumns"
                    :key="column.id"
                  >
                    <div v-if="column.id == 'status'">
                      <span
                        v-if="
                          itemValue(item, 'status') == 'Available' &&
                          itemValue(item, 'plug_status') == 'BROKEN'
                        "
                        class="status_notuse"
                        >대기중_고장</span
                      >
                      <span
                        v-else-if="itemValue(item, 'status') == 'ACTIVE'"
                        class="status_use"
                        >사용중</span
                      >
                      <span
                        v-else-if="itemValue(item, 'status') == 'Available'"
                        class="status_use"
                        >대기중</span
                      >
                      <span
                        v-else-if="itemValue(item, 'status') == 'Wait'"
                        class="status_wait"
                        >준비중</span
                      >
                      <span
                        v-else-if="itemValue(item, 'status') == 'OTP_ACTIVE'"
                        class="status_use"
                        >사용중</span
                      >
                      <span
                        v-else-if="itemValue(item, 'status') == 'UNLIMIT'"
                        class="status_use"
                        >사용중</span
                      >
                    </div>
                    <div v-else-if="column.id == 'plug_status'">
                      <span
                        v-if="itemValue(item, 'plug_status') == 'BROKEN'"
                        class="status_notuse"
                      >
                        고장</span
                      >
                      <span
                        v-else-if="itemValue(item, 'plug_status') == 'ON'"
                        class="status_use"
                      >
                        ON</span
                      >
                      <span v-else-if="itemValue(item, 'plug_status') == 'OFF'">
                        OFF</span
                      >
                    </div>

                    <div v-else-if="column.id === 'plug_id'">
                      {{ itemValue(item, column.id) | plugIdComma }}
                    </div>
                    <div v-else>
                      {{ itemValue(item, column.id) }}
                    </div>
                  </div>
                </div>
              </div>
              <div class="paging-cover">
                <b-pagination
                  class="mt-3"
                  v-model="pageNum"
                  :total-rows="totalNum"
                  :per-page="pages.pageSize"
                  @page-click="pageClickFind"
                ></b-pagination>
              </div>
            </div>

            <div v-else-if="tableData.length > 0">
              <div class="countData">총 {{ totalNum }} 개</div>
              <div class="div-table">
                <div class="div-row">
                  <div
                    class="div-cell"
                    v-for="(column, index) in tableColumns"
                    :key="index"
                  >
                    <span>
                      {{ column.msg }}
                    </span>
                  </div>
                </div>
                <!-- <div class="div-row" v-for="(item, index) in paginatedData" :key="index" @click="moveSiteDetail(item.siteId)"> -->
                <div
                  class="div-row"
                  v-for="(item, index) in tableData"
                  :key="index.id"
                  @click="getDetail(index)"
                  :class="{ clickedRow: index === clickRowIndex }"
                >
                  <div
                    class="div-cell"
                    v-for="(column, index) in tableColumns"
                    :key="index"
                  >
                    <div v-if="column.id == 'status'">
                      <span
                        v-if="
                          itemValue(item, 'status') == 'Available' &&
                          itemValue(item, 'plug_status') == 'BROKEN'
                        "
                        class="status_notuse"
                        >대기중_고장</span
                      >
                      <span
                        v-else-if="itemValue(item, 'status') == 'ACTIVE'"
                        class="status_use"
                        >사용중</span
                      >
                      <span
                        v-else-if="itemValue(item, 'status') == 'Available'"
                        class="status_use"
                        >대기중</span
                      >
                      <span
                        v-else-if="itemValue(item, 'status') == 'Wait'"
                        class="status_wait"
                        >준비중</span
                      >
                      <span
                        v-else-if="itemValue(item, 'status') == 'OTP_ACTIVE'"
                        class="status_use"
                        >사용중</span
                      >
                      <span
                        v-else-if="itemValue(item, 'status') == 'UNLIMIT'"
                        class="status_use"
                        >사용중</span
                      >
                    </div>
                    <div v-else-if="column.id == 'plug_status'">
                      <span
                        v-if="itemValue(item, 'plug_status') == 'BROKEN'"
                        class="status_notuse"
                      >
                        고장</span
                      >
                      <span
                        v-else-if="itemValue(item, 'plug_status') == 'ON'"
                        class="status_use"
                      >
                        ON</span
                      >
                      <span v-else-if="itemValue(item, 'plug_status') == 'OFF'">
                        OFF</span
                      >
                    </div>

                    <div v-else-if="column.id === 'plug_id'">
                      {{ itemValue(item, column.id) | plugIdComma }}
                    </div>
                    <div v-else>
                      {{ itemValue(item, column.id) }}
                    </div>
                  </div>
                </div>
              </div>
              <div class="paging-cover">
                <b-pagination
                  class="mt-3"
                  v-model="pageNum"
                  :total-rows="totalNum"
                  :per-page="pages.pageSize"
                  @page-click="pageClick"
                ></b-pagination>
              </div>
            </div>
            <div v-else>
              <div class="emptyContent">표시할 데이터가 없습니다.</div>
            </div>
          </div>
        </card>
      </div>

      <div class="col-6">
        <card
          class="strpied-tabled-with-hover"
          body-classes="table-full-width table-responsive"
        >
          <template slot="header">
            <h4 class="card-title">플러그 상세</h4>
          </template>
          <div class="detail_div">
            <div v-if="clickRowIndex >= 0">
              <table class="table">
                <tr>
                  <th>플러그 등록일</th>
                  <td>{{ this.plug.created }}</td>
                </tr>
                <tr>
                  <th>플러그 ID</th>
                  <td>
                    {{ this.plug.plug_id | plugIdComma }}
                  </td>
                </tr>
                <tr>
                  <th>일련 번호</th>
                  <td>{{ this.plug.serial_num }}</td>
                </tr>
                <!-- <tr>
                  <th>플러그 명</th>
                  <td>{{ this.plug.plug_name }}</td>
                </tr> -->
                <tr>
                  <th>건물 관리자 ID</th>
                  <td>
                    {{ this.plug.provider_email }}
                  </td>
                </tr>
                <tr>
                  <th>건물 관리자명</th>
                  <td>{{ this.plug.provider_name }}</td>
                </tr>
                <!-- <tr>
                  <th>주소</th>
                  <td>
                    {{ this.plug.address_0 }}<br />{{ this.plug.address_1 }}
                  </td>
                </tr> -->
                <tr>
                  <th>사이트 ID</th>
                  <td>{{ this.plug.site_id }}</td>
                </tr>
                <tr>
                  <th>사이트명</th>
                  <td>{{ this.plug.site_name }}</td>
                </tr>
                <tr>
                  <th>섹션</th>
                  <td>{{ this.plug.section_name }}</td>
                </tr>
                <tr>
                  <th>플러그 위치</th>
                  <td v-if="plug.plug_type === '1'">커뮤니티 센터</td>
                  <td v-else-if="plug.plug_type === '2'">오뚜기(콘) 결합형</td>
                  <td v-else-if="plug.plug_type === '3'">야외 조경 시설</td>
                  <td v-else-if="plug.plug_type === '4'">통로</td>
                  <td v-else-if="plug.plug_type === '5'">주차장기둥</td>
                  <td v-else-if="plug.plug_type === '6'">주차장 벽면</td>
                  <td v-else-if="plug.plug_type === '7'">폴대형</td>
                  <td v-else-if="plug.plug_type === '8'">테이블 매립형</td>
                </tr>
                <tr>
                  <th>플러그 위치 설명</th>
                  <td>{{ this.plug.location }}</td>
                </tr>
                <tr>
                  <th>플러그 상태</th>
                  <td v-if="plug.plug_status === 'ON'">ON</td>
                  <td v-else-if="plug.plug_status === 'OFF'">OFF</td>
                  <td v-else-if="plug.plug_status === 'BROKEN'">고장</td>
                </tr>
                <tr>
                  <th>사용 상태</th>
                  <td v-if="plug.status === 'ACTIVE'">사용중</td>
                  <td
                    v-else-if="
                      plug.plug_status === 'BROKEN' &&
                      plug.status === 'Available'
                    "
                  >
                    대기중_고장
                  </td>
                  <td v-else-if="plug.status === 'Wait'">준비중</td>
                  <td v-else-if="plug.status === 'OTP_ACTIVE'">OTP</td>
                  <td v-else-if="plug.status === 'Available'">대기중</td>
                  <td v-else-if="plug.status === 'UNLIMIT'">사용중</td>
                </tr>
                <!-- <tr>
                  <th>플러그 동기화</th>
                  <td></td>
                </tr> -->

                <tr>
                  <th>OTP 발행</th>
                  <td>
                    <div class="row">
                      <div class="radio_div col-10" id="seconddiv">
                        <div class="rd_paycycle_div1">
                          <input
                            type="radio"
                            name="rd_paycycle"
                            value="1"
                            v-model="case_num"
                          />관리자 PW 재설정
                        </div>
                        <div class="rd_paycycle_div1">
                          <input
                            type="radio"
                            name="rd_paycycle"
                            value="2"
                            v-model="case_num"
                          />ADM MODE 접속
                        </div>
                        <div class="rd_paycycle_div1">
                          <input
                            type="radio"
                            name="rd_paycycle"
                            value="3"
                            v-model="case_num"
                          />공장초기화
                        </div>
                      </div>

                      <div class="col-2">
                        <button
                          v-if="this.permission === 'true'"
                          class="btn btn-default btn-sm btn-fill btn-primary btn-block paycycle_btn"
                          type="button"
                          @click="makeOtp()"
                        >
                          OTP 발행
                        </button>
                      </div>
                    </div>
                  </td>
                </tr>
                <tr>
                  <th>DB 삭제</th>
                  <td>
                    <div class="row">
                      <div class="radio_div col-10">
                        Plug ID : {{ this.plug.plug_id | plugIdComma }}
                      </div>

                      <div class="col-2">
                        <button
                          v-if="this.permission === 'true'"
                          class="btn btn-default btn-sm btn-fill btn-primary btn-block paycycle_btn"
                          type="button"
                          @click="dbSet()"
                        >
                          DB SET
                        </button>
                      </div>
                    </div>
                  </td>
                </tr>
              </table>
            </div>
            <div v-else>
              <div class="emptyContent">
                상세 보기 할 데이터를 클릭해 주세요.
              </div>
            </div>
          </div>
        </card>
      </div>
      <otp-issue-modal
        v-if="showOtpIssueModal"
        @close="showOtpIssueModal = false"
        :plug_id="plug_id"
        :plug_name="plug_name"
        :otp_number="otp_number"
      ></otp-issue-modal>
    </div>
    <!-- //row -->
  </div>
  <div v-else>
    <card>
      <h1>해당 계정은 [운영 관리] 페이지에 접근 권한이 없습니다.</h1>
    </card>
  </div>
</template>
<script>
import Card from "src/components/Cards/Card.vue";
import BaseInput from "src/components/Inputs/BaseInput.vue";
import OtpIssueModal from "src/components/Modals/OtpIssueModal.vue";

export default {
  filters: {
    plugIdComma(val) {
      return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ");
    },
  },
  components: {
    Card,
    BaseInput,
    OtpIssueModal,
  },
  data() {
    return {
      // 건물 사용자에서 플러그 정보보기(팝업) 클릭시 사용되는 데이터
      para_id: "",

      // otp 발행 데이터
      case_num: "",

      // otp 발행 넘버
      otp_number: "",

      // 플러그 정보 데이터
      plug: {
        plug_id: "",
        plug_status: "",
        created: "",
        plug_name: "",
        location: "",
        plug_type: "",
        status: "",
        provider_name: "",
        provider_email: "",
        // address_0: "",
        // address_1: "",
        site_id: "",
        site_name: "",
        section_name: "",
        admin_email: "",
        plug_type: "",
        seed_num: "",
        serial_num: "",
      },
      change_access: "",

      // otp 발행 모달창
      showOtpIssueModal: false,

      index: "",

      // 페이지 갯수
      pages: { page: 1, pageSize: 15, provider_id: "" },

      // 페이징 넘버
      pageNum: 0,

      // 페이징 전체 넘버
      totalNum: 0,

      // 검색창 데이터
      searchInput: "",

      // 검색기준 셀렉트 박스 데이터
      searchOptionList: [
        { id: "all", name: "전체" },
        { id: "plug_id", name: "플러그 ID" },
        // { id: "plug_name", name: "플러그 명" },
        { id: "site_name", name: "사이트명" },
        // { id: "status", msg: "사용 상태" },
      ],

      // 메인 테이블 데이터 컬럼
      tableColumns: [
        { id: "plug_id", msg: "플러그 ID" },
        // { id: "plug_name", msg: "플러그 명" },
        { id: "site_name", msg: "사이트명" },
        { id: "plug_status", msg: "플러그 상태" },
        { id: "status", msg: "사용 상태" },
      ],

      // 테이블 데이터
      tableData: [],

      // 검색 테이블 데이터
      searchData: [],

      my_id: "",
      clickRowIndex: -1,
      permission: "",
    };
  },
  async created() {
    if (sessionStorage.getItem("login") !== null) {
      this.change_access =
        this.$store.state.get_admin_detail.admin_user_profile.access.slice(
          2,
          3
        );
      this.my_id =
        this.$store.state.get_admin_detail.admin_user_profile.admin_id;
      this.plug.admin_email =
        this.$store.state.get_admin_detail.admin_user_profile.admin_email;
      this.permission =
        this.$store.state.get_admin_detail.admin_user_profile.permission;
    }
    if (this.change_access === "y") {
      if (this.$route.params.provider_id != null) {
        this.para_id = this.$route.params.provider_id;
        // alert(this.para_id);

        this.getPopupList();
      } else {
        this.getAdminPlugList();
      }
    }
  },
  computed: {
    pageCount() {
      //전체 페이지 수 count
      let listLeng = this.totalNum,
        listSize = this.pages.pageSize,
        page = Math.floor(listLeng / listSize);

      if (listLeng % listSize > 0) page += 1;
      return page;
    },
    pageFindCount() {
      //전체 페이지 수 count
      let listLeng = this.totalNum,
        listSize = this.pages.pageSize,
        page = Math.floor(listLeng / listSize);

      if (listLeng % listSize > 0) page += 1;
      return page;
    },
  },

  methods: {
    // 엔터 클릭시 처리되도록 하는 기능
    enterkey() {
      if (window.event.keyCode == 13) {
        this.findPlug();
      }
    },
    list: function () {
      var list = [];
      for (var i = 1; i < this.age; i += 2) list.push(i);
      return list;
    },
    itemValue(item, column) {
      return item[column.toLowerCase()];
    },

    // otp 발행 기능
    async makeOtp() {
      if (this.case_num === "") {
        alert("Case를 선택하세요");
      } else {
        this.showOtpIssueModal = true;
        this.plug_id = this.plug.plug_id;
        this.plug_name = this.plug.plug_name;
        var data = {};
        data.case_num = this.case_num;
        data.seed_num = this.plug.seed_num;
        console.log(data);
        const response = await this.$store.dispatch("MAKE_OTP", data);
        console.log(response);
        this.otp_number = response.data.data.otp_number;
      }
    },

    //DB plug 세팅
    async dbSet() {
      let plug_id = this.plug.plug_id;
      if (confirm(plug_id + "의 초기화를 진행하시겠습니까?")) {
        const res = await this.$store.dispatch("PLUG_DB_SET", plug_id);
        console.log(res);
        if (res.data.data.query_result === "true") {
          alert("초기화 되었습니다.");
        } else {
          alert("abc");
          alert(res.data.data.query_result);
        }
      } else {
        alert("취소되었습니다.");
      }
      // const response = await this.$store.dispatch("DB_SET", data);
      //     // console.log(response);
      //     // this.otp_number = response.data.data.otp_number;
      //   }
    },
    /* 페이징 클릭 시 */
    pageClick(button, page) {
      this.getAdminPlugList(page);
    },

    // 플러그 검색
    pageClickFind(button, page) {
      this.findPlug(page);
    },

    // 플러그 검색
    async findPlug(page) {
      this.pageNum = page ? page : 1;
      this.pages.page = this.pageNum;

      if (this.searchOptionList.id === undefined) {
        this.searchOptionList.id = "all";
      }
      var data = {};
      data.id = this.searchOptionList.id;
      data.search = this.searchInput;
      data.page = this.pages.page;
      data.lines = this.pages.pageSize;

      const response = await this.$store.dispatch("FIND_PLUG", data);
      console.log(response);
      var list = response.data.data.find_list;
      this.totalNum = response.data.data.total;

      if (!list || list.length === 0) {
        // alert("검색된 데이터가 없습니다. 다시 검색하시기 바랍니다.");
        this.tableData = [];
        list = [];
      }
      this.searchData = [];
      for (var item of list) {
        var temp = {
          provider_id: item.provider_id,
          plug_id: item.plug_id,
          name: item.name,
          created: item.created,
          plug_name: item.plug_name,
          location: item.location,
          plug_type: item.type,
          plug_status: item.plug_status,
          status: item.status,
          plug_type: item.plug_type,
          seed_num: item.seed_num,
          serial_num: item.serial_num,
          // provider
          provider_name: item.provider_name,
          provider_email: item.provider_email,
          address_0: item.address_0,
          address_1: item.address_1,

          // site section
          site_id: item.site_id,
          site_name: item.site_name,
          section_name: item.section_name,
        };
        this.searchData.push(temp);
      }
      this.clickRowIndex = -1;
    },

    // 검색 데이터 상세정보
    async findUserDetail(index) {
      this.clickRowIndex = index;
      this.case_num = "";
      this.plug.plug_id = this.searchData[index].plug_id;
      this.plug.plug_name = this.searchData[index].plug_name;
      this.plug.seed_num = this.searchData[index].seed_num;
      this.plug.serial_num = this.searchData[index].serial_num;
      // this.plug.address_0 = this.searchData[index].address_0;
      // this.plug.address_1 = this.searchData[index].address_1;
      this.plug.created = this.searchData[index].created;
      this.plug.status = this.searchData[index].status;
      this.plug.provider_email = this.searchData[index].provider_email;
      this.plug.provider_name = this.searchData[index].provider_name;
      this.plug.location = this.searchData[index].location;
      this.plug.plug_status = this.searchData[index].plug_status;
      this.plug.plug_type = this.searchData[index].plug_type;
      this.plug.site_id = this.searchData[index].site_id;
      this.plug.site_name = this.searchData[index].site_name;
      this.plug.section_name = this.searchData[index].section_name;
      this.index = index;
    },

    // 메인테이블 상세정보
    async getDetail(index) {
      this.clickRowIndex = index;
      this.case_num = "";
      this.plug.plug_id = this.tableData[index].plug_id;
      this.plug.plug_name = this.tableData[index].plug_name;
      this.plug.seed_num = this.tableData[index].seed_num;
      this.plug.serial_num = this.tableData[index].serial_num;
      // this.plug.address_0 = this.tableData[index].address_0;
      // this.plug.address_1 = this.tableData[index].address_1;
      this.plug.created = this.tableData[index].created;
      this.plug.status = this.tableData[index].status;
      this.plug.provider_email = this.tableData[index].provider_email;
      this.plug.provider_name = this.tableData[index].provider_name;
      this.plug.location = this.tableData[index].location;
      this.plug.plug_status = this.tableData[index].plug_status;
      this.plug.plug_type = this.tableData[index].plug_type;
      this.plug.site_id = this.tableData[index].site_id;
      this.plug.site_name = this.tableData[index].site_name;
      this.plug.section_name = this.tableData[index].section_name;
      this.index = index;
    },

    // 메인 테이블 데이터
    async getAdminPlugList(page) {
      this.pageNum = page ? page : 1;
      this.pages.page = this.pageNum;

      const response = await this.$store.dispatch(
        "FETCH_GET_ADMIN_PLUG_LIST",
        this.pages
      );
      console.log(response.data);
      console.log(response);

      var list = response.data.data.admin_plug_list;
      this.totalNum = response.data.data.total;
      this.tableData = [];
      for (var item of list) {
        var temp = {
          provider_id: item.provider_id,
          plug_id: item.plug_id,
          name: item.name,
          created: item.created,
          plug_name: item.plug_name,
          location: item.location,
          plug_type: item.type,
          plug_status: item.plug_status,
          status: item.status,
          plug_type: item.plug_type,
          seed_num: item.seed_num,
          serial_num: item.serial_num,
          // provider
          provider_name: item.provider_name,
          provider_email: item.provider_email,
          address_0: item.address_0,
          address_1: item.address_1,

          // site section
          site_id: item.site_id,
          site_name: item.site_name,
          section_name: item.section_name,
        };
        this.tableData.push(temp);
      }
      this.clickRowIndex = -1;
    },

    // 윈도우팝업시 provider_id에 해당하는 플러그 리스트만 보이도록 생성
    async getPopupList(page) {
      this.pageNum = page ? page : 1;
      this.pages.page = this.pageNum;
      this.pages.provider_id = this.para_id;
      const response = await this.$store.dispatch(
        "FETCH_GET_POPUP_PLUG_LIST",
        this.pages
      );
      console.log(response.data);
      console.log(response);

      var list = response.data.data.popup_plug_list;
      this.totalNum = response.data.data.total;
      this.tableData = [];
      for (var item of list) {
        var temp = {
          provider_id: item.provider_id,
          plug_id: item.plug_id,
          name: item.name,
          created: item.created,
          plug_name: item.plug_name,
          location: item.location,
          plug_type: item.type,
          plug_status: item.plug_status,
          status: item.status,
          plug_type: item.plug_type,
          // provider
          provider_name: item.provider_name,
          provider_email: item.provider_email,
          address_0: item.address_0,
          address_1: item.address_1,

          // site section
          site_id: item.site_id,
          site_name: item.site_name,
          section_name: item.section_name,
        };
        this.tableData.push(temp);
      }
      this.clickRowIndex = -1;
    },
  },
};
</script>
<style scoped>
.search_div {
  padding: 10px 30px 30px 20px;
}
.search_div .search_input_row {
  margin: 20px 0 0 0;
  display: flex;
}
.search_div .search_btn {
  width: 70px;
  font-weight: 400;
  background-color: #3195cf;
  border: 1px solid #4388c1;
  color: white;
  height: 35px;
  font-size: 13px;
}
.countData {
  padding: 15px;
}
.radio_div {
  line-height: 30px;
}
.radio_div .form-check {
  display: inline-block;
}
.rd_paycycle_div1 {
  justify-content: space-between;
  align-items: center;
  padding: 0 20px 0 0;
  display: inline-block;
}
.paycycle_btn {
  width: 100px;
  height: 30px;
  float: right;
}

.detail_div {
  margin: 10px 25px;
}
.table th {
  background: #f2f2f2;
}
.checkbox_div .form-check {
  display: inline-block;
}

.btn_div button {
  margin: 10px 10px;
  width: 100px;
}
.uselog_btn {
  color: black;
}
.p1 {
  margin: 5px 0 0 0;
  white-space: nowrap;
}
.seletbox {
  margin: 0 20px 0 0;
}
.inputbox {
  margin: 0 20px 2% 0;
  width: 50%;
  position: relative;
}
</style>
