<template>
  <div class="content" v-if="this.change_access === 'y'">
    <div class="row">
      <div class="col-6">
        <card
          class="strpied-tabled-with-hover"
          body-classes="table-full-width table-responsive"
        >
          <template slot="header">
            <h4 class="card-title">정기권 구매 내역</h4>
          </template>
          <div class="search_div">
            <div class="search_input_row">
              <p class="p1">검색 기준</p>
              <base-select-search
                type="text"
                class="text-center selectInput seletbox"
                :items="searchOptionList"
                v-model="searchOptionList.id"
              ></base-select-search>
              <base-input
                type="text"
                placeholder=""
                v-model="searchInput"
                @keypress="enterkey()"
                class="inputbox"
              />
            </div>

            <div class="search_input_row">
              <div class="p2">구매 일자</div>

              <input
                type="date"
                id="datefield"
                v-model="dateFrom"
                min="2021-01-01"
                max="2050-13-13"
                onfocus="today"
                class="date1"
              />
              <input
                type="date"
                id="datefield"
                v-model="dateTo"
                min="2021-01-01"
                max="2050-13-13"
                onfocus="today"
                class="date2"
              />
            </div>
            <button
              class="btn btn-fill btn-primary btn-block search_btn"
              type="button"
              @click="findTicket()"
            >
              <a style="white-space: nowrap">검색</a>
            </button>
          </div>
          <!-- <site-table class="table-hover table-striped"></site-table> -->
          <div class="search_result_div">
            <div v-if="searchData.length > 0">
              <div class="countData">총 {{ totalNum }} 개</div>
              <div class="div-table">
                <div class="div-row">
                  <div
                    class="div-cell"
                    v-for="column in tableColumns"
                    :key="column.id"
                  >
                    <span>
                      {{ column.msg }}
                    </span>
                  </div>
                </div>
                <!-- <div class="div-row" v-for="(item, index) in paginatedData" :key="index" @click="moveSiteDetail(item.siteId)"> -->
                <div
                  class="div-row"
                  v-for="(item, index) in searchData"
                  :key="index.id"
                  @click="findUserDetail(index)"
                  :class="{ clickedRow: index === clickRowIndex }"
                >
                  <div
                    class="div-cell"
                    v-for="column in tableColumns"
                    :key="column.id"
                  >
                    {{ itemValue(item, column.id) }}
                  </div>
                </div>
              </div>
              <div class="paging-cover">
                <b-pagination
                  class="mt-3"
                  v-model="pageNum"
                  :total-rows="totalNum"
                  :per-page="pages.pageSize"
                  @page-click="pageClickFind"
                ></b-pagination>
              </div>
            </div>
            <div v-else-if="tableData.length > 0">
              <div class="countData">총 {{ totalNum }} 개</div>
              <div class="div-table">
                <div class="div-row">
                  <div
                    class="div-cell"
                    v-for="(column, index) in tableColumns"
                    :key="index"
                  >
                    <span>
                      {{ column.msg }}
                    </span>
                  </div>
                </div>
                <!-- <div class="div-row" v-for="(item, index) in paginatedData" :key="index" @click="moveSiteDetail(item.siteId)"> -->
                <div
                  class="div-row"
                  v-for="(item, index) in tableData"
                  :key="index.id"
                  @click="getDetail(index)"
                  :class="{ clickedRow: index === clickRowIndex }"
                >
                  <div
                    class="div-cell"
                    v-for="(column, index) in tableColumns"
                    :key="index"
                  >
                    <div>
                      {{ itemValue(item, column.id) }}
                    </div>
                  </div>
                </div>
              </div>
              <div class="paging-cover">
                <b-pagination
                  class="mt-3"
                  v-model="pageNum"
                  :total-rows="totalNum"
                  :per-page="pages.pageSize"
                  @page-click="pageClick"
                ></b-pagination>
              </div>
            </div>
            <div v-else>
              <div class="emptyContent">표시할 데이터가 없습니다.</div>
            </div>
          </div>
        </card>
      </div>

      <div class="col-6">
        <card
          class="strpied-tabled-with-hover"
          body-classes="table-full-width table-responsive"
        >
          <template slot="header">
            <h4 class="card-title">정기권 구매 내역 상세</h4>
          </template>
          <div class="detail_div">
            <div v-if="clickRowIndex >= 0">
              <table class="table">
                <tr>
                  <th>사이트ID</th>
                  <td>{{ this.ticket.site_id }}</td>
                </tr>
                <tr>
                  <th>사이트명</th>
                  <td>{{ this.ticket.site_name }}</td>
                </tr>
                <tr>
                  <th>사용자 ID</th>
                  <td>{{ this.ticket.user_id }}</td>
                </tr>
                <tr>
                  <th>사용자 이름</th>
                  <td>
                    {{ this.ticket.user_name }}
                  </td>
                </tr>
                <tr>
                  <th>사용자 핸드폰</th>
                  <td>{{ this.ticket.mob_phone }}</td>
                </tr>
                <tr>
                  <th>정기권 구매 일자</th>
                  <td>
                    {{ this.ticket.created }}
                  </td>
                </tr>
                <tr>
                  <th>정기권 종료 일자</th>
                  <td>{{ this.ticket.end_date }}</td>
                </tr>
                <tr>
                  <th>구매 금액</th>
                  <td>
                    {{ this.ticket.charge | numCommaFilter | moneyFilter }}
                  </td>
                </tr>
                <tr>
                  <th>사용시간</th>
                  <td>{{ this.ticket.use_time | numToTimeFilter }}</td>
                </tr>
                <tr>
                  <th>남은 시간</th>
                  <td>{{ this.ticket.left_time | numToTimeFilter }}</td>
                </tr>
              </table>
            </div>
            <div v-else>
              <div class="emptyContent">
                상세 보기 할 데이터를 클릭해 주세요.
              </div>
            </div>
          </div>
        </card>
      </div>
    </div>
    <!-- //row -->
  </div>
  <div v-else>
    <card>
      <h1>해당 계정은 [운영 관리] 페이지에 접근 권한이 없습니다.</h1>
    </card>
  </div>
</template>
<script>
import Card from "src/components/Cards/Card.vue";
import BaseInput from "src/components/Inputs/BaseInput.vue";
import dayjs from "dayjs";

export default {
  components: {
    Card,
    BaseInput,
  },
  data() {
    return {
      // 구매일자 날짜 데이터
      dateFrom: dayjs().subtract(1, "year").add(1, "day").format("YYYY-MM-DD"),
      dateTo: dayjs().format("YYYY-MM-DD"),

      // 정기권 구매내역 데이터
      ticket: {
        user_id: "",
        end_date: "",
        created: "",
        plug_name: "",
        use_time: "",
        left_time: "",
        charge: "",
        user_name: "",
        mob_phone: "",
        site_id: "",
        site_name: "",
      },
      index: "",

      // 페이징 갯수
      pages: { page: 1, pageSize: 15, provider_id: "" },
      pageNum: 0,
      totalNum: 0,

      // 검색 인풋박스 데이터
      searchInput: "",
      testData: "",

      // 검색기준 셀렉트박스 데이터
      searchOptionList: [
        { id: "all", name: "전체" },
        { id: "site_name", name: "사이트명" },
        { id: "user_name", name: "이름" },
        { id: "mob_phone", name: "핸드폰 번호" },
      ],

      // 메인 테이블 컬럼
      tableColumns: [
        { id: "site_id", msg: "사이트ID" },
        { id: "site_name", msg: "사이트명" },
        { id: "user_name", msg: "이름" },
        { id: "mob_phone", msg: "핸드폰 번호" },
        { id: "created", msg: "구매 일자" },
        { id: "end_date", msg: "종료 일자" },
      ],
      tableData: [],
      searchData: [],
      my_id: "",
      clickRowIndex: -1,
    };
  },
  async created() {
    //초기 데이터 셋팅
    this.getAdminTicketList();

    if (sessionStorage.getItem("login") !== null) {
      this.change_access =
        this.$store.state.get_admin_detail.admin_user_profile.access.slice(
          2,
          3
        );
      this.my_id =
        this.$store.state.get_admin_detail.admin_user_profile.admin_id;
      this.ticket.admin_email =
        this.$store.state.get_admin_detail.admin_user_profile.admin_email;
    }
  },
  computed: {
    pageCount() {
      //전체 페이지 수 count
      let listLeng = this.totalNum,
        listSize = this.pages.pageSize,
        page = Math.floor(listLeng / listSize);

      if (listLeng % listSize > 0) page += 1;
      return page;
    },
    pageFindCount() {
      //전체 페이지 수 count
      let listLeng = this.totalNum,
        listSize = this.pages.pageSize,
        page = Math.floor(listLeng / listSize);

      if (listLeng % listSize > 0) page += 1;
      return page;
    },
  },

  methods: {
    enterkey() {
      if (window.event.keyCode == 13) {
        this.findTicket();
      }
    },
    list: function () {
      var list = [];
      for (var i = 1; i < this.age; i += 2) list.push(i);
      return list;
    },
    itemValue(item, column) {
      return item[column.toLowerCase()];
    },
    /* 페이징 클릭 시 */
    pageClick(button, page) {
      this.getAdminTicketList(page);
    },
    pageClickFind(button, page) {
      this.findTicket(page);
    },

    // 검색 기능
    async findTicket(page) {
      this.pageNum = page ? page : 1;
      this.pages.page = this.pageNum;

      if (this.searchOptionList.id === undefined) {
        this.searchOptionList.id = "all";
      }
      var data = {};
      data.id = this.searchOptionList.id;
      data.search = this.searchInput;
      data.dateFrom = this.dateFrom;
      data.dateTo = this.dateTo;
      data.page = this.pages.page;
      data.lines = this.pages.pageSize;

      const response = await this.$store.dispatch("FIND_TICKET", data);
      console.log(response);
      var list = response.data.data.admin_ticket_list;
      this.totalNum = response.data.data.total;

      if (!list || list.length === 0) {
        // alert("검색된 데이터가 없습니다. 다시 검색하시기 바랍니다.");
        this.tableData = [];
        list = [];
      }
      this.searchData = [];
      for (var item of list) {
        var temp = {
          user_id: item.user_id,
          user_name: item.name,
          created: item.created,
          end_date: item.end_date,
          left_time: item.left_time,
          use_time: 300 - item.left_time,
          charge: item.charge,
          // provider
          user_name: item.user_name,
          mob_phone: item.mob_phone,
          // site section
          site_id: item.site_id,
          site_name: item.site_name,
        };
        this.searchData.push(temp);
      }
      this.clickRowIndex = -1;
    },

    // 검색 데이터 상세정보
    async findUserDetail(index) {
      this.clickRowIndex = index;
      this.ticket.user_id = this.searchData[index].user_id;
      this.ticket.created = this.searchData[index].created;
      this.ticket.user_name = this.searchData[index].user_name;
      this.ticket.mob_phone = this.searchData[index].mob_phone;
      this.ticket.end_date = this.searchData[index].end_date;
      this.ticket.left_time = this.searchData[index].left_time;
      this.ticket.use_time = this.searchData[index].use_time;
      this.ticket.charge = this.searchData[index].charge;
      this.ticket.site_id = this.searchData[index].site_id;
      this.ticket.site_name = this.searchData[index].site_name;
      this.index = index;
    },

    // 메인 테이블 데이터 상세저ㅓㅇ보
    async getDetail(index) {
      this.clickRowIndex = index;
      this.ticket.user_id = this.tableData[index].user_id;
      this.ticket.created = this.tableData[index].created;
      this.ticket.user_name = this.tableData[index].user_name;
      this.ticket.mob_phone = this.tableData[index].mob_phone;
      this.ticket.end_date = this.tableData[index].end_date;
      this.ticket.left_time = this.tableData[index].left_time;
      this.ticket.use_time = this.tableData[index].use_time;
      this.ticket.charge = this.tableData[index].charge;
      this.ticket.site_id = this.tableData[index].site_id;
      this.ticket.site_name = this.tableData[index].site_name;
      this.index = index;
    },

    // 정기권구매 내역 리스트
    async getAdminTicketList(page) {
      this.pageNum = page ? page : 1;
      this.pages.page = this.pageNum;

      const response = await this.$store.dispatch(
        "FETCH_GET_ADMIN_TICKET_LIST",
        this.pages
      );
      console.log(response.data);
      console.log(response);

      var list = response.data.data.admin_ticket_list;
      this.totalNum = response.data.data.total;
      this.tableData = [];
      for (var item of list) {
        var temp = {
          user_id: item.user_id,
          user_name: item.name,
          created: item.created,
          end_date: item.end_date,
          left_time: item.left_time,
          use_time: 300 - item.left_time,
          charge: item.charge,
          // provider
          user_name: item.user_name,
          mob_phone: item.mob_phone,
          // site section
          site_id: item.site_id,
          site_name: item.site_name,
        };
        this.tableData.push(temp);
      }
      this.clickRowIndex = -1;
    },
  },
};
</script>
<style scoped>
.search_div {
  padding: 10px 30px 30px 20px;
}
.search_div .search_input_row {
  margin: 20px 0 0 0;
  display: flex;
}
.search_div .search_btn {
  width: 300px;
  font-weight: 400;
  background-color: #3195cf;
  border: 1px solid #4388c1;
  color: white;
  height: 35px;
  font-size: 13px;
  margin: 20px auto;
}
.countData {
  padding: 15px;
}

.detail_div {
  margin: 10px 25px;
}
.table th {
  background: #f2f2f2;
}
.checkbox_div .form-check {
  display: inline-block;
}

.btn_div button {
  margin: 10px 10px;
  width: 100px;
}
.uselog_btn {
  color: black;
}
.p1 {
  margin: 5px 0 0 0;
  white-space: nowrap;
}
.seletbox {
  margin: 0 20px 0 0;
}
.inputbox {
  margin: 0 20px 2% 0;
  width: 50%;
  position: relative;
}

.date1 {
  border-radius: 4px;
  border: 1px solid #4388c1;
  padding-left: 10px;
  height: 35px;
  margin: 0 0 0 20px;
}
.date2 {
  border-radius: 4px;
  border: 1px solid #4388c1;
  padding-left: 10px;
  height: 35px;
  margin: 0 0 0 30px;
}
</style>
