v
<template>
  <div class="content" v-if="this.change_permission === 'true'">
    <div class="container-fluid">
      <card>
        <div>
          <h4 slot="header" class="card-title">사용 요금 등록</h4>
        </div>

        <div>
          <b-table hover bordered :items="items" :fields="fields">
            <template #table-caption>
              <button
                class="float-right link_electric btn-link"
                @click.prevent="moveTo()"
              >
                한전 전기요금 구조
              </button>
            </template>
          </b-table>
        </div>
        <div style="margin-top: 50px"></div>
        <div class="row">
          <div class="col-md-3">
            <label for="apartment" class="control-label"
              >주택용 (원 / 1kWh)</label
            >
            <input type="checkbox" id="apartment" v-model="house" />
            <base-input
              type="text"
              v-model="data.house_input"
              :disabled="house == false"
            >
            </base-input>
          </div>
        </div>
        <div class="row">
          <div class="col-md-3">
            <label for="apartment" class="control-label"
              >일반용 (원 / 1kWh)</label
            >
            <input type="checkbox" id="apartment" v-model="general" />
            <base-input
              type="text"
              v-model="data.general_input"
              :disabled="general == false"
            >
            </base-input>
          </div>
        </div>
        <div class="row">
          <div class="col-md-3">
            <label for="apartment" class="control-label"
              >산업용 (원 / 1kWh)</label
            >
            <input type="checkbox" id="apartment" v-model="industry" />
            <base-input
              type="text"
              v-model="data.industry_input"
              :disabled="industry == false"
            >
            </base-input>
          </div>
        </div>
        <div class="row">
          <div class="col-md-3">
            <label for="apartment" class="control-label"
              >교육용 (원 / 1kWh)</label
            >
            <input type="checkbox" id="apartment" v-model="education" />
            <base-input
              type="text"
              v-model="data.education_input"
              :disabled="education == false"
            >
            </base-input>
          </div>
        </div>
        <div class="row">
          <div class="col-md-3">
            <label for="apartment" class="control-label"
              >농사용 (원 / 1kWh)</label
            >
            <input type="checkbox" id="apartment" v-model="farm" />
            <base-input
              type="text"
              v-model="data.farm_input"
              :disabled="farm == false"
            >
            </base-input>
          </div>
        </div>

        <div class="text-center">
          <button
            type="button"
            class="btn btn-fill btn-primary btn-fill float-right savebtn"
            id="saveBtn"
            @click.prevent="savePrice()"
          >
            저장
          </button>
        </div>
      </card>
    </div>
  </div>
  <div v-else>
    <card>
      <h1>해당 계정은 [사용요금 등록] 페이지에 접근 권한이 없습니다.</h1>
    </card>
  </div>
</template>

<script>
import Card from "src/components/Cards/Card.vue";
import BaseInput from "src/components/Inputs/BaseInput.vue";

export default {
  components: {
    Card,
    BaseInput,
  },
  data() {
    return {
      // 주택용 체크
      house: false,

      // 일반용 체크
      general: false,

      // 산업용 체크
      industry: false,

      // 교육용 체크
      education: false,

      // 농사용 체크
      farm: false,

      // 인풋박스 데이터
      data: {
        house_input: 0,
        general_input: 0,
        industry_input: 0,
        education_input: 0,
        farm_input: 0,
      },

      //
      house_one: 12,
      change_permission: "",

      // 테이블 컬럼
      fields: [
        { key: " ", label: " ", thStyle: { width: "20%" } },
        { key: "주택용", label: "주택용", thStyle: {} },
        { key: "일반용", label: "일반용", thStyle: {} },
        { key: "산업용", label: "산업용", thStyle: {} },
        { key: "교육용", label: "교육용", thStyle: {} },
        { key: "농사용", label: "농사용", thStyle: {} },
        { key: "hidden", thClass: "d-none", tdClass: "d-none" },
      ],

      // 테이블 컬럼
      items: [
        {
          " ": "현재 등록 요금 (원 / 1kWh)",
          주택용: "",
          일반용: "0",
          산업용: "0",
          교육용: "0",
          농사용: "0",
        },
        {
          " ": "1시간 기준 요금 (원 / 3kWh)",
          주택용: "0",
          일반용: "0",
          산업용: "0",
          교육용: "0",
          농사용: "0",
        },
        {
          " ": "최대 입력 가능 요금 (원 / 3kWh)",
          주택용: "0",
          일반용: "0",
          산업용: "0",
          교육용: "0",
          농사용: "0",
        },
      ],
      itemsTax: [
        {
          " ": "부가세",
          주택용: "0",
          일반용: "0",
          산업용: "0",
          교육용: "0",
          농사용: "0",
        },
      ],
    };
  },

  async created() {
    if (sessionStorage.getItem("login") !== null) {
      this.change_permission =
        this.$store.state.get_admin_detail.admin_user_profile.permission;
    }
    this.getPrice();
    if (this.change_permission === "true") {
      // 가격테이블
      await this.getPrice();
      //table에 있는 "현재 등록 요금(원/1kWh)"
      this.items[0]["주택용"] = this.data.house_input;
      this.items[0]["일반용"] = this.data.general_input;
      this.items[0]["산업용"] = this.data.industry_input;
      this.items[0]["교육용"] = this.data.education_input;
      this.items[0]["농사용"] = this.data.farm_input;
      //1시간 기준 요금(원/3kWh)
      this.items[1]["주택용"] = Math.round(this.data.house_input * 3);
      this.items[1]["일반용"] = Math.round(this.data.general_input * 3);
      this.items[1]["산업용"] = Math.round(this.data.industry_input * 3);
      this.items[1]["교육용"] = Math.round(this.data.education_input * 3);
      this.items[1]["농사용"] = Math.round(this.data.farm_input * 3);

      // 부가세 계산 처리 추가 20230704 kimJak
      this.itemsTax[0]["주택용"] = Math.floor(
        (this.items[1]["주택용"] * 1.5) / 10
      );
      this.itemsTax[0]["일반용"] = Math.floor(
        (this.items[1]["일반용"] * 1.5) / 10
      );
      this.itemsTax[0]["산업용"] = Math.floor(
        (this.items[1]["산업용"] * 1.5) / 10
      );
      this.itemsTax[0]["교육용"] = Math.floor(
        (this.items[1]["교육용"] * 1.5) / 10
      );
      this.itemsTax[0]["농사용"] = Math.floor(
        (this.items[1]["농사용"] * 1.5) / 10
      );

      //최대 입력 가능 요금 (원 / 3kWh)
      this.items[2]["주택용"] =
        "~ " +
        Math.floor(
          (this.items[1]["주택용"] * 1.5 + this.itemsTax[0]["주택용"]) / 10
        ) *
          10;
      this.items[2]["일반용"] =
        "~ " +
        Math.floor(
          (this.items[1]["일반용"] * 1.5 + this.itemsTax[0]["일반용"]) / 10
        ) *
          10;
      this.items[2]["산업용"] =
        "~ " +
        Math.floor(
          (this.items[1]["산업용"] * 1.5 + this.itemsTax[0]["산업용"]) / 10
        ) *
          10;
      this.items[2]["교육용"] =
        "~ " +
        Math.floor(
          (this.items[1]["교육용"] * 1.5 + this.itemsTax[0]["교육용"]) / 10
        ) *
          10;
      this.items[2]["농사용"] =
        "~ " +
        Math.floor(
          (this.items[1]["농사용"] * 1.5 + this.itemsTax[0]["농사용"]) / 10
        ) *
          10;
    }
  },
  methods: {
    maxLength(id, maxLen) {
      //input 텍스트박스 글자수 제한
      var inputValue = document.getElementById(id).value;
      if (maxLen == null) {
        maxLen =
          $("#" + id).attr("maxLength") != null
            ? $("#" + id).attr("maxLength")
            : 1000;
      }

      if (inputValue.length > maxLen) {
        document.getElementById(id).value = inputValue.substring(0, maxLen);
      }
    },

    // 가격 인풋박스 데이터
    async getPrice() {
      const response = await this.$store.dispatch("GET_SITE_PRICE");
      this.data.house_input = response.data.data[0]["house"];
      this.data.general_input = response.data.data[0]["general"];
      this.data.industry_input = response.data.data[0]["industry"];
      this.data.education_input = response.data.data[0]["education"];
      this.data.farm_input = response.data.data[0]["farm"];
      console.log(response);
    },

    // 저장 버튼 기능
    async savePrice() {
      if (confirm("다음과 같이 저장하는 것이 맞습니까? ")) {
        console.log(this.data);
        const response = await this.$store.dispatch(
          "CREATE_SITE_PRICE",
          this.data
        );
        console.log(response);
        if (response.data.result == true) window.location.reload();
      }
    },

    // 한전 전기요금 구조 버튼 링크
    moveTo() {
      window.open(
        "https://cyber.kepco.co.kr/ckepco/front/jsp/CY/E/E/CYEEHP00110.jsp"
      );
    },
  },
};
</script>
<style scoped>
.control-label {
  font-size: 14px;
  margin-right: 10px;
}
.row {
  margin-top: 0px !important;
}
.link_electric {
  text-decoration: underline;
}
.savebtn {
  margin: 0 1100px 0 0;
}
.btn-primary {
  background-color: #3195cf;
  border: 1px solid #4388c1;
  color: white;
}

/***********joinform css */
* {
  font-family: Noto Sans KR;
  font-style: normal;
}
.joinForm-title {
  margin-bottom: 25px;
}

table {
  width: 80%;
  border-collapse: collapse;
  text-align: right;
}

/* 여기서부터 사용하는 css*/
.div_checkbox {
  display: flex;
}
.container,
.container-fluid,
.container-sm,
.container-md,
.container-lg,
.container-xl {
  width: 100%;
  padding-right: 15px;
  padding-left: 0;
}
.card .table tbody td:first-child {
  width: 20% !important;
}
/* .card .table tbody td:last-child {
  width: 20%;
} */
.card .table {
  border: 2.5px solid black !important;
}
.card .table td,
.table th {
  border: 1px solid black !important;
}

.card .table thead th:last-child {
  display: table-cell !important;
  width: 16% !important;
}

/* .card .table thead .tr .th {
  font-size: 14px !important;
  color: #212529 !important;
  font-weight: 800 !important;
} */
</style>

<style lang="scss">
@mixin can-toggle-branding(
  $can-toggle-off-color: #777,
  $can-toggle-on-color: #42d0ed,
  $can-toggle-inactive-text: rgba(white, 0.5),
  $can-toggle-transition: cubic-bezier(0, 1, 0.5, 1)
) {
  input[type="checkbox"] {
    &[disabled] ~ label {
      color: rgba($can-toggle-off-color, 0.5);
    }

    &:focus ~ label,
    &:hover ~ label {
      .can-toggle__switch {
        background-color: $can-toggle-off-color;
        &:after {
          color: darken($can-toggle-off-color, 10%);
        }
      }
    }
    &:hover ~ label {
      color: darken($can-toggle-off-color, 5%);
    }
    &:checked {
      ~ label {
        &:hover {
          color: darken($can-toggle-on-color, 3%);
        }

        .can-toggle__switch {
          background-color: lighten($can-toggle-on-color, 5%);
          &:after {
            color: darken($can-toggle-on-color, 5%);
          }
        }
      }

      &:focus,
      &:hover {
        ~ label {
          .can-toggle__switch {
            background-color: $can-toggle-on-color;
            &:after {
              color: darken($can-toggle-on-color, 10%);
            }
          }
        }
      }
    }
  }

  label {
    .can-toggle__switch {
      transition: background-color 0.3s $can-toggle-transition;
      background: lighten($can-toggle-off-color, 5%);
      &:before {
        color: $can-toggle-inactive-text;
      }
      &:after {
        // Autoprefixer choked here, so making the prefixes explicit
        -webkit-transition: -webkit-transform 0.3s $can-toggle-transition;
        transition: transform 0.3s $can-toggle-transition;
        color: $can-toggle-off-color;
      }
    }
  }
}

@mixin can-toggle-appearance(
  $can-toggle-width: 134px,
  $can-toggle-height: 36px,
  $can-toggle-border-radius: 4px,
  $can-toggle-offset: 2px,
  $can-toggle-label-font-size: 14px,
  $can-toggle-switch-font-size: 12px,
  $can-toggle-shadow: 0 3px 3px rgba(black, 0.4)
) {
  $can-toggle-switch-width: $can-toggle-width/2;

  input[type="checkbox"] {
    &:focus ~ label,
    &:hover ~ label {
      .can-toggle__switch {
        &:after {
          box-shadow: $can-toggle-shadow;
        }
      }
    }

    &:checked {
      ~ label {
        .can-toggle__switch {
          &:after {
            transform: translate3d(
              $can-toggle-width -
                ($can-toggle-switch-width + $can-toggle-offset),
              0,
              0
            );
          }
        }
      }
      &:focus,
      &:hover {
        ~ label {
          .can-toggle__switch {
            &:after {
              box-shadow: $can-toggle-shadow;
            }
          }
        }
      }
    }
  }

  label {
    font-size: $can-toggle-label-font-size;

    .can-toggle__switch {
      height: $can-toggle-height;
      flex: 0 0 $can-toggle-width;
      border-radius: $can-toggle-border-radius;

      &:before {
        left: $can-toggle-width/2;
        font-size: $can-toggle-switch-font-size;
        line-height: $can-toggle-height;
        width: $can-toggle-width/2;
        padding: 0 12px;
      }

      &:after {
        top: $can-toggle-offset;
        left: $can-toggle-offset;
        border-radius: $can-toggle-border-radius/2;
        width: $can-toggle-switch-width - $can-toggle-offset;
        line-height: $can-toggle-height - ($can-toggle-offset * 2);
        font-size: $can-toggle-switch-font-size;
      }

      &:hover {
        &:after {
          box-shadow: $can-toggle-shadow;
        }
      }
    }
  }
}

.can-toggle {
  position: relative;
  margin-bottom: 15px;
  width: fit-content;
  *,
  *:before,
  *:after {
    box-sizing: border-box;
  }
  //overflow: hidden;

  input[type="checkbox"] {
    opacity: 0;
    position: absolute;
    top: 0;
    left: 0;

    &[disabled] ~ label {
      pointer-events: none;
      .can-toggle__switch {
        opacity: 0.4;
      }
    }

    &:checked {
      ~ label {
        .can-toggle__switch {
          &:before {
            content: attr(data-unchecked);
            left: 0;
          }

          &:after {
            content: attr(data-checked);
          }
        }
      }

      /* &:focus,
      &:hover {
        ~ label {
        }
      } */
    }
  }

  label {
    user-select: none;
    position: relative;
    display: flex;
    align-items: center;

    .can-toggle__switch {
      position: relative;

      &:before {
        content: attr(data-checked);
        position: absolute;
        top: 0;
        text-transform: uppercase;
        text-align: center;
      }

      &:after {
        content: attr(data-unchecked);
        position: absolute;
        z-index: 5;
        text-transform: uppercase;
        text-align: center;
        background: white;
        transform: translate3d(0, 0, 0);
      }
    }
  }

  // Default values for .can-toggle class
  @include can-toggle-branding;
  @include can-toggle-appearance;

  // &.can-toggle--size-large {
  //   @include can-toggle-appearance(
  //     160px,
  //     0px, // Toggle
  //     50px,
  //     px,  // Toggle h
  //     4px,
  //     x,   // Toggle border r
  //     2px,
  //     x,   // Offset (distance btw switch and
  //     14px,
  //     px,  // Label font
  //     14px,
  //     px,  // Switch font
  //     0 4px 4px rgba(black, 0.4) 0.4) // Switch shadow on hover/
  //   );
  // }
}
</style>
