<template>
  <div class="content">
    <!-- 모달창 텍스트내용 -->
    <v-card>
      <v-card-title class="text-h5">
        <h2>정산처리 세부 내역</h2>
      </v-card-title>

      <!-- 모달창 테이블 -->
      <div class="div-table">
        <div class="div-row">
          <div
            class="div-cell"
            v-for="(column, index) in paid_tableColumns"
            :key="index"
          >
            <span>
              {{ column.msg }}
            </span>
          </div>
        </div>
        <!-- <div class="div-row" v-for="(item, index) in paginatedData" :key="index" @click="moveSiteDetail(item.siteId)"> -->
        <div
          class="div-row"
          v-for="(item, index) in paid_tableData"
          :key="index"
        >
          <div
            class="div-cell"
            v-for="(column, index) in paid_tableColumns"
            :key="index"
          >
            <div v-if="column.id == 'use_paid'">
              <span> {{ item.use_paid | numCommaFilter | moneyFilter }}</span>
            </div>
            <div v-else-if="column.id == 'use_pro'">
              <span> {{ item.use_pro | numToTimeFilter }}</span>
            </div>
            <div v-else>
              {{ itemValue(item, column.id) }}
            </div>
          </div>
        </div>
      </div>
      <div class="mijung">
        <h2>미정산</h2>
      </div>
      <div class="div-table">
        <div class="div-row">
          <div
            class="div-cell"
            v-for="(column, index) in mijung_tableColumns"
            :key="index"
          >
            <span>
              {{ column.msg }}
            </span>
          </div>
        </div>
        <!-- <div class="div-row" v-for="(item, index) in paginatedData" :key="index" @click="moveSiteDetail(item.siteId)"> -->
        <div
          class="div-row"
          v-for="(item, index) in mijung_tableData"
          :key="index"
        >
          <div
            class="div-cell"
            v-for="(column, index) in mijung_tableColumns"
            :key="index"
          >
            <div v-if="column.id == 'use_paid'">
              <span> {{ item.use_paid | numCommaFilter | moneyFilter }}</span>
            </div>
            <div v-else-if="column.id == 'use_pro'">
              <span> {{ item.use_pro | numToTimeFilter }}</span>
            </div>
            <div v-else>
              {{ itemValue(item, column.id) }}
            </div>
          </div>
        </div>
      </div>
      <!-- 모달창 테이블 end -->

      <!-- 모달 안쪽 저장 버튼 -->
      <v-card-actions>
        <v-spacer></v-spacer>
        <!-- <v-btn
          class="btn btn-info btn-fill float-right col-1"
          style="color: white"
          text
          @click="dialog = false"
        >
          닫기
        </v-btn> -->
      </v-card-actions>
    </v-card>
  </div>
</template>
<script>
export default {
  components: {},
  data() {
    return {
      site_id: "",
      amount: "",
      // 모달창 안쪽 내역보기 테이블
      paid_tableColumns: [
        { id: "use_No", msg: "NO" },
        { id: "use_plug", msg: "플러그 ID" },
        // { id: "n_plug", msg: "플러그 명" },
        { id: "use_mobile", msg: "사용자 핸드폰" },
        { id: "use_start", msg: "시작 시간" },
        { id: "use_end", msg: "종료 시간" },
        { id: "use_pro", msg: "선택 상품" },
        { id: "use_paid", msg: "결제 금액" },
      ],
      paid_tableData: [],
      // 모달창 안쪽 내역보기 미정산 테이블
      mijung_tableColumns: [
        { id: "use_no", msg: "NO" },
        { id: "use_plug", msg: "플러그 ID" },
        // { id: "n_plug", msg: "플러그 명" },
        { id: "use_mobile", msg: "사용자 핸드폰" },
        { id: "use_start", msg: "시작 시간" },
        { id: "use_end", msg: "종료 시간" },
        { id: "use_pro", msg: "선택 상품" },
        { id: "use_paid", msg: "결제 금액" },
        { id: "comment", msg: "미정산 이유" },
      ],
      mijung_tableData: [],
    };
  },
  async created() {
    this.site_id = this.$route.query.site;
    this.amount = this.$route.query.amount;
    this.bill_id = this.$route.query.bill;
    console.log(this.site_id);
    console.log(this.amount);
    var data = {};
    data.site_id = this.site_id;
    data.amount = this.amount;
    data.bill_id = this.bill_id;

    const response = await this.$store.dispatch("GET_PAID_PLUG_LIST", data);
    console.log(response);
    var used_list = response.data.data.used_list;
    for (var i = 0; i < used_list.length; i++) {
      this.paid_tableData.push({
        use_no: used_list.length - i,
        use_plug: used_list[i].plug_id,
        n_plug: used_list[i].plug_name,
        use_mobile: used_list[i].mobile_number,
        use_start: this.$getDateYYYYMMDD(used_list[i].time_from),
        use_end: this.$getDateYYYYMMDD(used_list[i].time_to),
        use_pro: used_list[i].minute / 60,
        use_paid: used_list[i].charge,
      });
    }
    var missed_list = response.data.data.missed_list;
    for (var i = 0; i < missed_list.length; i++) {
      this.mijung_tableData.push({
        use_no: missed_list.length - i,
        use_plug: missed_list[i].plug_id,
        n_plug: missed_list[i].plug_name,
        use_mobile: missed_list[i].mobile_number,
        use_start: this.$getDateYYYYMMDD(missed_list[i].time_from),
        use_end: this.$getDateYYYYMMDD(missed_list[i].time_to),
        use_pro: missed_list[i].minute / 60,
        use_paid:
          missed_list[i].bill_status === "1"
            ? "-" + missed_list[i].charge
            : missed_list[i].charge,
        comment: missed_list[i].bill_comment,
      });
    }
  },

  methods: {
    itemValue(item, column) {
      return item[column.toLowerCase()];
    },
  },
};
</script>
<style scoped>
.card {
  height: 100%;
}
.search_div {
  padding: 10px 30px 30px 20px;
}
.search_div .search_input_row {
  display: flex;
}
.search_div .search_btn {
  width: 300px;
  margin: auto;
}
.radio_div {
  display: flex;
}
.radio_div div {
  margin-right: 30px;
}
.countData {
  padding: 15px;
}

.detail_div {
  margin: 10px 25px;
}
.table th {
  background: #f2f2f2;
}
.checkbox_div .form-check {
  display: inline-block;
}

.btn_div button {
  margin: 10px 10px;
  width: 100px;
}
.uselog_btn {
  color: black;
}
.mijung {
  padding: 70px 0px 15px 25px;
  font-size: 20px;
}
.sidebar:after,
.sidebar:before,
body > .navbar-collapse:after,
body > .navbar-collapse:before {
  display: none;
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 2;
}
</style>
