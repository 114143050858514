/*!

 =========================================================
 * Vue Light Bootstrap Dashboard - v2.0.0 (Bootstrap 4)
 =========================================================

 * Product Page: http://www.creative-tim.com/product/light-bootstrap-dashboard
 * Copyright 2019 Creative Tim (http://www.creative-tim.com)
 * Licensed under MIT (https://github.com/creativetimofficial/light-bootstrap-dashboard/blob/master/LICENSE.md)

 =========================================================

 * The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

 */
import Vue from "vue";
import VueRouter from "vue-router";
import VueI18n from "vue-i18n";
import App from "./App.vue";
import store from "./vue-store.js";
import Vuetify from "vuetify";
import axiosSetting from "./axiosSetting";

import VueDaumPostcode from "vue-daum-postcode";

import { BootstrapVue, IconsPlugin } from "bootstrap-vue";
//import Paginate from 'vuejs-paginate'

// LightBootstrap plugin
import LightBootstrap from "./light-bootstrap-main";

// router setup
import router from "./routes/routes";

import "expose-loader?$!expose-loader?jQuery!jquery";
import "./registerServiceWorker";

import common from "./common";
import vuetify from "./plugins/vuetify";

// plugin setup
Vue.use(VueRouter);
Vue.use(LightBootstrap);
Vue.use(VueI18n); //다국어 처리

//Vue.component('paginate', Paginate)
//Vue.use(VueDaumPostcode)
Vue.use(BootstrapVue);
Vue.use(IconsPlugin);
//Vue.use(Paginate)

Vue.use(common);

//vuex
Vue.use(store);
// vuetify
Vue.use(Vuetify);
// configure router
// const router = new VueRouter({
//   routes, // short for routes: routes
//   linkActiveClass: 'nav-item active',
//   scrollBehavior: (to) => {
//     if (to.hash) {
//       return {selector: to.hash}
//     } else {
//       return { x: 0, y: 0 }
//     }
//   },
//   mode: "history"
// })

// router.beforeEach((to, from, next) => {
//   if(to.matched.some(record => record.meta.requiresAuth)) {
//     if (store.getters.isLoggedIn) {
//       next()
//       return
//     }
//     next('/login')
//   } else {
//     next()
//   }
// });

// configure i18n(다국어 처리)
const i18n = new VueI18n({
  locale: "ko",
  fallbackLocale: "ko",
  messages: {
    ko: require("../src/locales/ko.json"),
    en: require("../src/locales/en.json"), //현재는 사용X
  },
});

//필터
//숫자 3자리 단위마다 콤마를 찍는다.
Vue.filter("numCommaFilter", function (value) {
  return String(value).replace(/\B(?=(\d{3})+(?!\d))/g, ",");
});
//단위값 '원'을 붙인다.
Vue.filter("moneyFilter", function (value) {
  return value + "원";
});
//숫자를 시간 단위로 변환한다.
Vue.filter("numToTimeFilter", function (value) {
  if (value < 1) {
    return value % 1 == 0 ? "" : "30분";
  } else {
    return Math.floor(value) + "시간 " + (value % 1 == 0 ? "" : "30분");
  }
});

/* eslint-disable no-new */
new Vue({
  el: "#app",
  render: (h) => h(App),
  router,
  i18n,
  store,
  vuetify,
  axiosSetting,
});
