import axios from 'axios'

export default{
  install(Vue){
    // String을 'YYYY-MM-DD HH:mm:ss'의 형태로 리턴한다.
    Vue.prototype.$getDateYYYYMMDD = function(str){
      var time = str? String(str): '';
      var date = '';

      if(time){
          date = time.substr(0,4)+"-"+time.substr(4,2)+"-"+time.substr(6,2)+" "+time.substr(8,2)+":"+time.substr(10,2)+":"+time.substr(12,2);
      }

      return date;
    }

    //string 형을 date 형으로 변환
    Vue.prototype.$getDateFromString = function(str){
      var time = str? String(str): '';
      var date = '';

      if(time){
        date = new Date(time.substr(0,4), time.substr(4,2)-1, time.substr(6,2), time.substr(8,2), time.substr(10,2), time.substr(12,2));
      }

      return date;
    }
    //시작시간과 종료시간의 차이를 구함
    Vue.prototype.$getDuringTime = function(time_from, time_to){
      var date_to = this.$getDateFromString(time_to);
      var date_from = this.$getDateFromString(time_from);                

      var result = ' ';

      if(date_to && date_from) {
      var use_min = (date_to.getTime() - date_from.getTime()) / (1000*60);
      var use_hour = parseInt(use_min/60);
      use_min = use_min%60;

      if(use_hour >= 1) {
        result += use_hour + '시간 ';
      }
        result += use_min + '분';
      }else {
        result = '-';
      }
      
      return result;
    }

    Vue.prototype.$getHourTime = function(time){
      var date = time;           

      var result = ' ';

      if(date) {
      var use_min = (date);
      var use_hour = parseInt(use_min/60);
      use_min = use_min%60;

      if(use_hour >= 1) {
        result += use_hour + '시간 ';
      }
        result += use_min + '분';
      }else {
        result = '-';
      }
      
      return result;
    }
  }
}