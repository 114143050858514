<template>
  <div class="content" v-if="this.change_access === 'y'">
    <div class="row">
      <div class="col-6">
        <card
          class="strpied-tabled-with-hover"
          body-classes="table-full-width table-responsive"
        >
          <template slot="header">
            <h4 class="card-title">정산 검색</h4>
          </template>

          <p class="p1">검색기간</p>
          <!-- <base-radio name="chk_usetime" id="chk_usetime_off" v-model="testData" text="월별" ></base-radio>
                  <base-radio name="chk_usetime" id="chk_usetime_off" v-model="testData" text="분기별"></base-radio> -->
          <div class="selbox">
            <v-checkbox
              v-model="checkbox.checked"
              :label="`월별`"
              value="month"
              @change="check_one()"
              v-on:click="check_one()"
              class="monthck"
            ></v-checkbox>
            <v-checkbox
              v-model="checkbox.checked"
              :label="`분기별`"
              value="quarter"
              @change="check_one()"
              v-on:click="check_one()"
              class="quarck"
            ></v-checkbox>
          </div>

          <!-- month picker -->
          <div class="month_pic">
            <div id="paid" :style="status.required.style">
              <date-picker
                v-model="range"
                :lang="lang"
                range
                type="month"
                format="YYYY-MM"
                utc
              ></date-picker>
            </div>
            <div :style="status.required2.style">
              <date-picker
                v-model="date"
                :lang="lang"
                type="year"
                format="YYYY"
              ></date-picker>
              <select v-model="quarterFrom" class="q_select">
                <option value="">분기</option>
                <option value="01-01">1Q</option>
                <option value="04-01">2Q</option>
                <option value="07-01">3Q</option>
                <option value="10-01">4Q</option>
              </select>
              <div class="qa">
                <div>
                  <a>~</a>
                </div>

                <select v-model="quarterTo" class="q_select_1">
                  <option value="">분기</option>
                  <option value="03-31">1Q</option>
                  <option value="06-30">2Q</option>
                  <option value="09-30">3Q</option>
                  <option value="12-31">4Q</option>
                </select>
              </div>
            </div>
            <button
              class="btn btn-fill btn-primary btn-block search_btn"
              type="button"
              @click="payResult()"
            >
              검색
            </button>
          </div>

          <div class="div-excelBtn" @click="exportExcel()">
            <div>
              <label class="label-excelBtn">
                <img
                  class="excel"
                  src="~@/assets/imgs/excel/Excel_icon-09.png"
                />

                다운로드</label
              >
            </div>
          </div>

          <div class="div-table">
            <div class="div-row">
              <div
                class="div-cell"
                v-for="column in tableColumns"
                :key="column.id"
                style="background-color: #dee2e6"
              >
                <span>
                  {{ column.msg }}
                </span>
              </div>
            </div>
            <!-- <div class="div-row" v-for="(item, index) in paginatedData" :key="index" @click="moveSiteDetail(item.siteId)"> -->
            <div
              class="div-row"
              v-for="(item, index) in searchData"
              :key="index.id"
            >
              <div
                class="div-cell"
                v-for="column in tableColumns"
                :key="column.id"
              >
                <div v-if="column.id == 'money'">
                  <span> {{ item.money | comma | moneyFilter }}</span>
                </div>
                <div v-else-if="column.id == 'paid'">
                  <span> {{ item.paid.toFixed(0) | comma | moneyFilter }}</span>
                </div>
                <div v-else-if="column.id == 'rain_money'">
                  <span>
                    {{ item.rain_money.toFixed(0) | comma | moneyFilter }}</span
                  >
                </div>
                <div v-else>
                  {{ itemValue(item, column.id) }}
                </div>
              </div>
            </div>
          </div>
        </card>
      </div>
    </div>
    <!-- //row -->
  </div>
  <div v-else>
    <card>
      <h1>해당 계정은 [정산 관리] 페이지에 접근 권한이 없습니다.</h1>
    </card>
  </div>
</template>
<script>
import DatePicker from "vue2-datepicker";
import Card from "src/components/Cards/Card.vue";
import XLSX from "xlsx";

export default {
  filters: {
    comma(val) {
      return String(val).replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    },
  },
  components: {
    Card,
    DatePicker,
  },

  data() {
    return {
      // 분기별 데이터
      quarterFrom: "",
      quarterTo: "",
      change_access: "",

      // 테이블 컬럼
      tableColumns: [
        { id: "date", msg: "검색 기간" },
        { id: "site_num", msg: "정산 사이트 수" },
        { id: "plug_num", msg: "사용 플러그 수" },
        { id: "missed_plug", msg: "정산 미반영 수" },
        { id: "money", msg: "결제 금액" },
        { id: "paid", msg: "정산 금액" },
        { id: "rain_money", msg: "레인써클 수익" },
      ],

      // 검색 데이터
      searchData: [
        //todo 단위, 숫자에 콤마 붙이는 것 개발 필요
      ],
      date: "",

      // 날짜 데이터
      range: "",
      lang: {
        months: ["1", "2", "3", "4", "5", "6", "7", "8", "9", "10", "11", "12"],
      },

      // 체크박스 데이터
      checkbox: [
        { value: "month", checked: false, disabled: false },
        { value: "quarter", checked: false, disabled: false },
      ],

      // 분기 스타일
      style: {
        search: "display:block",
        quarter: "display:none",
        search2: "display:block",
        quarter2: "display:none",
      },

      status: {
        required: { style: "" },
        required2: { style: "" },
      },
    };
  },
  watch: {},
  created() {
    // 페이지 시작시 월별 체크되어 있도록 설정
    if ((this.checkbox.checked = "month")) {
      this.status.required.style = this.style.search;
      this.status.required2.style = this.style.quarter2;
    }
    if (sessionStorage.getItem("login") !== null) {
      this.change_access =
        this.$store.state.get_admin_detail.admin_user_profile.access.slice(
          3,
          4
        );
    }
  },
  methods: {
    itemValue(item, column) {
      return item[column.toLowerCase()];
    },

    // 월별 쿼터 동시선택 되지 않도록 설정
    check_one: function () {
      if (this.checkbox.checked == "quarter") {
        this.status.required.style = this.style.quarter;
        this.status.required2.style = this.style.search2;
      } else {
        this.checkbox.checked == "month";
        this.status.required.style = this.style.search;
        this.status.required2.style = this.style.quarter2;
      }
    },

    // 정산 결과
    async payResult() {
      var date = this.range;
      if (date) {
        var dateFrom =
          date[0].getFullYear() +
          "-" +
          ("0" + (date[0].getMonth() + 1)).slice(-2) +
          "-" +
          ("0" + date[0].getDate()).slice(-2);
        var dateTo =
          date[1].getFullYear() +
          "-" +
          ("0" + (date[1].getMonth() + 1)).slice(-2) +
          "-" +
          ("0" + date[1].getDate()).slice(-2);
      } else {
        var dateFrom = null;
        var dateTo = null;
      }

      if (this.date) {
        var qYear = this.date.getFullYear();
      } else {
        var qYear = null;
      }
      var data = {};
      data.dateFrom = dateFrom;
      data.dateTo = dateTo;
      data.id = this.checkbox.checked;
      data.qFrom = qYear + "-" + this.quarterFrom;
      data.qTo = qYear + "-" + this.quarterTo;

      if (this.checkbox.checked === null) {
        alert("검색 기준을 선택하세요");
      } else if (this.checkbox.checked === "month") {
        if (this.range === "") {
          alert("날짜를 선택하세요");
        } else {
          const response = await this.$store.dispatch(
            "FIND_BILLING_RESULT",
            data
          );
          console.log(response);
          var list = response.data.data.find_list;
          if (!list || list.length === 0) {
            this.tableData = [];
            list = [];
            // alert("검색된 데이터가 없습니다. 다시 검색하시기 바랍니다.");
          }
          this.searchData = [];
          for (var item of list) {
            var temp = {
              //plug info
              date: item.year + "." + item.month,
              site_num: item.site,
              plug_num: item.plug,
              missed_plug: item.missed_plug,
              money: item.money,
              paid: item.money * 0.9,
              rain_money: item.money - item.money * 0.9,
            };
            this.searchData.push(temp);
          }
        }
      } else if (this.checkbox.checked === "quarter") {
        if (this.date === "") {
          alert("년을 선택하세요");
        } else if (this.quarterFrom === "") {
          alert("시작 분기를 선택하세요");
        } else if (this.quarterTo === "") {
          alert("끝 분기를 선택하세요");
        } else {
          const response = await this.$store.dispatch(
            "FIND_BILLING_RESULT",
            data
          );
          console.log(response);
          var list = response.data.data.find_list;
          if (!list || list.length === 0) {
            // alert("검색된 데이터가 없습니다. 다시 검색하시기 바랍니다.");
            this.tableData = [];
            list = [];
          }
          this.searchData = [];
          for (var item of list) {
            var temp = {
              //plug info
              date: item.year + "." + item.quarter + "Q",
              site_num: item.site,
              plug_num: item.plug,
              missed_plug: item.missed_plug,
              money: item.money,
              paid: item.money * 0.9,
              rain_money: item.money - item.money * 0.9,
            };
            this.searchData.push(temp);
          }
        }
      } else {
      }
    },

    // 엑셀 다운로드
    exportExcel() {
      var today = new Date();
      var today_date =
        today.getFullYear() +
        "" +
        ("0" + (today.getMonth() + 1)).slice(-2) +
        "" +
        ("0" + today.getDate()).slice(-2);
      var today_time =
        ("0" + today.getHours()).slice(-2) +
        "" +
        ("0" + today.getMinutes()).slice(-2) +
        "" +
        ("0" + today.getSeconds()).slice(-2);

      const wb = XLSX.utils.book_new(); //엑셀 파일 생성(workbook)
      var ws_data = [
        ["추출일", this.$getDateYYYYMMDD(today_date + today_time)],
        [],
      ];

      //header setting
      var temp = [];
      var i = 0;
      for (var header of this.tableColumns) {
        if (header.id == "selbox") {
          continue;
        }
        temp[i] = header.msg;
        i++;
      }
      ws_data.push(temp);

      //data setting
      for (var row of this.searchData) {
        temp = [];
        i = 0;
        for (var header of this.tableColumns) {
          if (header.id == "selbox") {
            continue;
          }
          temp[i] = this.itemValue(row, header.id);
          i++;
        }
        ws_data.push(temp);
      }

      var ws = XLSX.utils.aoa_to_sheet(ws_data);
      const workseet = XLSX.utils.book_append_sheet(wb, ws, "Sheet1");

      //엑셀 파일 명
      var fileName =
        "정산 검색_" + today_date + "_" + today_time + "_" + ".xlsx";
      XLSX.writeFile(wb, fileName); //엑셀 다운로드
    },
  },
};
</script>
<style scoped>
.card {
  height: 815px;
  width: 1000px;
}
.search_div {
  padding: 10px 30px 30px 20px;
}
.search_div .search_input_row {
  display: flex;
}

.radio_div {
  display: flex;
  padding-left: 50px;
}
.radio_div div {
  margin-right: 40px;
  border-radius: 4px;
}
.countData {
  padding: 15px;
}

.detail_div {
  margin: 10px 25px;
}
.table th {
  background: #f2f2f2;
}
.checkbox_div .form-check {
  display: inline-block;
}

.btn_div button {
  margin: 10px 10px;
  width: 100px;
}
.uselog_btn {
  color: black;
}

.btn-block {
  width: 200px;
  margin: 5% 0 2% 41%;
}
.col-2 {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 16.6666666667%;
  flex: 0 0 16.6666666667%;
  max-width: 15%;
}
.form-check {
  position: relative;
  display: block;
  padding-left: 1.25rem;
  margin-top: 10px;
}
.month_pic {
  width: 250px;
  margin: -50px 0 0 350px;
}
.q_select {
  display: block;
  width: 70px;
  font-size: 14px;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #4388c1;
  border-radius: 0px;
  text-align: center;
  height: 35px;
  position: absolute;
  margin-top: 15px;
}
.q_select_1 {
  display: block;
  width: 70px;
  font-size: 14px;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #4388c1;
  border-radius: 0px;
  text-align: center;
  height: 35px;
  position: absolute;
  margin-left: 30px;
  margin-top: -28px;
}
.qa {
  margin-left: 90px;
  margin-top: 20px;
}
.excel {
  height: 23px;
}
.p1 {
  margin: 20px 0 0 20px;
}
.search_btn {
  width: 70px;
  font-weight: 400;
  background-color: #3195cf;
  border: 1px solid #4388c1;
  color: white;
  height: 35px;
  font-size: 13px;
  margin: -34px 0 100px 280px;
}
.monthck {
  margin: -27px 0 0 150px;
}
.quarck {
  margin: -50px 0 0 230px;
}
.selbox {
  width: 292px;
}
</style>
