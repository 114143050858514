<template>
  <div class="content">
    <div class="row">
      <div class="col-6">
        <card
          class="strpied-tabled-with-hover"
          body-classes="table-full-width table-responsive"
        >
          <template slot="header">
            <h4 class="card-title">운영자</h4>
          </template>
          <div class="search_div">
            <div class="search_input_row">
              <p class="p1">검색 기준</p>
              <base-select-search
                type="text"
                class="text-center selput seletbox"
                :items="searchOptionList"
                v-model="searchOptionList.id"
              ></base-select-search>
              <base-input
                v-model="searchInput"
                type="find"
                placeholder=""
                @keypress="enterkey()"
                class="inputbox"
              />
              <button
                class="btn btn-fill btn-primary btn-block search_btn"
                type="button"
                @click="findUser()"
              >
                <a style="white-space: nowrap">검색</a>
              </button>
            </div>
          </div>
          <div class="search_result_div">
            <div v-if="searchData.length > 0">
              <div class="countData">총 {{ totalNum }} 명</div>
              <div class="div-table">
                <div class="div-row">
                  <div
                    class="div-cell"
                    v-for="column in tableColumns"
                    :key="column.id"
                  >
                    <span>
                      {{ column.msg }}
                    </span>
                  </div>
                </div>
                <!-- <div class="div-row" v-for="(item, index) in paginatedData" :key="index" @click="moveSiteDetail(item.siteId)"> -->
                <div
                  class="div-row"
                  v-for="(item, index) in searchData"
                  :key="index.id"
                  @click="findUserDetail(index)"
                  :class="{ clickedRow: index === clickRowIndex }"
                >
                  <div
                    class="div-cell"
                    v-for="column in tableColumns"
                    :key="column.id"
                  >
                    {{ itemValue(item, column.id) }}
                  </div>
                </div>
              </div>
              <div class="paging-cover">
                <b-pagination
                  class="mt-3"
                  v-model="pageNum"
                  :total-rows="totalNum"
                  :per-page="pages.pageSize"
                  @page-click="pageClickFind"
                ></b-pagination>
              </div>
            </div>
            <div v-else-if="tableData.length > 0">
              <div class="countData">총 {{ totalNum }} 명</div>
              <div class="div-table">
                <div class="div-row">
                  <div
                    class="div-cell"
                    v-for="column in tableColumns"
                    :key="column.id"
                  >
                    <span>
                      {{ column.msg }}
                    </span>
                  </div>
                </div>
                <!-- <div class="div-row" v-for="(item, index) in paginatedData" :key="index" @click="moveSiteDetail(item.siteId)"> -->
                <div
                  class="div-row"
                  v-for="(item, index) in tableData"
                  :key="index.id"
                  @click="getDetail(index)"
                  :class="{ clickedRow: index === clickRowIndex }"
                >
                  <div
                    class="div-cell"
                    v-for="column in tableColumns"
                    :key="column.id"
                  >
                    {{ itemValue(item, column.id) }}
                  </div>
                </div>
              </div>
              <div class="paging-cover">
                <b-pagination
                  class="mt-3"
                  v-model="pageNum"
                  :total-rows="totalNum"
                  :per-page="pages.pageSize"
                  @page-click="pageClick"
                ></b-pagination>
              </div>
            </div>
            <div v-else>
              <div class="emptyContent">표시할 데이터가 없습니다.</div>
            </div>
          </div>
        </card>
      </div>

      <div class="col-6">
        <card
          class="strpied-tabled-with-hover"
          body-classes="table-full-width table-responsive"
        >
          <template slot="header">
            <h4 class="card-title">운영자 상세</h4>
          </template>
          <div class="detail_div">
            <div v-if="clickRowIndex >= 0">
              <table class="table">
                <tr>
                  <th>ID</th>
                  <td>
                    <base-input
                      type="email"
                      placeholder="이메일"
                      v-model="user.email"
                      disabled
                    />
                  </td>
                </tr>
                <tr>
                  <th>비밀번호</th>
                  <td class="td_pw">
                    <base-input
                      type="password"
                      placeholder="비밀번호"
                      v-model="user.password"
                    />
                    <div
                      v-show="
                        this.change_permission === 'true' ||
                        this.my_id === this.user.id
                      "
                    >
                      <button
                        @click.prevent="modify_pass()"
                        class="btn btn-fill btn-primary btn-block btn_blue form-group"
                      >
                        비밀번호 변경
                      </button>
                    </div>
                  </td>
                </tr>
                <tr>
                  <th>이름</th>
                  <td>
                    <base-input
                      type="text"
                      placeholder="이름"
                      v-model="user.name"
                    />
                  </td>
                </tr>
                <tr>
                  <th>휴대폰 번호</th>
                  <td>
                    <base-input
                      type="text"
                      placeholder="휴대폰 번호"
                      v-model="user.mob_number"
                    />
                  </td>
                </tr>
                <tr>
                  <th>팀</th>
                  <td>
                    <base-input
                      type="text"
                      placeholder="팀"
                      v-model="user.team"
                    />
                  </td>
                </tr>
                <tr>
                  <th>권한</th>
                  <td>
                    <div class="can-toggle can-toggle--size-large">
                      <input
                        id="c"
                        type="checkbox"
                        v-model="user.permission"
                        :disabled="disabled"
                      />
                      <label for="c">
                        <div
                          ref="ticket_flag"
                          class="can-toggle__switch"
                          data-checked="사용"
                          data-unchecked="미사용"
                          @click="clickTicketFlag()"
                        ></div>
                      </label>
                    </div>
                  </td>
                </tr>
                <tr>
                  <th>접근</th>
                  <td>
                    <div
                      class="checkbox_div"
                      v-for="item in user.access"
                      :key="item.id"
                    >
                      <input
                        type="checkbox"
                        :disabled="disabled"
                        class="mr-2"
                        id="box"
                        v-model="item.check"
                      />{{ item.name }}
                    </div>
                  </td>
                </tr>
              </table>

              <div class="btn_div text-center">
                <button
                  v-show="this.change_permission === 'true'"
                  type="submit"
                  class="btn btn-fill btn-primary"
                  id="saveBtn"
                  @click.prevent="modifying()"
                >
                  수정
                </button>
                <button
                  v-show="this.change_permission === 'true'"
                  type="submit"
                  class="btn btn-danger btn-fill"
                  id="deleteBtn"
                  @click.prevent="DeleteBtn()"
                >
                  삭제
                </button>
              </div>
            </div>
            <div v-else>
              <div class="emptyContent">
                상세 보기 할 데이터를 클릭해 주세요.
              </div>
            </div>
          </div>
        </card>
      </div>
    </div>
  </div>
</template>
<script>
import { exit } from "process";
import Card from "src/components/Cards/Card.vue";
import BaseInput from "src/components/Inputs/BaseInput.vue";

export default {
  components: {
    Card,
    BaseInput,
  },
  data() {
    return {
      // 검색 인풋박스 데이터
      searchInput: "",

      // id 클릭 못 하게 막음
      disabled: false,

      // 삭제 버튼
      delBtn: {
        admin_status: "",
        updated: "",
      },

      // 페이지 갯수
      pages: { page: 1, pageSize: 15 },

      // 페이징 넘버
      pageNum: 0,

      // 페이징 전체 넘버
      totalNum: 0,

      // 어드민 유저 데이터
      user: {
        id: "",
        name: "",
        mob_number: "",
        password: "",
        email: "",
        team: "",
        admin_status: "",
        updated: "",
        permission: "",
        access: [
          { name: "대시보드", check: false },
          { name: "고객 관리", check: false },
          { name: "운영 관리", check: false },
          { name: "정산 관리", check: false },
        ],
        value: "",
      },

      // 검색 기준 옵션
      searchOptionList: [
        { id: "all", name: "전체" },
        { id: "id", name: "ID" },
        { id: "name", name: "이름" },
        { id: "team", name: "팀" },
        { id: "phone", name: "휴대폰번호" },
      ],

      // 메인 테이블 데이터 컬럼
      tableColumns: [
        { id: "id", msg: "ID" },
        { id: "name", msg: "이름" },
        { id: "team", msg: "팀" },
        { id: "phone", msg: "휴대폰 번호" },
        { id: "authority", msg: "권한" },
      ],

      // 메인 테이블 데이터
      tableData: [],

      // 검색 데이터
      searchData: [],

      change_permission: "",
      my_id: "",
      clickRowIndex: -1,
    };
  },
  async created() {
    if (sessionStorage.getItem("login") !== null) {
      this.change_permission =
        this.$store.state.get_admin_detail.admin_user_profile.permission;
      this.my_id =
        this.$store.state.get_admin_detail.admin_user_profile.admin_id;
    }
    console.log(this.change_permission);
    if (this.change_permission !== "") {
      this.getAdminList();
    }
  },
  computed: {
    pageCount() {
      //전체 페이지 수 count
      let listLeng = this.totalNum,
        listSize = this.pages.pageSize,
        page = Math.floor(listLeng / listSize);

      if (listLeng % listSize > 0) page += 1;
      return page;
    },
  },
  methods: {
    enterkey() {
      if (window.event.keyCode == 13) {
        this.findUser();
      }
    },
    /* 페이징 클릭 시 */
    pageClick(button, page) {
      this.getAdminList(page);
    },
    pageClickFind(button, page) {
      this.findUser(page);
    },

    // 어드민 유저 검색
    async findUser(page) {
      this.pageNum = page ? page : 1;
      this.pages.page = this.pageNum;
      if (this.searchOptionList.id === undefined) {
        this.searchOptionList.id = "all";
      }
      var data = {};
      data.id = this.searchOptionList.id;
      data.search = this.searchInput;
      data.page = this.pages.page;
      data.lines = this.pages.pageSize;

      const response = await this.$store.dispatch("FIND_ADMIN", data);
      console.log(response);

      var list = response.data.data.find_list;
      this.totalNum = response.data.data.total;

      if (!list || list.length === 0) {
        // alert("검색된 데이터가 없습니다. 다시 검색하시기 바랍니다.");
        this.tableData = [];
        list = [];
      }
      this.searchData = [];
      for (var item of list) {
        var temp = {
          user_id: item.user_id,
          id: item.email,
          name: item.name,
          team: item.team,
          phone: item.mob_number,
          authority: item.permission == "true" ? "O" : "X",
          access: item.access,
        };
        this.searchData.push(temp);
      }
      this.clickRowIndex = -1;
    },

    // 권한 부여
    clickTicketFlag(index) {
      this.permission = this.tableData[index].user_id;
      this.permission = !this.permission;
    },
    itemValue(item, column) {
      return item[column.toLowerCase()];
    },

    // 메인 테이블 데이터 상세정보
    getDetail(index) {
      this.clickRowIndex = index;
      this.user.email = this.tableData[index].id;
      this.user.name = this.tableData[index].name;
      this.user.mob_number = this.tableData[index].phone;
      this.user.team = this.tableData[index].team;
      this.user.id = this.tableData[index].user_id;
      this.user.access[0].check =
        this.tableData[index].access.slice(0, 1) == "y" ? true : false;
      this.user.access[1].check =
        this.tableData[index].access.slice(1, 2) == "y" ? true : false;
      this.user.access[2].check =
        this.tableData[index].access.slice(2, 3) == "y" ? true : false;
      this.user.access[3].check =
        this.tableData[index].access.slice(3, 4) == "y" ? true : false;
      this.user.permission =
        this.tableData[index].authority === "O" ? true : false;

      console.log(this.change_permission);
      // if (this.change_permission === "false") {
      //   document.getElementById("c").disabled = true;
      //   this.disabled = true;

      //   //disable not working with checkboxes
      //   // document.getElementsByTagName(".base-checkbox").disabled = true;
      // }
    },

    // 검색 데이터 상세정보
    findUserDetail(index) {
      this.clickRowIndex = index;
      this.user.email = this.searchData[index].id;
      this.user.name = this.searchData[index].name;
      this.user.mob_number = this.searchData[index].phone;
      this.user.team = this.searchData[index].team;
      this.user.id = this.searchData[index].user_id;
      this.user.access[0].check =
        this.searchData[index].access.slice(0, 1) == "y" ? true : false;
      this.user.access[1].check =
        this.searchData[index].access.slice(1, 2) == "y" ? true : false;
      this.user.access[2].check =
        this.searchData[index].access.slice(2, 3) == "y" ? true : false;
      this.user.access[3].check =
        this.searchData[index].access.slice(3, 4) == "y" ? true : false;
      this.user.permission =
        this.searchData[index].authority === "O" ? true : false;

      if (this.change_permission === "false") {
        document.getElementById("c").disabled = true;
        // document.getElementById("box").disabled = true;

        //disable not working with checkboxes
        document.getElementsByTagName(".base-checkbox").disabled = true;
      }
    },

    //비밀번호 변경 function
    async modify_pass() {
      var data = {};
      data.id = this.user.id;
      data.pass = this.user.password;
      console.log(data);
      if (this.user.password === "") {
        alert("비밀번호 입력하세요");
      } else {
        const response = await this.$store.dispatch("UPDATE_PASSWORD", data);
        if (response.data.result === true) {
          alert("비밀번호 변경이 완료되었습니다.");
          window.location.reload();
        } else {
          alert("some error");
        }
        console.log(response);
      }
    },

    //정보 수정 function
    async modifying() {
      var value = "";
      for (var i = 0; i < 4; i++) {
        if (this.user.access[i].check === true) {
          value = value + "y";
        } else {
          value = value + "n";
        }
      }
      this.user.value = value;

      const modifying = new Object();
      modifying.id = this.user.id;
      modifying.name = this.user.name;
      modifying.mob_number = this.user.mob_number;
      modifying.team = this.user.team;
      modifying.permission = this.user.permission;
      modifying.access = this.user.value;

      if (confirm("수정하는 것이 맞습니까?") == true) {
        const response = await this.$store.dispatch(
          "UPDATE_ADMIN_USER",
          modifying
        );
        console.log(response.data);

        if (response.data.result === true) {
          window.location.reload();
        } else {
          alert("정상적으로 처리되지 않았습니다. 개발팀에 문의하세요.");
        }
        console.log(modifying);
      }
    },

    // 계정 삭제 버튼 기능
    async DeleteBtn() {
      console.log(this.user.id);
      var user_id = this.user.id;
      if (confirm("해당 운영자를 삭제하는 것이 맞습니까?") == true) {
        const response = await this.$store.dispatch(
          "DELETE_ADMIN_USER",
          user_id
        );
        console.log(response.data);
        if (response.data.result === true) {
          window.location.reload();
        } else {
          alert("정상적으로 처리되지 않았습니다. 개발팀에 문의하세요.");
        }
      }
    },

    // 어드민유저 리스트
    async getAdminList(page) {
      this.pageNum = page ? page : 1;
      this.pages.page = this.pageNum;

      const response = await this.$store.dispatch(
        "FETCH_GET_ADMIN_LIST",
        this.pages
      );
      console.log(response.data);

      var list = response.data.data.user_list;
      this.totalNum = response.data.data.total;
      this.tableData = [];

      for (var item of list) {
        var temp = {
          user_id: item.user_id,
          id: item.email,
          name: item.name,
          team: item.team,
          phone: item.mob_number,
          authority: item.permission == "true" ? "O" : "X",
          access: item.access,
          admin_status: item.admin_status,
        };
        this.tableData.push(temp);
      }

      this.clickRowIndex = -1;
    },
  },
};
</script>
<style scoped>
.search_div {
  padding: 10px 30px 30px 20px;
}
.search_div .search_input_row {
  margin: 20px 0 0 0;

  display: flex;
}
.search_div .search_btn {
  width: 70px;
  font-weight: 400;
  background-color: #3195cf;
  border: 1px solid #4388c1;
  color: white;
  height: 35px;
  font-size: 13px;
}
.countData {
  padding: 15px;
}

.detail_div {
  margin: 10px 25px;
}
.table th {
  background: #f2f2f2;
}
.checkbox_div .form-check {
  display: inline;
}

.btn_div button {
  margin: 10px 10px;
  width: 100px;
}
.p1 {
  margin: 5px 0 0 0;
  white-space: nowrap;
}
.seletbox {
  margin: 0 20px 0 0;
}
.inputbox {
  margin: 0 20px 2% 0;
  width: 50%;
  position: relative;
}
.detail_div .form-group {
  margin: 10px 0;
}
.td_pw {
  display: flex;
}
.td_pw .form-group {
  flex: auto;
}
.td_pw .btn {
  width: 120px;
  height: 35px;
  margin-left: 10px;
}
</style>
