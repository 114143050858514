<template>
  <div class="content" v-if="this.change_access === 'y'">
    <div class="row">
      <div class="col-6">
        <card
          class="strpied-tabled-with-hover"
          body-classes="table-full-width table-responsive"
        >
          <template slot="header">
            <h4 class="card-title">정산 결과</h4>
          </template>
          <div class="search_div">
            <div class="search_input_row">
              <p class="p1">검색 기준</p>
              <base-select-search
                type="text"
                class="text-center selectInput seletbox"
                :items="searchOptionList"
                v-model="searchOptionList.id"
              ></base-select-search>
              <base-input
                type="text"
                placeholder=""
                v-model="searchInput"
                @keypress="enterkey()"
                class="inputbox"
              />
              <button
                class="btn btn-fill btn-primary btn-block search_btn"
                type="button"
                @click="findResult()"
              >
                <a style="white-space: nowrap">검색</a>
              </button>
            </div>
          </div>

          <div class="div-excelBtn" @click="exportExcel()">
            <div>
              <label class="label-excelBtn">
                <img
                  class="excel"
                  src="~@/assets/imgs/excel/Excel_icon-09.png"
                />

                다운로드</label
              >
            </div>
          </div>

          <div class="div-table">
            <div class="div-row">
              <div
                class="div-cell"
                v-for="column in TotalColumns"
                :key="column.id"
                style="background-color: #dee2e6"
              >
                <span>
                  {{ column.msg }}
                </span>
              </div>
            </div>
            <!-- <div class="div-row" v-for="(item, index) in paginatedData" :key="index" @click="moveSiteDetail(item.siteId)"> -->
            <div
              class="div-row"
              v-for="(item, index) in SiteTotal"
              :key="index.id"
            >
              <div
                class="div-cell"
                v-for="column in TotalColumns"
                :key="column.id"
              >
                <div v-if="column.id == 'id'">
                  {{ itemValue(item, column.id) | comma }}
                </div>
                <div v-else>
                  {{ itemValue(item, column.id) | comma | moneyFilter }}
                </div>
              </div>
            </div>
          </div>

          <!-- <site-table class="table-hover table-striped"></site-table> -->
          <div class="search_result_div">
            <div v-if="searchData.length > 0">
              <div class="countData">총 {{ searchData.length }} 사이트</div>
              <div class="div-table">
                <div class="div-row">
                  <div
                    class="div-cell"
                    v-for="column in searchColumns"
                    :key="column.id"
                  >
                    <span>
                      {{ column.msg }}
                    </span>
                  </div>
                </div>
                <!-- <div class="div-row" v-for="(item, index) in paginatedData" :key="index" @click="moveSiteDetail(item.siteId)"> -->
                <div
                  class="div-row"
                  v-for="(item, index) in searchData"
                  :key="index.id"
                  @click="findBillDetail(index)"
                  :class="{ clickedRow: index === clickRowIndex }"
                >
                  <div
                    class="div-cell"
                    v-for="column in searchColumns"
                    :key="column.id"
                  >
                    <div v-if="column.id == 'sum_amount'">
                      <span>
                        {{
                          item.sum_amount | numCommaFilter | moneyFilter
                        }}</span
                      >
                    </div>
                    <div v-else>
                      {{ itemValue(item, column.id) }}
                    </div>
                  </div>
                </div>
              </div>
              <div class="paging-cover">
                <b-pagination
                  class="mt-3"
                  v-model="pageNum"
                  :total-rows="totalNum"
                  :per-page="pages.pageSize"
                  @page-click="pageClickFind"
                ></b-pagination>
              </div>
            </div>
            <div v-else-if="tableData.length > 0">
              <div class="countData">
                총 {{ tableData.length | comma }} 사이트
              </div>
              <div class="div-table">
                <div class="div-row">
                  <div
                    class="div-cell"
                    v-for="column in tableColumns"
                    :key="column.id"
                  >
                    <span>
                      {{ column.msg }}
                    </span>
                  </div>
                </div>
                <!-- <div class="div-row" v-for="(item, index) in paginatedData" :key="index" @click="moveSiteDetail(item.siteId)"> -->
                <div
                  class="div-row"
                  v-for="(item, index) in tableData"
                  :key="index.id"
                  @click="getDetail(index)"
                  :class="{ clickedRow: index === clickRowIndex }"
                >
                  <div
                    class="div-cell"
                    v-for="column in tableColumns"
                    :key="column.id"
                  >
                    <div v-if="column.id == 'sum_amount'">
                      <span>
                        {{
                          item.sum_amount | numCommaFilter | moneyFilter
                        }}</span
                      >
                    </div>
                    <div v-else>
                      {{ itemValue(item, column.id) }}
                    </div>
                  </div>
                </div>
              </div>
              <div class="paging-cover">
                <b-pagination
                  class="mt-3"
                  v-model="pageNum"
                  :total-rows="totalNum"
                  :per-page="pages.pageSize"
                  @page-click="pageClick"
                ></b-pagination>
              </div>
            </div>
            <div v-else>
              <div class="emptyContent">표시할 데이터가 없습니다.</div>
            </div>
          </div>
        </card>
      </div>

      <div class="col-6">
        <card
          class="strpied-tabled-with-hover"
          body-classes="table-full-width table-responsive"
        >
          <template slot="header">
            <h4 class="card-title">건물 관리자 상세</h4>
          </template>
          <div class="detail_div">
            <div v-if="clickRowIndex >= 0">
              <table class="table">
                <tr>
                  <th>사이트 ID</th>
                  <td>{{ this.site.id }}</td>
                </tr>
                <tr>
                  <th>사이트명</th>
                  <td>{{ this.site.name }}</td>
                </tr>
                <tr>
                  <th>건물 관리자 ID</th>
                  <td>{{ this.site.provider_email }}</td>
                </tr>
                <tr>
                  <th>건물 관리자 이름</th>
                  <td>{{ this.site.provider_name }}</td>
                </tr>
                <tr>
                  <th>핸드폰번호</th>
                  <td>{{ this.site.mob_number }}</td>
                </tr>
                <tr>
                  <th>사이트 등록일</th>
                  <td>{{ this.site.site_created }}</td>
                </tr>
                <tr>
                  <th>정산 주기</th>
                  <td>
                    {{
                      this.number
                        ? this.number[0].bill_sett_time === 4
                          ? "1개월"
                          : this.number[0].bill_sett_time === 3
                          ? "3주"
                          : this.number[0].bill_sett_time === 2
                          ? "2주"
                          : this.number[0].bill_sett_time === 1
                          ? "1주"
                          : this.number[0].bill_sett_time === 5
                          ? "2개월"
                          : null
                        : 0
                    }}
                  </td>
                </tr>
                <tr>
                  <th>총 정산 횟수</th>
                  <td>{{ this.site.record + "회" }}</td>
                </tr>
                <tr>
                  <th>최종 정산일</th>
                  <td>{{ this.site.last_created }}</td>
                </tr>
                <tr>
                  <th>누적 결제 금액</th>
                  <td>{{ this.site.pay_amount | comma | moneyFilter }}</td>
                </tr>
                <tr>
                  <th>누적 정산 금액</th>
                  <td>{{ this.site.bill_amount | comma | moneyFilter }}</td>
                </tr>
                <tr>
                  <th>세부 내역</th>
                  <td>
                    <!-- 사용 내역 모달창 버튼 -->

                    <div id="User_List" data-app>
                      <v-row justify="center">
                        <v-dialog
                          class="m_dialog"
                          v-model="dialog"
                          persistent
                          max-width="80%"
                        >
                          <template v-slot:activator="{ on, attrs }">
                            <button
                              v-bind="attrs"
                              v-on="on"
                              class="btn btn-outline btn-wd btn-info uselog_btn"
                            >
                              내역 보기
                            </button>
                          </template>

                          <!-- 모달창 텍스트내용 -->
                          <v-card style="height: 80%">
                            <v-card-title class="text-h5">
                              <h3>정산 세부 내역</h3>
                            </v-card-title>
                            <!-- 모달창 테이블 -->
                            <div class="div-table">
                              <div class="div-row">
                                <div
                                  class="div-cell"
                                  v-for="column in popup_tableColumns"
                                  :key="column.id"
                                  @click="getDetail()"
                                >
                                  <span>
                                    {{ column.msg }}
                                  </span>
                                </div>
                              </div>
                              <!-- <div class="div-row" v-for="(item, index) in paginatedData" :key="index" @click="moveSiteDetail(item.siteId)"> -->
                              <div
                                class="div-row"
                                v-for="(item, index) in popup_tableData"
                                :key="index.id"
                              >
                                <div
                                  class="div-cell"
                                  v-for="column in popup_tableColumns"
                                  :key="column.id"
                                >
                                  <div v-if="column.id == 'pays'">
                                    <span>
                                      {{ item.pay | comma | moneyFilter }}</span
                                    >
                                  </div>
                                  <div v-if="column.id == 'paids'">
                                    <span>
                                      {{
                                        item.paid | comma | moneyFilter
                                      }}</span
                                    >
                                  </div>

                                  {{ itemValue(item, column.id) }}
                                  <div v-if="column.id == 'detail'">
                                    <div>
                                      <v-btn
                                        style="
                                          background-color: #42d0ed;
                                          color: white;
                                        "
                                        @click="getLog(index)"
                                        >상세 내역
                                      </v-btn>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>

                            <!-- 모달창 테이블 end -->

                            <!-- 모달 안쪽 저장 버튼 -->
                            <v-card-actions>
                              <v-spacer></v-spacer>
                              <v-btn
                                class="btn btn-info btn-fill float-right col-1"
                                style="color: white"
                                text
                                @click="dialog = false"
                              >
                                닫기
                              </v-btn>
                            </v-card-actions>
                          </v-card>
                        </v-dialog>
                      </v-row>
                    </div>
                  </td>
                </tr>
              </table>
            </div>
            <div v-else>
              <div class="emptyContent">
                상세 보기 할 데이터를 클릭해 주세요.
              </div>
            </div>
          </div>
        </card>
      </div>
    </div>
  </div>
  <div v-else>
    <card>
      <h1>해당 계정은 [정산 관리] 페이지에 접근 권한이 없습니다.</h1>
    </card>
  </div>
</template>
<script>
import Card from "src/components/Cards/Card.vue";
import BaseInput from "src/components/Inputs/BaseInput.vue";
import XLSX from "xlsx";
import accessToken from "../../accessToken";

export default {
  // 숫자에 콤마 넣기
  filters: {
    comma(val) {
      return String(val).replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    },
  },
  components: {
    Card,
    BaseInput,
  },
  data() {
    return {
      // 세부내역 팝업 여부
      dialog: false,

      // 페이지 갯수
      pages: { page: 1, pageSize: 15 },
      pageNum: 0,
      totalNum: 0,

      // 결제금액 총액
      billTotal: 0,

      // 정산금액 총액
      payTotal: 0,

      // 총 사이트 갯수를 나타내는 카운트
      length: 0,

      access: accessToken.host,

      // 정산된 사이트 정보
      site: {
        record: "",
        id: "",
        name: "",
        provider_name: "",
        provider_email: "",
        mob_number: "",
        pay_amount: "",
        bill_amount: "",
        last_created: "",
        site_created: "",
        bill_time: "",
      },

      // 검색 기준 셀렉트 박스 옵션
      searchOptionList: [
        { id: "all", name: "전체" },
        { id: "site", name: "사이트명" },
        { id: "provider", name: "건물 관리자 이름" },
      ],

      // 토탈 테이블 컬럼
      TotalColumns: [
        { id: "id", msg: "누적 사이트 수" },
        { id: "total_amount", msg: "누적 결제 금액" },
        { id: "bill_amount", msg: "누적 정산 금액" },
      ],

      // 메인데이터 컬럼
      tableColumns: [
        { id: "site_id", msg: "사이트ID" },
        { id: "site_name", msg: "사이트명" },
        { id: "provider_name", msg: "건물 관리자 이름" },
        { id: "sum_amount", msg: "누적 정산 금액" },
        { id: "created", msg: "최종 정산일" },
      ],

      // 검색 테이블 컬럼
      searchColumns: [
        { id: "site_id", msg: "사이트ID" },
        { id: "site_name", msg: "사이트명" },
        { id: "provider_name", msg: "건물 관리자 이름" },
        { id: "sum_amount", msg: "누적 정산 금액" },
        { id: "created", msg: "최종 정산일" },
      ],

      // 팝업 테이블 컬럼
      popup_tableColumns: [
        { id: "num", msg: "NO" },
        { id: "payday", msg: "정산일" },
        { id: "pays", msg: "결제 금액" },
        { id: "paids", msg: "정산 금액" },
        { id: "paid_acc", msg: "정산 계좌" },
        { id: "payment_cycle", msg: "정산 주기" },
        { id: "detail", msg: "상세 내역" },
      ],

      // 메인 테이블 데이터
      tableData: [],

      // 검색 데이터
      searchData: [],

      // 팝업 데이터
      popup_tableData: [],

      // 총 사이트갯수 데이터
      SiteTotal: [],

      // 검색 인풋박스 데이터
      searchInput: "",

      number: 0,
      data: {
        created: "",
        bill: "",
      },
      change_access: "",
      clickRowIndex: -1,
    };
  },
  async created() {
    if (sessionStorage.getItem("login") !== null) {
      this.change_access =
        this.$store.state.get_admin_detail.admin_user_profile.access.slice(
          3,
          4
        );
      this.my_id =
        this.$store.state.get_admin_detail.admin_user_profile.admin_id;
    }
    this.length = this.tableData.length;
    if (this.change_access === "y") {
      this.getBillRecord();
    }
  },
  computed: {
    pageCount() {
      //전체 페이지 수 count
      let listLeng = this.totalNum,
        listSize = this.pages.pageSize,
        page = Math.floor(listLeng / listSize);

      if (listLeng % listSize > 0) page += 1;
      return page;
    },
    searchCount() {
      //전체 페이지 수 count
      let listLeng = this.totalNum,
        listSize = this.pages.pageSize,
        page = Math.floor(listLeng / listSize);

      if (listLeng % listSize > 0) page += 1;
      return page;
    },
  },
  methods: {
    enterkey() {
      if (window.event.keyCode == 13) {
        this.findResult();
      }
    },
    itemValue(item, column) {
      return item[column.toLowerCase()];
    },

    // 세부내역 팝업 들어가서 상세내역 클릭스 나오는 윈도우 팝업
    getLog(index) {
      // localhost
      if (this.access === true) {
        window.open(
          "http://localhost:8080/operator/paid/PaidResultTable" +
            "?site=" +
            this.site.id +
            "&amount=" +
            this.popup_tableData[index].pay +
            "&bill=" +
            this.popup_tableData[index].bill_id,
          "window_name",
          "width=1000,height=800,location=no,status=no,scrollbars=yes"
        );
      } else {
        window.open(
          // server
          "https://admin.shareplug.co.kr/operator/paid/PaidResultTable/" +
            "?site=" +
            this.site.id +
            "&amount=" +
            this.popup_tableData[index].pay +
            "&bill=" +
            this.popup_tableData[index].bill_id,
          "window_name",
          "width=1400,height=1000,location=no,status=no,scrollbars=yes"
        );
      }
    },
    /* 페이징 클릭 시 */
    pageClick(button, page) {
      this.getBillRecord(page);
    },
    pageClickFind(button, page) {
      this.findResult(page);
    },

    // 정산결과 검색 기능
    async findResult(page) {
      this.pageNum = page ? page : 1;
      this.pages.page = this.pageNum;

      if (this.searchOptionList.id === undefined) {
        this.searchOptionList.id = "all";
      }
      var data = {};
      data.id = this.searchOptionList.id;
      data.search = this.searchInput;
      data.page = this.pages.page;
      data.lines = this.pages.pageSize;

      const response = await this.$store.dispatch("FIND_PAID_RESULT", data);
      console.log(response);
      var list = response.data.data.find_paid_list;
      this.totalNum = response.data.data.total;
      this.billTotal = response.data.data.sum_bill;
      this.payTotal = response.data.data.pay_total;
      this.totalNum = response.data.data.total;

      if (!list || list.length === 0) {
        // alert("검색된 데이터가 없습니다. 다시 검색하시기 바랍니다.");
        this.tableData = [];
        list = [];
      }
      this.searchData = [];

      for (var item of list) {
        var temp = {
          site_id: item.site_id,
          pay: item.pay,
          paid: item.paid,
          site_name: item.name,
          sum_amount: item.sum_amount,
          pay_amount: item.pay_amount,
          created: item.created,
          count_record: item.count_num,
          provider_name: item.provider_name,
          provider_email: item.provider_email,
          site_created: item.site_created,
          mob_number: item.mob_number,
          bill_time: item.bill_time,
          number: item.bill_id,
        };
        this.searchData.push(temp);
      }
      this.clickRowIndex = -1;

      this.SiteTotal = [];
      this.SiteTotal.push({
        id: this.totalNum,
        total_amount: this.payTotal,
        bill_amount: this.billTotal,
      });
    },

    // 검색 데이터 상세정보
    async findBillDetail(index) {
      this.clickRowIndex = index;
      this.site.record = this.searchData[index].count_record;
      this.site.id = this.searchData[index].site_id;
      this.site.name = this.searchData[index].site_name;
      this.site.bill_amount = this.searchData[index].sum_amount;
      this.site.pay_amount = this.searchData[index].pay_amount;
      this.site.last_created = this.searchData[index].created;
      this.site.provider_name = this.searchData[index].provider_name;
      this.site.provider_email = this.searchData[index].provider_email;
      this.site.site_created = this.searchData[index].site_created;
      this.site.bill_time = this.searchData[index].bill_time;
      this.site.mob_number = this.searchData[index].mob_number;

      this.number = this.searchData[index].number;
      this.popup_tableData = [];
      for (var i = 0; i < this.number.length; i++) {
        this.popup_tableData.push({
          num: this.number.length - i,
          payday: this.number[i].created,
          pay: this.number[i].bill_pay_total,
          paid: this.number[i].bill_amount,
          paid_acc:
            this.number[i].bill_bank_name +
            "-" +
            this.number[i].bill_acc_holder +
            "-" +
            this.number[i].bill_acc_num,
          payment_cycle:
            this.number[i].bill_sett_time === 4
              ? "1개월"
              : this.number[i].bill_sett_time === 3
              ? "3주"
              : this.number[i].bill_sett_time === 2
              ? "2주"
              : this.number[i].bill_sett_time === 1
              ? "1주"
              : this.number[i].bill_sett_time === 5
              ? "2개월"
              : null,
          bill_id: this.number[i].bill_id, // 2024.06.10 kimjak 추가 정산처리 selecting
        });
      }
    },

    // 메인 데이터 상세정보
    async getDetail(index) {
      this.clickRowIndex = index;
      this.site.record = this.tableData[index].count_record;
      this.site.id = this.tableData[index].site_id;
      this.site.name = this.tableData[index].site_name;
      this.site.bill_amount = this.tableData[index].sum_amount;
      this.site.pay_amount = this.tableData[index].pay_amount;
      this.site.last_created = this.tableData[index].created;
      this.site.provider_name = this.tableData[index].provider_name;
      this.site.provider_email = this.tableData[index].provider_email;
      this.site.site_created = this.tableData[index].site_created;
      this.site.bill_time = this.tableData[index].bill_time;
      this.site.mob_number = this.tableData[index].mob_number;

      this.number = this.tableData[index].number;
      this.popup_tableData = [];
      for (var i = 0; i < this.number.length; i++) {
        this.popup_tableData.push({
          num: this.number.length - i,
          payday: this.number[i].created,
          pay: this.number[i].bill_pay_total,
          paid: this.number[i].bill_amount,
          paid_acc:
            this.number[i].bill_bank_name +
            "-" +
            this.number[i].bill_acc_holder +
            "-" +
            this.number[i].bill_acc_num,
          payment_cycle:
            this.number[i].bill_sett_time === 4
              ? "1개월"
              : this.number[i].bill_sett_time === 3
              ? "3주"
              : this.number[i].bill_sett_time === 2
              ? "2주"
              : this.number[i].bill_sett_time === 1
              ? "1주"
              : this.number[i].bill_sett_time === 5
              ? "2개월"
              : null,
          bill_id: this.number[i].bill_id, // 2024.06.10 kimjak 추가 정산처리 selecting
        });
      }
    },

    // 메인 테이블 데이터 상세정보
    async getBillRecord(page) {
      this.pageNum = page ? page : 1;
      this.pages.page = this.pageNum;

      const response = await this.$store.dispatch(
        "FETCH_GET_BILL_RECORD",
        this.pages
      );
      console.log(response.data);

      var list = response.data.data.bill_record_list;
      this.totalNum = response.data.data.total;
      this.billTotal = response.data.data.sum_bill;
      this.payTotal = response.data.data.pay_total;
      this.tableData = [];
      for (var item of list) {
        var temp = {
          site_id: item.site_id,
          pay: item.pay,
          paid: item.paid,
          site_name: item.name,
          sum_amount: item.sum_amount,
          pay_amount: item.pay_amount,
          created: item.created,
          count_record: item.count_num,
          provider_name: item.provider_name,
          provider_email: item.provider_email,
          site_created: item.site_created,
          mob_number: item.mob_number,
          bill_time: item.bill_time,
          number: item.bill_id,
        };
        this.tableData.push(temp);
      }
      this.clickRowIndex = -1;

      this.SiteTotal = [];
      this.SiteTotal.push({
        id: this.totalNum,
        total_amount: this.payTotal,
        bill_amount: this.billTotal,
      });
    },

    // 엑셀 다운로드 기능
    exportExcel() {
      var today = new Date();
      var today_date =
        today.getFullYear() +
        "" +
        ("0" + (today.getMonth() + 1)).slice(-2) +
        "" +
        ("0" + today.getDate()).slice(-2);
      var today_time =
        ("0" + today.getHours()).slice(-2) +
        "" +
        ("0" + today.getMinutes()).slice(-2) +
        "" +
        ("0" + today.getSeconds()).slice(-2);

      const wb = XLSX.utils.book_new(); //엑셀 파일 생성(workbook)
      var ws_data = [
        ["추출일", this.$getDateYYYYMMDD(today_date + today_time)],
        [],
      ];

      //header setting
      var temp = [];
      var i = 0;

      if (this.searchData.length > 0) {
        for (var header of this.searchColumns) {
          if (header.id == "selbox") {
            continue;
          }
          temp[i] = header.msg;
          i++;
        }
        ws_data.push(temp);

        //data setting
        for (var row of this.searchData) {
          temp = [];
          i = 0;
          for (var header of this.searchColumns) {
            if (header.id == "selbox") {
              continue;
            }
            temp[i] = this.itemValue(row, header.id);
            i++;
          }
          ws_data.push(temp);
        }
      } else {
        for (var header of this.tableColumns) {
          if (header.id == "selbox") {
            continue;
          }
          temp[i] = header.msg;
          i++;
        }
        ws_data.push(temp);

        //data setting
        for (var row of this.tableData) {
          temp = [];
          i = 0;
          for (var header of this.tableColumns) {
            if (header.id == "selbox") {
              continue;
            }
            temp[i] = this.itemValue(row, header.id);
            i++;
          }
          ws_data.push(temp);
        }
      }

      var ws = XLSX.utils.aoa_to_sheet(ws_data);
      const workseet = XLSX.utils.book_append_sheet(wb, ws, "Sheet1");

      //엑셀 파일 명
      var fileName =
        "정산 결과_" + today_date + "_" + today_time + "_" + ".xlsx";
      XLSX.writeFile(wb, fileName); //엑셀 다운로드
    },
  },
};
</script>
<style scoped>
.search_div {
  padding: 10px 30px 30px 20px;
}
.search_div .search_input_row {
  display: flex;
  margin: 20px 0 0 0;
}
.search_div .search_btn {
  width: 70px;
  font-weight: 400;
  background-color: #3195cf;
  border: 1px solid #4388c1;
  color: white;
  height: 35px;
  font-size: 13px;
}

.countData {
  padding: 15px;
}

.detail_div {
  margin: 10px 25px;
}
.table th {
  background: #f2f2f2;
}

.btn_div button {
  margin: 10px 10px;
  width: 100px;
}
.uselog_btn {
  color: black;
  margin-top: 5px;
  height: 40px;
  margin-left: 5px;
}
.excel {
  height: 23px;
}
.p1 {
  margin: 5px 0 0 0;
  white-space: nowrap;
}
.seletbox {
  margin: 0 20px 0 0;
}
.inputbox {
  margin: 0 20px 2% 0;
  width: 50%;
  position: relative;
}
</style>
