<template>
  <div id="modal-template">
    <transition name="modal">
      <div class="modal-mask">
        <div class="modal-wrapper">
          <div class="modal-container">
            <div class="closeDiv">
              <img
                class="close"
                @click="$emit('close')"
                src="~@/assets/imgs/close.jpg"
              />
            </div>

            <div class="modal-header">
              <slot name="header">
                <div class="aria-center">플러그 ID</div>
                <div class="aria-center">{{ plug_id }}</div>
              </slot>
            </div>

            <div class="modal-body aria-center page1">
              <slot name="body">
                <span class="aria-center title">관리자 OTP 발급</span>
                <base-input
                  type="text"
                  class="text-center otpNumber"
                  v-model="otp_number"
                  readOnly="true"
                ></base-input>
                <p class="otpDesc">
                  발급된 OTP를 사용하려면 각 상황에 맞는 접속키를 입력한
                  상태에서 OTP를 입력하시기 바랍니다. 발급된 OTP를 접속키 입력
                  한 상태에서 n초내에 입력하시기 바랍니다. 발급된 OTP는 해당
                  플러그 ID를 기준으로 당일에만 유효합니다. 각 OTP는 보안에
                  각별히 유의하시기 바랍니다.<br />
                </p>
              </slot>
            </div>
          </div>
        </div>
      </div>
    </transition>
  </div>
</template>

<script>
import BaseInput from "src/components/Inputs/BaseInput.vue";

export default {
  components: {
    BaseInput,
  },
  props: {
    plug_id: {
      type: String,
    },
    plug_name: {
      type: String,
    },
    otp_number: {
      type: String,
    },
  },
  data() {
    return {
      page: 1,
    };
  },
};
</script>

<style scoped>
.modal-mask {
  position: fixed;
  z-index: 9998;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: table;
  transition: opacity 0.3s ease;
}

.modal-wrapper {
  display: table-cell;
  vertical-align: middle;
}

.modal-container {
  max-width: 540px;
  margin: 0px auto;
  padding: 20px 0px;
  background-color: #fff;
  border-radius: 2px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.33);
  transition: all 0.3s ease;
  font-family: Helvetica, Arial, sans-serif;
}

.modal-header h3 {
  margin-top: 0;
  color: #42b983;
}

.modal-body {
  margin: 20px 0;
}

.aria-center {
  margin: 0px auto;
  text-align: center;
}

.close {
  float: right;
  width: 45px;
}

.modal-header {
  display: block;
}

.modal-header div {
  font-size: 22px;
}

.modal-body .title {
  font-weight: bold;
  font-size: 24px;
}

.otpNumber {
  width: 450px;
  height: 100px;
  margin: 0 auto;
  margin-top: 20px;

  margin-bottom: 20px;
}

.otpNumber input[type="text"] {
  font-size: 60px;
}
.otpDesc {
  width: 450px;
  font-size: 14px;
  margin: 30px auto -10px auto;
  margin-bottom: -10px;
}

.closeDiv {
  width: 540px;
  position: absolute;
}

table {
  width: 100%;
  border-collapse: collapse;
}
table th {
  font-size: 1.2rem;
}

table tr td {
  padding: 1rem 0;
  font-size: 1.1rem;
}
</style>
