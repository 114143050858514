<template>
  <div class="content" v-if="this.change_access === 'y'">
    <div class="container-fluid">
      <div class="row">
        <div class="col-12">
          <card
            class="strpied-tabled-with-hover"
            body-classes="table-full-width table-responsive"
          >
            <template slot="header">
              <h4 class="card-title">당일 사용 현황</h4>
              <!-- <span class="span">({{this.hour}} 집계)</span> -->
              <span class="span">실시간</span>
            </template>

            <div class="div-table">
              <div class="div-row">
                <div
                  class="div-cell"
                  v-for="column in dailyTable"
                  :key="column.id"
                >
                  <span>
                    {{ column.name }}
                  </span>
                </div>
              </div>
              <div
                class="div-row"
                v-for="(item, index) in tableData"
                :key="index"
              >
                <div
                  class="div-cell"
                  v-for="column in dailyTable"
                  :key="column.id"
                >
                  {{ itemValue(item, column.id) | comma }}
                </div>
              </div>
            </div>
          </card>
        </div>
      </div>

      <div class="row">
        <div class="col-12">
          <card
            class="strpied-tabled-with-hover"
            body-classes="table-full-width table-responsive"
          >
            <template slot="header">
              <h4 class="card-title">당월 사용 현황</h4>
              <span class="span"
                >({{ this.firstDay }} ~ {{ this.date }} 집계)</span
              >
            </template>

            <div class="div-table">
              <div class="div-row">
                <div
                  class="div-cell"
                  v-for="column in monthlyTable"
                  :key="column.id"
                >
                  <span>
                    {{ column.name }}
                  </span>
                </div>
              </div>
              <div
                class="div-row"
                v-for="(item, index) in tableData"
                :key="index"
              >
                <div
                  class="div-cell"
                  v-for="column in monthlyTable"
                  :key="column.id"
                >
                  {{ itemValue(item, column.id) | comma }}
                </div>
              </div>
            </div>
          </card>
        </div>
      </div>

      <div class="row">
        <div class="col-12">
          <card
            class="strpied-tabled-with-hover"
            body-classes="table-full-width table-responsive"
          >
            <template slot="header">
              <h4 class="card-title">누적 사용 현황</h4>
              <span class="span">({{ this.date }} 집계)</span>
            </template>

            <div class="div-table" status="Overall">
              <div class="div-row">
                <div
                  class="div-cell"
                  v-for="column in allTable"
                  :key="column.id"
                >
                  <span>
                    {{ column.name }}
                  </span>
                </div>
              </div>
              <div
                class="div-row"
                v-for="(item, index) in tableData"
                :key="index"
              >
                <div
                  class="div-cell"
                  v-for="column in allTable"
                  :key="column.id"
                >
                  {{ itemValue(item, column.id) | comma }}
                </div>
              </div>
            </div>
          </card>
        </div>
      </div>

      <div class="row">
        <div class="col-12">
          <card
            class="strpied-tabled-with-hover"
            body-classes="table-full-width table-responsive"
          >
            <template slot="header">
              <h4 class="card-title">당월 설치 현황</h4>
              <span class="span"
                >({{ this.firstDay }} ~ {{ this.date }} 집계)</span
              >
            </template>
            <div class="div-table">
              <div class="div-row">
                <div
                  class="div-cell"
                  v-for="column in monthSiteTable"
                  :key="column.id"
                >
                  <span>
                    {{ column.name }}
                  </span>
                </div>
              </div>
              <div
                class="div-row"
                v-for="(item, index) in tableData"
                :key="index"
              >
                <div
                  class="div-cell"
                  v-for="column in monthSiteTable"
                  :key="column.id"
                >
                  {{ itemValue(item, column.id) | comma }}
                </div>
              </div>
            </div>
          </card>
        </div>
      </div>
      <div class="row">
        <div class="col-12">
          <card
            class="strpied-tabled-with-hover"
            body-classes="table-full-width table-responsive"
          >
            <template slot="header">
              <h4 class="card-title">누적 설치 현황</h4>
              <span class="span">({{ this.date }} 집계)</span>
            </template>

            <div class="div-table">
              <div class="div-row">
                <div
                  class="div-cell"
                  v-for="column in allSiteTable"
                  :key="column.id"
                >
                  <span>
                    {{ column.name }}
                  </span>
                </div>
              </div>
              <div
                class="div-row"
                v-for="(item, index) in tableData"
                :key="index"
              >
                <div
                  class="div-cell"
                  v-for="column in allSiteTable"
                  :key="column.id"
                >
                  {{ itemValue(item, column.id) | comma }}
                </div>
              </div>
            </div>
          </card>
        </div>
      </div>
    </div>
  </div>
  <div v-else>
    <card>
      <h1>해당 계정은 [DASHBOARD] 페이지에 접근 권한이 없습니다.</h1>
    </card>
  </div>
</template>

<script>
import Card from "src/components/Cards/Card.vue";
import "hooper/dist/hooper.css";

export default {
  filters: {
    comma(val) {
      return String(val).replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    },
  },
  name: "App",

  components: {
    Card,
  },
  data() {
    return {
      // 당일 사용 현황
      dailyTable: [
        { id: "day_people", name: "총 사용 인원" },
        { id: "day_time", name: "총 사용 시간" },
        { id: "day_money", name: "총 결제 금액 (정기권 포함)" },
      ],

      // 당월 사용 현황
      monthlyTable: [
        { id: "month_people", name: "총 사용 인원" },
        { id: "month_time", name: "총 사용 시간" },
        { id: "month_money", name: "총 결제 금액 (정기권 포함)" },
      ],

      // 누적 사용 현황
      allTable: [
        { id: "people", name: "총 사용 인원" },
        { id: "time", name: "총 사용 시간" },
        { id: "money", name: "총 결제 금액 (정기권 포함)" },
      ],

      // 당월 철치 현황
      monthSiteTable: [
        { id: "site", name: "추가 Site" },
        { id: "plug", name: "추가 Plug" },
      ],

      // 누적 설치 현황
      allSiteTable: [
        { id: "all_site", name: "누적 Site" },
        { id: "all_plug", name: "누적 Plug" },
      ],

      // 사용 리스트
      useList: [],

      // 테이블 데이터
      tableData: [],

      // 시간
      hour: "",

      // 날짜
      date: "",

      // 당월 설치 날짜 집계
      firstDay: "",
      change_access: "",
    };
  },
  props: {
    p_status: {
      type: String,
    },
  },
  created() {
    if (sessionStorage.getItem("login") !== null) {
      this.change_access =
        this.$store.state.get_admin_detail.admin_user_profile.access.slice(
          0,
          1
        );
    }
    var today = new Date();
    this.date =
      today.getFullYear() +
      "-" +
      ("0" + (today.getMonth() + 1)).slice(-2) +
      "-" +
      ("0" + (today.getDate() - 1)).slice(-2);
    this.firstDay =
      today.getFullYear() +
      "-" +
      ("0" + (today.getMonth() + 1)).slice(-2) +
      "-" +
      0 +
      1;
    this.hour = today.getHours() + ":00";
    if (this.change_access == "y") {
      this.getList();
    }
  },
  methods: {
    hasValue(item, column) {
      return item[column] !== "undefined";
    },
    itemValue(item, column) {
      return item[column];
    },

    // 대쉬보드 리스트
    async getList() {
      const response = await this.$store.dispatch("FETCH_GET_DASHBOARD");
      var overall = response.data.data.overall;
      var daily = response.data.data.daily;
      var monthly = response.data.data.monthly;
      var month_site = response.data.data.month_site;
      var all_site = response.data.data.total_site;

      var temp = {
        day_people: daily[0].total,
        day_time: this.$getHourTime(daily[0].time),
        day_money: daily[0].money,
        month_people: monthly[0].total,
        month_time: this.$getHourTime(monthly[0].time),
        month_money: monthly[0].money,
        people: overall[0].total,
        time: this.$getHourTime(overall[0].time),
        money: overall[0].money,
        site: month_site[0].site,
        plug: month_site[1].site,
        all_site: all_site[0].site,
        all_plug: all_site[1].site,
      };
      this.tableData.push(temp);
    },
  },
};
</script>
<style scoped>
.card-title {
  font-family: "Nanum Gothic", sans-serif;
  font-weight: bold;
  text-align: center;
}
.span {
  display: flex !important;
  justify-content: right !important;
}

.table {
  margin-bottom: 0px;
}
.table-striped {
  background-color: rgb(222, 226, 230);
  width: 102%;
  border-top: 1px solid;
  border-bottom: 1px solid;
}
.col-12 {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 100%;
  flex: 0 0 100%;
  max-width: 100%;
  margin-top: -13px;
  margin-left: -16px;
}
</style>
