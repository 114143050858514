<template>
  <div
    class="sidebar"
    :style="sidebarStyle"
    :data-color="backgroundColor"
    :data-image="backgroundImage"
  >
    <!-- v-bind로 3가지 속성 묶기 -->
    <div class="sidebar-wrapper">
      <div class="logo">
        <span class="simple-text logo__container">
          {{ this.userName + " " + "님" }}
        </span>
        <div class="logo__container">
          <div class="email">{{ this.userEmail }}</div>
        </div>
      </div>

      <slot name="content"></slot>
      <ul class="nav nav-main__links">
        <!--By default vue-router adds an active class 
        to each route link. This way the links are 
        colored when clicked-->
        <slot>
          <sidebar-link
            v-for="(link, index) in sidebarLinks"
            :key="link.name + index"
            :to="link.path"
            @click="closeNavbar"
            :link="link"
          >
            <i :class="link.icon"></i>
            <p>{{ link.name }}</p>
          </sidebar-link>
        </slot>
      </ul>
      <ul class="nav nav-bottom" v-if="$slots['bottom-links']">
        <slot name="bottom-links"></slot>
      </ul>
      <div class="logset">
        <div class="logset2 logsetting">
          <!-- <router-link to="/admin/user/passwordinput">설정</router-link> -->
        </div>
        <div @click="logout()" class="logset1 logsetting">로그아웃</div>
      </div>
    </div>
  </div>
</template>
<script>
import SidebarLink from "./SidebarLink.vue";

export default {
  components: {
    SidebarLink,
  },
  methods: {
    logout() {
      this.$store.commit("logout");
      localStorage.setItem("token", "");
      alert("로그아웃 되었습니다.");
    },
  },
  props: {
    backgroundColor: {
      type: String,
      default: "black",
      validator: (value) => {
        let acceptedValues = [
          "",
          "blue",
          "azure",
          "green",
          "orange",
          "red",
          "purple",
          "black",
        ];
        return acceptedValues.indexOf(value) !== -1;
      },
    },
    backgroundImage: {
      type: String,
      default: "img/sidebar-5.jpg",
    },
    activeColor: {
      type: String,
      default: "success",
      validator: (value) => {
        let acceptedValues = [
          "primary",
          "info",
          "success",
          "warning",
          "danger",
        ];
        return acceptedValues.indexOf(value) !== -1;
      },
    },
    sidebarLinks: {
      type: Array,
      default: () => [],
    },
    autoClose: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      userName: "",
      log: "",
      userEmail: "",
    };
  },
  provide() {
    return {
      autoClose: this.autoClose,
    };
  },
  created() {
    if (sessionStorage.getItem("login") !== null) {
      this.log = sessionStorage.getItem("login");
      this.userName =
        this.$store.state.get_admin_detail.admin_user_profile.name;
      this.userEmail =
        this.$store.state.get_admin_detail.admin_user_profile.admin_email;
    } else {
      this.log = false;
      this.glog = false;
    }
    console.log(this.userName);
  },
  computed: {
    sidebarStyle() {
      return {
        backgroundImage: `url(${this.backgroundImage})`,
      };
    },
  },
};
</script>
<style scoped>
.sidebar .sidebar-wrapper {
  display: flex;
  flex-direction: column;
}
.sidebar .nav-main__links {
  flex: 1;
}
.sidebar .sidebar-wrapper .logo .logo__container {
  padding-left: 10px;
}

.logo__container .email {
  vertical-align: sub;
  display: inline-block;
}

.logo__container .btn_setting {
  width: 28px;
  right: 15px;
  position: absolute;
}
.logsetting {
  display: inline-block;
  width: 60px;
  height: 30px;
  margin-left: 50px;
  text-transform: uppercase;
  line-height: 40px;
  font-size: 15px;
  font-weight: 600;
}

a {
  color: white;
}
a:hover,
a:focus {
  color: #42d0ed;
  text-decoration: none;
}
.logsetting:hover,
.logsetting:focus {
  color: #42d0ed;
  text-decoration: none;
}
.logset1 {
  margin-left: 38px;
}
</style>
